import React from "react";

function greetings(currentHour) {
  return (
    <>
      {currentHour < 12
        ? "Good Morning! "
        : currentHour >= 12 && currentHour <= 17
        ? "Good Afternoon! "
        : currentHour > 17 || currentHour < 24
        ? "Good Evening! "
        : "Welcome! "}
    </>
  );
}

export default greetings;
