import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";

import IndexNavbar from "components/Navbars/IndexNavbar";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import Message from "components/Message";
import AuthService from "services/authService";
import colors from "config/colors.config";

function UpdatePasswordPage(props) {
  let username = useParams({}).username;
  let timerID = useRef(null);
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [alert, setAlert] = useState("");
  const [passwordFocus, setPasswordFocus] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(timerID);
    };
  }, []);

  const onChange = (e) => {
    setPassword(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setAlert("Attempting update...");
    try {
      await AuthService.userUpdatePassword({
        username: username,
        password: password,
      }).then((data) => {
        const { message } = data;
        if (message.msgBody === "Password updated") {
          setAlert("");
          props.history.push("/login-page");
        } else {
          setAlert("");
          setMessage(message);
          setTimeout(() => {
            props.history.push("/index-page");
          }, 2000);
        }
      });
    } catch (error) {
      setMessage(error.message);
      setAlert("");
    }
  };

  useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(https://res.cloudinary.com/comparelight-com/image/upload/v1598344044/page_background.jpg)",
          }}
        ></div>
        <div className="content">
          <Container>
            <div>
              <p style={{ fontWeight: "bold" }}> {alert}</p>
              <h2 style={{ marginTop: "100px" }}>Update Password</h2>
              <Col className="ml-auto mr-auto" md="4">
                <Card className="card-login card-plain">
                  <Form onSubmit={onSubmit} className="form">
                    <CardHeader className="text-center">
                      <div
                        className="logo-container"
                        style={{ marginBottom: "10px", width: "100px" }}
                      >
                        <img
                          alt="comparelight logo"
                          src={
                            "https://res.cloudinary.com/comparelight-com/image/upload/v1598268099/comparelight_logo.png"
                          }
                        ></img>
                      </div>
                    </CardHeader>
                    <CardBody>
                      {" "}
                      {message ? <Message message={message} /> : null}
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (passwordFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          variant="outlined"
                          required
                          placeholder="Enter new password..."
                          type="password"
                          id="password"
                          name="password"
                          onChange={onChange}
                          onFocus={() => setPasswordFocus(true)}
                          onBlur={() => setPasswordFocus(false)}
                        ></Input>
                      </InputGroup>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        block
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Update
                      </Button>
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </div>
          </Container>
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default UpdatePasswordPage;
