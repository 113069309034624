import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardImg,
  CardTitle,
  CardText,
  CardSubtitle,
  CardBody,
} from "reactstrap";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import GetInTouch from "./index-section/GetInTouch.js";
import Download from "./index-section/Download.js";
import ShopPageHeader from "components/Headers/ShopPageHeader";
import ShopService from "services/shopService";
import colors from "config/colors.config";

function ShopPage(props) {
  let history = useHistory();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    ShopService.getAllPublishedProducts().then((data) => {
      setProducts(data);
    });
  }, []);

  useEffect(() => {
    document.body.classList.add("shop-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("shop-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <ShopPageHeader />

        <div className="section ">
          <Container className="text-center">
            <Row className="justify-content-md-center">
              <Col lg="8" md="12">
                <h2 className="text-center">
                  Solutions Just for you<br></br>
                </h2>

                <h5 className="description">
                  Simply dummy text ever sincehar the 1500s, when an unknownshil
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries.
                </h5>
              </Col>
            </Row>
          </Container>
        </div>
        <div style={{ margin: "20px" }}>
          <Row>
            {products.map((product) => {
              return (
                <Col key={product.id} md="3">
                  <Card>
                    <CardImg
                      top
                      width="100%"
                      src={product.image_url}
                      alt="product image"
                    />
                    <hr
                      className="warning"
                      style={{
                        backgroundColor: colors.primary,
                      }}
                    />
                    <CardBody>
                      <CardTitle>{product.name}</CardTitle>
                      <CardSubtitle>Vendor: {product.vendor} </CardSubtitle>
                      <CardText>{product.description}</CardText>
                      <Button
                        className="btn-round"
                        href="/product-page"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push("/product-page/" + product.name);
                        }}
                        style={{
                          color: colors.primary,
                        }}
                        outline
                      >
                        Visit Store
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Who we are?</h2>
                <h5 className="description">
                  We are a team of engineers and energy enthusiast that helps
                  electricity consumers in nigeria to not only remotely buy
                  electricity but also we are actively involved in helping
                  organizations and homes put in place practical energy saving
                  solutions that produces exceptional results. we do this by
                  developing and creating IOT solutions tailored to suit their
                  needs.
                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/Smart-Hotel.jpg") + ")",
                    }}
                  >
                    <p className="blockquote blockquote-info">
                      "Over the past couple of years we found that most
                      electricity consumers in Nigeria wants to be able to
                      control and measure their power consumptions. This is what
                      we provide our customers"<br></br>
                      <br></br>
                      <small>-Adeola</small>
                    </p>
                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/shared-Apartment.jpg") +
                        ")",
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/smarthome.jpg") + ")",
                    }}
                  ></div>
                  <h3>
                    So if we don't just offer a platform for electricity
                    consumers in Nigeria to buy power, then what extra value are
                    we adding?
                  </h3>
                  <h4 className="sub-title" style={{ color: "#FFA500" }}>
                    Hotels
                  </h4>
                  <p>
                    Electricity is one of the major cost driver for hotels, Our
                    team have developed a solution that allows hotel managers
                    reduce their electricity bills. The solution initiates
                    energy savings modes during business downtime periods. It
                    also provides a transparent process that allows flexibility
                    so that hotel managers can set a standard allowable energy
                    units for guests. Excess energy consumed by guest are
                    charged back to customers on checkout. Guests are also able
                    to monitor their electricity consumptions for the duration
                    of stay. This way, managers are able to set a precise budget
                    for electricity bills.
                  </p>
                  <h4 className="sub-title" style={{ color: "#FFA500" }}>
                    Shared Apartments
                  </h4>
                  <p>
                    Our shared apartment solution in a way is similar to our
                    hotel energy management solution. The solution allows
                    short-term residents of shared apartment pay the electricity
                    bills incurred during their stay. The solution ensures
                    profitability on the part of the facility managers and
                    allows residents manage their electricity consumption.
                  </p>
                  <h4 className="sub-title" style={{ color: "#FFA500" }}>
                    Homes
                  </h4>
                  <p>
                    Imagine your whole home controlled with just a few swipes
                    and clicks on your phone – that’s what we offer. A ‘smart’
                    or connected home from front-door locks, lights and security
                    cameras that you can control and check from afar. Everyday
                    household gadgets are being made ‘smart’, usually by using
                    your wi-fi to connect to an app on your smartphone or
                    tablet, allowing you to control them remotely. Talk to us
                    and our Engineers will pay you a visit. We offer you a
                    system that saves you energy.
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <GetInTouch />
        <Download />
        <DarkFooter />
      </div>
    </>
  );
}

export default ShopPage;
