import Cookies from "universal-cookie";
const cookies = new Cookies();
let tok = cookies.get("access_token");
let url = "https://smswallet.com.ng:9091";
//let url = "http://localhost:7070";

export default {
  getAllPublishedProducts: () => {
    return fetch(url + "/publishedproducts")
      .then((res) => res.json())
      .then((data) => data);
  },

  findProduct: (name) => {
    return fetch(url + "/findproduct", {
      method: "post",
      body: JSON.stringify(name),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  findAllProductFeatures: (shopProductId) => {
    return fetch(url + "/allproductfeatures", {
      method: "post",
      body: JSON.stringify(shopProductId),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  findAllProductTags: (shopProductId) => {
    return fetch(url + "/allproducttags", {
      method: "post",
      body: JSON.stringify(shopProductId),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
};
