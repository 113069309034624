import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Row,
  Form,
  Input,
  Col,
  Card,
  CardTitle,
  CardBody,
  CardHeader,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";

// core components

import colors from "config/colors.config";
import AdminService from "services/adminService";
import Message from "components/Message";

function ProductQueries(props) {
  const [iconTabs, setIconTabs] = useState("1");
  const [productName, setProductName] = useState({ name: "" });
  const [product, setProduct] = useState({
    id: "",
    vendor: "",
    name: "",
    model: "",
    price: "",
    quantity: "",
    status: "",
    description: "",
    image_url: "",
    video_url: "",
    tutorial_url: "",
    updatedAt: "",
  });

  const [alert, setAlert] = useState("");

  const [message, setMessage] = useState(null);

  const [response, setResponse] = useState("false");

  //Search product
  const resetResponse = (e) => {
    setResponse("false");
    setMessage("");
  };
  const onChange = (e) => {
    setProductName({
      ...productName,
      [e.target.name]: e.target.value,
    });
  };
  const resetForm = () => {
    setProductName({
      name: "",
    });
  };

  const getProduct = (e) => {
    e.preventDefault();
    setAlert("Searching");
    try {
      AdminService.findProduct(productName).then((data) => {
        if (!data.message) {
          setAlert("");
          setResponse("true");
          setMessage("");
          resetForm();
          setProduct(data);
        } else {
          const { message } = data;
          setAlert("");
          setResponse("false");
          setMessage(message);
        }
      });
    } catch (error) {}
  };

  return (
    <div>
      <Card
        className="card-chart"
        body
        inverse
        style={{
          backgroundColor: colors.dashboard,
          color: colors.black,
        }}
      >
        <CardHeader>
          <Nav className="justify-content-center" role="tablist" tabs>
            <NavItem>
              <NavLink
                className={iconTabs === "1" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("1");
                }}
              >
                <i className="now-ui-icons education_glasses"></i>
                Search
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent className="text-center" activeTab={"iconTabs" + iconTabs}>
            <TabPane tabId="iconTabs1" style={{ color: colors.userTitle }}>
              {response === "false" ? (
                <div>
                  <div
                    className="col-md-6 offset-md-3"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                    }}
                  >
                    <Form onSubmit={getProduct} className="form">
                      <Input
                        placeholder="Product Name"
                        type="text"
                        name="name"
                        value={productName.name}
                        required
                        onChange={onChange}
                        id="name"
                      ></Input>
                      <Button
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Find Product
                      </Button>
                    </Form>

                    <p>{alert}</p>
                    {message ? <Message message={message} /> : null}
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "50px",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "50px",
                    padding: "20px",
                    backgroundColor: colors.primary,
                  }}
                >
                  <div>
                    <Button
                      className="btn-round"
                      onClick={resetResponse}
                      style={{
                        color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      size="sm"
                    >
                      Reset
                    </Button>
                  </div>
                  <Row>
                    <Col className>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Id
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.id}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Name
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.name}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Vendor
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.vendor}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Price
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.price}
                      </h5>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Model
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.model}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Quantity
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.quantity}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        UpdatedDate
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.updatedAt.slice(0, 10)}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Status
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.status}
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        VideoUrl
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.video_url}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        TutorialUrl
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.tutorial_url}
                      </h5>
                    </Col>
                  </Row>

                  <br></br>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        ImageUrl
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.image_url}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Description
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.description}
                      </h5>
                    </Col>
                  </Row>
                </div>
              )}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
}

export default ProductQueries;
