import React, { useEffect } from "react";

// reactstrap components
import { Row } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar";
import colors from "config/colors.config";
import TransparentFooter from "components/Footers/TransparentFooter";
import LandingPageHeader from "components/Headers/LandingPageHeader";
function AboutPage(props) {
  //Mobile menu
  useEffect(() => {
    document.body.classList.add("manager-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("manager-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <AuthNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section">
          <h2
            style={{
              color: colors.pageHeader,
              marginBottom: "0px",
              fontWeight: "600",

              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            About
          </h2>
          <Row style={{ margin: "50px" }}>
            <p>
              Comparelight is a subsidiary of Compare Price Market, The website
              vends power and in no way is involve in any comparison activity in
              it self. All the company comparison analysis are carried out on
              <a href="http://comparepricemarket.com">
                ComparepriceMarket.com.
              </a>
              <br></br>
              Presently the company gets its vending right as a dealer by
              working with Vatebra. The aim and goal of this company is to be
              the number one platform for vending power. The company compare
              price market limited was established in 2014, some fo our areas of
              interest includes household consumer goods, transportation,
              flights and other key areas in the Nigerian economy. <br></br>
              If you have questions about what we do or want to be a part of
              what we do, please send us an e-mail{" "}
              <a href="mailto:info@comparelight.com">info@comparelight.com</a>
            </p>
          </Row>
        </div>

        <TransparentFooter />
      </div>
    </>
  );
}

export default AboutPage;
