import React, { useState } from "react";

// reactstrap components
import { Button, Modal, NavLink } from "reactstrap";

// core components
import AuthService from "services/authService";

import Cookies from "universal-cookie";
import colors from "config/colors.config";
function Logout() {
  const [modalSmall, setModalSmall] = useState(false);
  // const { setIsAuthenticated, setUser } = useContext(AuthContext);
  const cookies = new Cookies();

  const onClickLogoutHandler = () => {
    AuthService.logout().then(() => {
      cookies.remove("access_token");
      window.location.href = "/";
    });
  };

  return (
    <>
      <NavLink
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setModalSmall(true);
        }}
      >
        <i className="now-ui-icons ui-1_lock-circle-open "></i>
        <p>Logout</p>
      </NavLink>

      <Modal
        isOpen={modalSmall}
        className="modal-sm"
        modalClassName="bd-example-modal-sm"
        toggle={() => setModalSmall(false)}
      >
        <div className="modal-header">
          <h5 className="modal-title" style={{ textAlign: "center" }}>
            Log Out
          </h5>
        </div>
        <div className="modal-body">
          <p>Are you sure you want logout from Comparelight wep App?</p>
        </div>
        <div className="modal-footer">
          <Button
            onClick={() => setModalSmall(false)}
            className="btn-round"
            style={{
              color: colors.secondary,
              backgroundColor: colors.primary,
            }}
            type="button"
          >
            Cancel
          </Button>
          <Button
            onClick={onClickLogoutHandler}
            className="btn-round"
            style={{
              color: colors.secondary,
              backgroundColor: colors.primary,
            }}
            type="button"
          >
            Log Out
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default Logout;
