import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
// reactstrap components
import { Button, FormGroup, Input, Modal, Form } from "reactstrap";

// core components

import colors from "config/colors.config";
import AuthService from "services/authService";

function Recharge() {
  let history = useHistory();
  const [rechargeModal, setRechargeModal] = useState(false);

  useEffect(() => {
    AuthService.isAuthenticated().then((data) => {
      setUser(data.user);
    });
  }, []);

  const [user, setUser] = useState({
    phone: "",
    meter_no: "",
    disco: "",
    amount: "0.00",
    meter_type: "",
  });

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    history.push(
      "/meter-page/" +
        user.phone +
        "|" +
        user.meter_no +
        "|" +
        user.disco +
        "|" +
        user.amount +
        "|" +
        user.meter_type
    );
    // if (user.amount === "0.00") {
    //   let msg = {
    //     msgBody: "Please enter recharge amount",
    //     msgError: true,
    //   };
    //   setMessage(msg);
    //   setTimeout(() => {
    //     setMessage(null);
    //   }, 5000);

    //   return false;
    // } else {
    //   history.push(
    //     "/meter-page/" +
    //       user.phone +
    //       "|" +
    //       user.meter_no +
    //       "|" +
    //       user.disco +
    //       "|" +
    //       user.amount
    //   );
    //   return true;
    // }
  };
  return (
    <>
      <Button
        style={{
          marginLeft: "-20px",
          backgroundColor: colors.primary,
          color: colors.secondary,
        }}
        className="btn-round"
        type="button"
        onClick={() => setRechargeModal(true)}
      >
        Recharge
      </Button>
      <Modal
        isOpen={rechargeModal}
        toggle={() => setRechargeModal(false)}
        modalClassName="modal-register"
      >
        <div className="modal-header no-border-header text-center">
          <button
            style={{
              color: colors.secondary,
            }}
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setRechargeModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <h6 className="text-muted">Recharge</h6>
        </div>
        <div className="modal-body">
          <Form onSubmit={onSubmit} className="form">
            <div className="form-row">
              <FormGroup className="col-md-6">
                <label>
                  Amount in <span style={{ color: colors.green }}>₦</span>
                </label>
                <NumberFormat
                  required
                  placeholder="0.00"
                  name="amount"
                  value={user.amount}
                  onChange={onChange}
                  thousandSeparator={true}
                  type="text"
                  style={{
                    borderRadius: "30px",
                    borderWidth: "1px",
                    borderColor: "blue",
                    height: "35px",
                    width: "220px",
                  }}
                />
              </FormGroup>
              <FormGroup className="col-md-6">
                <label>Meter</label>
                <Input
                  placeholder="Enter number"
                  name="meter_no"
                  value={user.meter_no}
                  onChange={onChange}
                  type="text"
                />
              </FormGroup>
            </div>

            <div className="form-row">
              <FormGroup className="col-md-6">
                <label>Phone No</label>
                <Input
                  placeholder="Enter number"
                  name="phone"
                  value={user.phone}
                  onChange={onChange}
                  type="text"
                />
              </FormGroup>
              <FormGroup className="col-md-6">
                <label>Disco</label>
                <Input
                  name="disco"
                  value={user.disco}
                  onChange={onChange}
                  type="select"
                >
                  <option value="">Choose...</option>
                  <option value="ikeja">Ikeja(IE)</option>
                  <option value="eko">Eko(EKEDC)</option>
                  <option value="ibadan">Ibadan(IBEDC)</option>
                  <option value="yola">Yola(YEDC)</option>
                  <option value="aba">Aba(ABA)</option>
                  <option value="benin">Benin(BEDC)</option>
                  <option value="enugu">Enugu(EEDC)</option>
                  <option value="abuja">Abuja(AEDC)</option>
                  <option value="kaduna">Kaduna(KAEDCO)</option>
                  <option value="jos">Jos(JED)</option>
                  <option value="portharcourt">Ph(PHED)</option>
                  <option value="kano">Kano(KEDCO)</option>
                </Input>
              </FormGroup>
              <FormGroup className="col-md-6">
                <label>MeterType</label>
                <Input
                  name="meter_type"
                  value={user.meter_type}
                  onChange={onChange}
                  type="select"
                >
                  <option value="">Choose...</option>
                  <option value="prepaid">Prepaid</option>
                  <option value="postpaid">Postpaid</option>
                </Input>
              </FormGroup>
            </div>
            <Button
              block
              className="btn-round"
              type="submit"
              style={{
                color: colors.secondary,
                backgroundColor: colors.primary,
              }}
            >
              Submit
            </Button>
          </Form>
        </div>
        <div className="modal-footer no-border-footer">
          <span className="text-muted text-center">
            {/* {message ? <Message message={message}/> : null} */}
            <a
              href="#pablo"
              onClick={(e) => e.preventDefault()}
              style={{
                color: colors.secondary,
              }}
            >
              Check meter Status
            </a>
            ?
          </span>
        </div>
      </Modal>
    </>
  );
}

export default Recharge;
