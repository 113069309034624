import React, { useState } from "react";

// reactstrap components
import {
  Button,
  FormGroup,
  Row,
  Form,
  Input,
  Col,
  Card,
  CardTitle,
  CardBody,
  CardHeader,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";

// core components

import colors from "config/colors.config";
import AdminService from "services/adminService";
import Message from "components/Message";

function ProductFeatureQueries(props) {
  const [iconTabs, setIconTabs] = React.useState("3");
  const [featureDetail, setFeatureDetail] = useState({
    shopProductId: "",
    title: "",
  });
  const [featureDetail2, setFeatureDetail2] = useState({
    shopProductId: "",
    title: "",
  });
  const [featureDetail3, setFeatureDetail3] = useState({ id: "" });
  const [feature, setFeature] = useState({
    id: "",
    title: "",
    description: "",
    shopProductId: "",
    updatedAt: "",
  });
  const [feature2, setFeature2] = useState({
    title: "",
    description: "",
    shopProductId: "",
  });
  const [feature3, setFeature3] = useState({
    id: "",
    title: "",
    description: "",
    shopProductId: "",
  });
  const [alert, setAlert] = useState("");
  const [alert2, setAlert2] = useState("");
  const [alert3, setAlert3] = useState("");
  const [alert4, setAlert4] = useState("");

  const [message, setMessage] = useState(null);
  const [message2, setMessage2] = useState(null);
  const [message3, setMessage3] = useState(null);
  const [message4, setMessage4] = useState(null);

  const [response, setResponse] = useState("false");
  const [response2, setResponse2] = useState("false");
  const [response3, setResponse3] = useState("false");

  //Search feature
  const resetResponse = (e) => {
    setResponse("false");
    setMessage("");
  };
  const onChange = (e) => {
    setFeatureDetail({
      ...featureDetail,
      [e.target.name]: e.target.value,
    });
  };
  const resetForm = () => {
    setFeatureDetail({
      shopProductId: "",
      title: "",
    });
  };

  const getProductFeature = (e) => {
    e.preventDefault();
    setAlert("Searching");
    try {
      AdminService.findProductFeature(featureDetail).then((data) => {
        if (!data.message) {
          setAlert("");
          setResponse("true");
          setMessage("");
          resetForm();
          setFeature(data);
        } else {
          const { message } = data;
          setAlert("");
          setResponse("false");
          setMessage(message);
        }
      });
    } catch (error) {}
  };

  //Create feature
  const onChangeFeature2 = (e) => {
    setFeature2({
      ...feature2,
      [e.target.name]: e.target.value,
    });
  };

  const resetProductFeature2 = () => {
    setFeature2({
      title: "",
      description: "",
      shopProductId: "",
    });
  };

  const createProductFeature = (e) => {
    e.preventDefault();
    setAlert2("Creating feature");
    try {
      AdminService.createProductFeature(feature2).then((data) => {
        if (data.message.msgBody === "Feature successfully created") {
          const { message } = data;
          setAlert2("");
          setMessage2(message);
          resetProductFeature2();
        } else {
          const { message } = data;
          setAlert2("");
          setMessage2(message);
        }
      });
    } catch (error) {}
  };

  //Update feature
  const resetResponse2 = (e) => {
    setResponse2("false");
    setMessage3("");
  };
  const onChange2 = (e) => {
    setFeatureDetail2({
      ...featureDetail2,
      [e.target.name]: e.target.value,
    });
  };
  const resetForm2 = () => {
    setFeatureDetail2({
      shopProductId: "",
      title: "",
    });
  };

  const getProductFeature2 = (e) => {
    e.preventDefault();
    setAlert3("Searching");
    try {
      AdminService.findProductFeature(featureDetail2).then((data) => {
        if (!data.message) {
          setAlert3("");
          setResponse2("true");
          resetForm2();
          setFeature3(data);
          setMessage3(null);
        } else {
          const { message } = data;
          setAlert3("");
          setResponse2("false");
          setMessage3(message);
        }
      });
    } catch (error) {}
  };

  const onChangeFeature3 = (e) => {
    setFeature3({
      ...feature3,
      [e.target.name]: e.target.value,
    });
  };

  const resetFeature3 = () => {
    setFeature3({
      id: "",
      title: "",
      description: "",
      shopProductId: "",
    });
  };

  const updateProductFeature = (e) => {
    e.preventDefault();
    setAlert3("Updating feature");
    try {
      AdminService.updateProductFeature(feature3).then((data) => {
        if (data.message.msgBody === "Feature was updated successfully.") {
          const { message } = data;
          setAlert3("");
          setMessage3(message);
          resetFeature3();
        } else {
          const { message } = data;
          setAlert3("");
          setMessage3(message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Delete feature
  const resetResponse3 = (e) => {
    setResponse3("false");
    setMessage4("");
  };
  const onChange3 = (e) => {
    setFeatureDetail3({
      ...featureDetail3,
      [e.target.name]: e.target.value,
    });
  };
  const resetForm3 = () => {
    setFeatureDetail3({
      id: "",
    });
  };
  const deleteProductFeature = (e) => {
    e.preventDefault();
    setAlert4("Deleting");
    try {
      AdminService.deleteProductFeature(featureDetail3).then((data) => {
        const { message } = data;
        setAlert4("");
        setResponse3("true");
        setMessage4(message);
        resetForm3();
      });
    } catch (error) {}
  };

  return (
    <div>
      <Card
        className="card-chart"
        body
        inverse
        style={{
          backgroundColor: colors.dashboard,
          color: colors.black,
        }}
      >
        <CardHeader>
          <Nav className="justify-content-center" role="tablist" tabs>
            <NavItem>
              <NavLink
                className={iconTabs === "1" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("1");
                }}
              >
                <i className="now-ui-icons education_glasses"></i>
                Search
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs === "2" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("2");
                }}
              >
                <i className="now-ui-icons files_paper"></i>
                Create
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs === "3" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("3");
                }}
              >
                <i className="now-ui-icons text_caps-small"></i>
                Update
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs === "4" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("4");
                }}
              >
                <i className="now-ui-icons ui-1_simple-delete"></i>
                Delete
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent className="text-center" activeTab={"iconTabs" + iconTabs}>
            <TabPane tabId="iconTabs1" style={{ color: colors.userTitle }}>
              {response === "false" ? (
                <div>
                  <div
                    className="col-md-6 offset-md-3"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                    }}
                  >
                    <Form onSubmit={getProductFeature} className="form">
                      <div className="form-row">
                        <FormGroup className="col-md-6">
                          <Input
                            placeholder="ProductId"
                            type="text"
                            name="shopProductId"
                            value={featureDetail.shopProductId}
                            required
                            onChange={onChange}
                            id="shopProductId"
                          ></Input>
                        </FormGroup>
                        <FormGroup className="col-md-6">
                          <Input
                            placeholder="Title"
                            type="text"
                            name="title"
                            value={featureDetail.title}
                            required
                            onChange={onChange}
                            id="title"
                          ></Input>
                        </FormGroup>
                      </div>

                      <Button
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Find feature
                      </Button>
                    </Form>

                    <p> {alert}</p>
                    {message ? <Message message={message} /> : null}
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "50px",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "50px",
                    padding: "20px",
                    backgroundColor: colors.primary,
                  }}
                >
                  <div>
                    <Button
                      className="btn-round"
                      onClick={resetResponse}
                      style={{
                        color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      size="sm"
                    >
                      Reset
                    </Button>
                  </div>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Description
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {feature.description}
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col className>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Id
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {feature.id}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Title
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {feature.title}
                      </h5>
                    </Col>

                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        ProductId
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {feature.shopProductId}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        UpDatedDate
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {feature.updatedAt.slice(0, 10)}
                      </h5>
                    </Col>
                  </Row>
                </div>
              )}
            </TabPane>
            <TabPane tabId="iconTabs2">
              <div style={{ color: colors.black }}>
                <Form onSubmit={createProductFeature} className="form">
                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <label htmlFor="shopProductId">ProductId</label>
                      <Input
                        id="shopProductId"
                        placeholder="Enter number"
                        type="text"
                        name="shopProductId"
                        value={feature2.shopProductId}
                        required
                        onChange={onChangeFeature2}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label htmlFor="title">Title</label>
                      <Input
                        id="title"
                        placeholder="Enter Title"
                        type="text"
                        name="title"
                        value={feature2.title}
                        required
                        onChange={onChangeFeature2}
                      ></Input>
                    </FormGroup>
                  </div>
                  <FormGroup>
                    <label htmlFor="description">Description</label>
                    <Input
                      id="description"
                      placeholder="Enter description"
                      type="textarea"
                      name="description"
                      required
                      value={feature2.description}
                      onChange={onChangeFeature2}
                    ></Input>
                  </FormGroup>

                  <Button
                    className="btn-round"
                    type="submit"
                    style={{
                      color: colors.secondary,
                      backgroundColor: colors.primary,
                    }}
                    size="lg"
                  >
                    Create
                  </Button>
                </Form>
                <hr
                  className="warning"
                  style={{
                    backgroundColor: colors.primary,
                    marginTop: "25px",
                  }}
                />
                <p> {alert2}</p>
                <div>{message2 ? <Message message={message2} /> : null}</div>
              </div>
            </TabPane>
            <TabPane tabId="iconTabs3">
              {response2 === "false" ? (
                <div>
                  <div
                    className="col-md-6 offset-md-3"
                    style={{
                      color: colors.black,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                    }}
                  >
                    <Form onSubmit={getProductFeature2} className="form">
                      <div className="form-row">
                        <FormGroup className="col-md-6">
                          <Input
                            placeholder="ProductId"
                            type="text"
                            name="shopProductId"
                            value={featureDetail2.shopProductId}
                            required
                            onChange={onChange2}
                            id="shopProductId"
                          ></Input>
                        </FormGroup>
                        <FormGroup className="col-md-6">
                          <Input
                            placeholder="Title"
                            type="text"
                            name="title"
                            value={featureDetail2.title}
                            required
                            onChange={onChange2}
                            id="title"
                          ></Input>
                        </FormGroup>
                      </div>

                      <Button
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Find feature
                      </Button>
                    </Form>
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "50px",
                    }}
                  />
                  {<p>{alert3}</p>}
                  <div>{message3 ? <Message message={message3} /> : null}</div>
                </div>
              ) : (
                <div style={{ color: colors.black }}>
                  <Button
                    className="btn-round"
                    onClick={resetResponse2}
                    style={{
                      color: colors.secondary,
                      backgroundColor: colors.primary,
                    }}
                    size="sm"
                  >
                    Reset
                  </Button>
                  <Form onSubmit={updateProductFeature} className="form">
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="id">Id</label>
                        <Input
                          id="id"
                          defaultValue={feature3.id}
                          placeholder="Enter number"
                          type="text"
                          name="id"
                          value={feature3.id}
                          onChange={onChangeFeature3}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="title">Title</label>
                        <Input
                          id="title"
                          placeholder="Enter title"
                          defaultValue={feature3.title}
                          type="text"
                          name="title"
                          value={feature3.title}
                          onChange={onChangeFeature3}
                        ></Input>
                      </FormGroup>
                    </div>
                    <FormGroup className="col-md-6">
                      <label htmlFor="description">Description</label>
                      <Input
                        id="description"
                        placeholder="Enter description"
                        defaultValue={feature3.description}
                        type="textarea"
                        name="description"
                        value={feature3.description}
                        onChange={onChangeFeature3}
                      ></Input>
                    </FormGroup>

                    <FormGroup>
                      <label htmlFor="shopProductId">ProductId</label>
                      <Input
                        id="shopProductId"
                        placeholder="Enter id"
                        type="text"
                        name="shopProductId"
                        defaultValue={feature3.shopProductId}
                        value={feature3.shopProductId}
                        onChange={onChangeFeature3}
                      ></Input>
                    </FormGroup>

                    <Button
                      className="btn-round"
                      type="submit"
                      style={{
                        color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      size="lg"
                    >
                      Update
                    </Button>
                  </Form>
                  {<p>{alert3}</p>}
                  <div>{message3 ? <Message message={message3} /> : null}</div>
                </div>
              )}
            </TabPane>
            <TabPane tabId="iconTabs4">
              {response3 === "false" ? (
                <div>
                  <div
                    className="col-md-6 offset-md-3"
                    style={{
                      color: colors.black,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                    }}
                  >
                    <Form onSubmit={deleteProductFeature} className="form">
                      <Input
                        placeholder="feature id"
                        type="text"
                        name="id"
                        value={featureDetail3.id}
                        required
                        onChange={onChange3}
                        id="id"
                      ></Input>
                      <Button
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Delete
                      </Button>
                    </Form>

                    <p>{alert4}</p>
                    {message4 ? <Message message={message4} /> : null}
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "50px",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "50px",
                  }}
                >
                  <div>
                    <Button
                      className="btn-round"
                      onClick={resetResponse3}
                      style={{
                        color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      size="sm"
                    >
                      Reset
                    </Button>
                  </div>
                  <p>{alert4}</p>
                  {message4 ? <Message message={message4} /> : null}
                </div>
              )}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
}

export default ProductFeatureQueries;
