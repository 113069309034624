import React, { useState, useEffect } from "react";
import AuthService from "services/authService";
import { Button, FormGroup, Input, Modal, Form, Row, Col } from "reactstrap";
import colors from "config/colors.config";
import MeterService from "services/meterService";

function MeterStatus(props) {
  const [meterstatusModal, setMeterstatusModal] = useState(false);
  const [hideForm, setHideForm] = useState(false);
  const [meterDetails, setMeterDetails] = useState({
    accountNo: "",
    accountType: "",
    address: "",
    balance: "",
    contactNo: "",
    meterNo: "",
    minAmount: "",
    name: "",
  });

  const [alert, setAlert] = useState("");

  useEffect(() => {
    AuthService.isAuthenticated().then((data) => {
      setUser(data.user);
    });
  }, []);
  const [user, setUser] = useState({ meter_no: "" });

  const resetForm = (e) => {
    e.preventDefault();
    setHideForm(false);
    setAlert("");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setHideForm(true);
    setAlert("Checking...");
    try {
      MeterService.findMeterDetails({
        // meterNo: "0207213086540",
        //disco: "aba",
        meterNo: user.meter_no,
        disco: MeterService.getDiscoSlug(user.disco),
      }).then((data) => {
        const { status, info } = data;
        if (status !== 200) {
          setAlert(`${user.disco} Not Available`);
        } else {
          setAlert("");
          setMeterDetails({
            accountNo: info.accountNo,
            accountType: info.accountType,
            address: info.address,
            balance: info.balance,
            contactNo: info.contactNo,
            meterNo: info.meterNo,
            minAmount: info.minAmount,
            name: info.name,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }

    // try {
    //   getDiscoSlug()
    //   if (user.disco === "Ikeja(IE)") {
    //     MeterService.findMeterDetails({
    //       meterNo: "43012533840",
    //       disco: "ikeja",
    //     }).then((data) => {
    //       const { status, info } = data;
    //       if (status !== 200) {
    //         setAlert(info);
    //       } else {
    //         setAlert("");
    //         setMeterDetails({
    //           accountNo: info.accountNo,
    //           accountType: info.accountType,
    //           address: info.address,
    //           balance: info.balance,
    //           contactNo: info.contactNo,
    //           meterNo: info.meterNo,
    //           minAmount: info.minAmount,
    //           name: info.name,
    //         });
    //       }
    //     });
    //   } else if (user.disco === "Eko(EKEDC)") {
    //     MeterService.findMeterDetails({
    //       meterNo: user.meter_no,
    //       disco: "eko",
    //     }).then((data) => {
    //       const { status, info } = data;
    //       if (status !== 200) {
    //         setAlert(info);
    //       } else {
    //         setAlert("");
    //         setMeterDetails({
    //           accountNo: info.accountNo,
    //           accountType: info.accountType,
    //           address: info.address,
    //           balance: info.balance,
    //           contactNo: info.contactNo,
    //           meterNo: info.meterNo,
    //           minAmount: info.minAmount,
    //           name: info.name,
    //         });
    //       }
    //     });
    //   } else if (user.disco === "Kano(KEDCO)") {
    //     MeterService.findMeterDetails({
    //       meterNo: user.meter_no,
    //       disco: "kano",
    //     }).then((data) => {
    //       const { status, info } = data;
    //       if (status !== 200) {
    //         setAlert(info);
    //       } else {
    //         setAlert("");
    //         setMeterDetails({
    //           accountNo: info.accountNo,
    //           accountType: info.accountType,
    //           address: info.address,
    //           balance: info.balance,
    //           contactNo: info.contactNo,
    //           meterNo: info.meterNo,
    //           minAmount: info.minAmount,
    //           name: info.name,
    //         });
    //       }
    //     });
    //   } else if (user.disco === "Ph(PHED)") {
    //     MeterService.findMeterDetails({
    //       meterNo: user.meter_no,
    //       disco: "portharcourt",
    //     }).then((data) => {
    //       const { status, info } = data;
    //       if (status !== 200) {
    //         setAlert(info);
    //       } else {
    //         setAlert("");
    //         setMeterDetails({
    //           accountNo: info.accountNo,
    //           accountType: info.accountType,
    //           address: info.address,
    //           balance: info.balance,
    //           contactNo: info.contactNo,
    //           meterNo: info.meterNo,
    //           minAmount: info.minAmount,
    //           name: info.name,
    //         });
    //       }
    //     });
    //   } else if (user.disco === "Jos(JED)") {
    //     MeterService.findMeterDetails({
    //       meterNo: user.meter_no,
    //       disco: "jos",
    //     }).then((data) => {
    //       const { status, info } = data;
    //       if (status !== 200) {
    //         setAlert(info);
    //       } else {
    //         setAlert("");
    //         setMeterDetails({
    //           accountNo: info.accountNo,
    //           accountType: info.accountType,
    //           address: info.address,
    //           balance: info.balance,
    //           contactNo: info.contactNo,
    //           meterNo: info.meterNo,
    //           minAmount: info.minAmount,
    //           name: info.name,
    //         });
    //       }
    //     });
    //   } else if (user.disco === "Kaduna(KAEDCO)") {
    //     MeterService.findMeterDetails({
    //       meterNo: user.meter_no,
    //       disco: "kaduna",
    //     }).then((data) => {
    //       const { status, info } = data;
    //       if (status !== 200) {
    //         setAlert(info);
    //       } else {
    //         setAlert("");
    //         setMeterDetails({
    //           accountNo: info.accountNo,
    //           accountType: info.accountType,
    //           address: info.address,
    //           balance: info.balance,
    //           contactNo: info.contactNo,
    //           meterNo: info.meterNo,
    //           minAmount: info.minAmount,
    //           name: info.name,
    //         });
    //       }
    //     });
    //   } else if (user.disco === "Abuja(AEDC)") {
    //     MeterService.findMeterDetails({
    //       meterNo: user.meter_no,
    //       disco: "abuja",
    //     }).then((data) => {
    //       const { status, info } = data;
    //       if (status !== 200) {
    //         setAlert(info);
    //       } else {
    //         setAlert("");
    //         setMeterDetails({
    //           accountNo: info.accountNo,
    //           accountType: info.accountType,
    //           address: info.address,
    //           balance: info.balance,
    //           contactNo: info.contactNo,
    //           meterNo: info.meterNo,
    //           minAmount: info.minAmount,
    //           name: info.name,
    //         });
    //       }
    //     });
    //   } else if (user.disco === "Enugu(EEDC)") {
    //     MeterService.findMeterDetails({
    //       meterNo: user.meter_no,
    //       disco: "enugu",
    //     }).then((data) => {
    //       const { status, info } = data;
    //       if (status !== 200) {
    //         setAlert(info);
    //       } else {
    //         setAlert("");
    //         setMeterDetails({
    //           accountNo: info.accountNo,
    //           accountType: info.accountType,
    //           address: info.address,
    //           balance: info.balance,
    //           contactNo: info.contactNo,
    //           meterNo: info.meterNo,
    //           minAmount: info.minAmount,
    //           name: info.name,
    //         });
    //       }
    //     });
    //   } else if (user.disco === "Benin(BEDC)") {
    //     MeterService.findMeterDetails({
    //       meterNo: user.meter_no,
    //       disco: "benin",
    //     }).then((data) => {
    //       const { status, info } = data;
    //       if (status !== 200) {
    //         setAlert(info);
    //       } else {
    //         setAlert("");
    //         setMeterDetails({
    //           accountNo: info.accountNo,
    //           accountType: info.accountType,
    //           address: info.address,
    //           balance: info.balance,
    //           contactNo: info.contactNo,
    //           meterNo: info.meterNo,
    //           minAmount: info.minAmount,
    //           name: info.name,
    //         });
    //       }
    //     });
    //   } else if (user.disco === "Aba(ABA)") {
    //     MeterService.findMeterDetails({
    //       meterNo: user.meter_no,
    //       disco: "aba",
    //     }).then((data) => {
    //       const { status, info } = data;
    //       if (status !== 200) {
    //         setAlert(info);
    //       } else {
    //         setAlert("");
    //         setMeterDetails({
    //           accountNo: info.accountNo,
    //           accountType: info.accountType,
    //           address: info.address,
    //           balance: info.balance,
    //           contactNo: info.contactNo,
    //           meterNo: info.meterNo,
    //           minAmount: info.minAmount,
    //           name: info.name,
    //         });
    //       }
    //     });
    //   } else if (user.disco === "Yola(YEDC)") {
    //     MeterService.findMeterDetails({
    //       meterNo: user.meter_no,
    //       disco: "yola",
    //     }).then((data) => {
    //       const { status, info } = data;
    //       if (status !== 200) {
    //         setAlert(info);
    //       } else {
    //         setAlert("");
    //         setMeterDetails({
    //           accountNo: info.accountNo,
    //           accountType: info.accountType,
    //           address: info.address,
    //           balance: info.balance,
    //           contactNo: info.contactNo,
    //           meterNo: info.meterNo,
    //           minAmount: info.minAmount,
    //           name: info.name,
    //         });
    //       }
    //     });
    //   } else {
    //     MeterService.findMeterDetails({
    //       meterNo: user.meter_no,
    //       disco: "ibadan",
    //     }).then((data) => {
    //       const { status, info } = data;
    //       if (status !== 200) {
    //         setAlert(info);
    //       } else {
    //         setAlert("");
    //         setMeterDetails({
    //           accountNo: info.accountNo,
    //           accountType: info.accountType,
    //           address: info.address,
    //           balance: info.balance,
    //           contactNo: info.contactNo,
    //           meterNo: info.meterNo,
    //           minAmount: info.minAmount,
    //           name: info.name,
    //         });
    //       }
    //     });
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  // function checkType(params) {
  //   if (meterDetails.accountType === "1" || "4") {
  //     return "Prepaid";
  //   }
  //   if (meterDetails.accountType === "5") {
  //     return "Postpaid";
  //   } else {
  //     return "";
  //   }
  // }
  return (
    <>
      <Button
        className="btn-round"
        type="button"
        onClick={() => setMeterstatusModal(true)}
        style={{
          backgroundColor: colors.primary,
          marginLeft: "-20px",
          color: colors.secondary,
        }}
      >
        Meter Status
      </Button>
      <Modal
        isOpen={meterstatusModal}
        toggle={() => setMeterstatusModal(false)}
        modalClassName="modal-register"
      >
        <div className="modal-header no-border-header text-center">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            style={{
              color: colors.secondary,
            }}
            onClick={() => setMeterstatusModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <h6 className="text-muted">Check Meter Status</h6>
        </div>
        <div className="modal-body">
          {hideForm === false ? (
            <Form onSubmit={onSubmit} className="form">
              <FormGroup>
                <label>Meter number</label>
                <Input
                  readOnly
                  name="meter_no"
                  value={user.meter_no}
                  className="form-control"
                  type="text"
                />
              </FormGroup>
              <Button
                block
                type="submit"
                className="btn-round"
                style={{
                  color: colors.secondary,
                  backgroundColor: colors.primary,
                }}
              >
                Submit
              </Button>
            </Form>
          ) : (
            <div
              style={{
                display: "grid",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <p style={{ color: colors.green, fontWeight: "bold" }}>{alert}</p>
              <Row>
                <Col>
                  <h6 style={{ color: colors.black, marginBottom: "0" }}>
                    Account No
                  </h6>
                  <span>{meterDetails.accountNo}</span>
                </Col>
                <Col>
                  <h6 style={{ color: colors.black, marginBottom: "0" }}>
                    Account Type
                  </h6>
                  <span>{meterDetails.accountType}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 style={{ color: colors.black, marginBottom: "0" }}>
                    Address
                  </h6>
                  <span>{meterDetails.address}</span>
                </Col>
                <Col>
                  <h6 style={{ color: colors.black, marginBottom: "0" }}>
                    Arrears
                  </h6>
                  <span>
                    <span style={{ color: colors.green }}>₦</span>
                    {meterDetails.balance}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 style={{ color: colors.black, marginBottom: "0" }}>
                    Rate
                  </h6>
                  <span style={{ color: colors.green }}>₦ </span>
                  N/A
                </Col>
                <Col>
                  <h6 style={{ color: colors.black, marginBottom: "0" }}>
                    Meter No
                  </h6>
                  <span>{meterDetails.meterNo}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 style={{ color: colors.black, marginBottom: "0" }}>
                    Minimum Payment
                  </h6>
                  <span>
                    <span style={{ color: colors.green }}>₦</span>
                    {meterDetails.minAmount}
                  </span>
                </Col>
                <Col>
                  <h6 style={{ color: colors.black, marginBottom: "0" }}>
                    Name
                  </h6>
                  <span>{meterDetails.name}</span>
                </Col>
              </Row>
              <Button
                block
                type="button"
                onClick={resetForm}
                className="btn-round"
                size="sm"
                style={{
                  color: colors.secondary,
                  backgroundColor: colors.primary,
                  width: "50%",
                }}
              >
                Reset
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default MeterStatus;
