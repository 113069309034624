import React, { useState, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
// core components

import colors from "config/colors.config";
import AdminService from "services/adminService";

import Message from "components/Message";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
// reactstrap components
import {
  Button,
  Row,
  Form,
  Input,
  Col,
  Card,
  CardTitle,
  CardBody,
  CardHeader,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";

createTheme("solarized", {
  context: {
    background: "#0399DA",
    text: "#FFFFFF",
  },
  divider: {
    default: "#ddd",
  },
});

const customStyles = {
  headCells: {
    style: {
      color: "#0399DA",
      fontWeight: "600",
    },
  },
};

const columns = [
  {
    name: "Date",
    selector: "date",
    sortable: true,
  },
  {
    name: "Amount",
    selector: "amount",
    sortable: true,
  },
  {
    name: "UserId",
    selector: "userId",
    sortable: true,
  },

  {
    name: "Invoice No",
    selector: "invoice_no",
    sortable: true,
  },

  {
    name: "Phone",
    selector: "phone_no",
  },

  {
    name: "Delivery Address",
    selector: "delivery_address",
  },
];

const columns2 = [
  {
    name: "UserId",
    selector: "userId",
    sortable: true,
  },
  {
    name: "Date",
    selector: "date",
    sortable: true,
  },
  {
    name: "Amount",
    selector: "amount",
    sortable: true,
  },
  {
    name: "Meter No",
    selector: "meter_no",
    sortable: true,
  },
  {
    name: "Token",
    selector: "token",
  },
  {
    name: "Invoice No",
    selector: "invoice_no",
    sortable: true,
  },
];
function TransactionsQueries(props) {
  const [iconTabs, setIconTabs] = React.useState("1");
  const [invoice, setInvoice] = useState({ invoice_no: "" });
  const [invoice2, setInvoice2] = useState({ invoice_no: "" });
  const [transactions, setTransactions] = useState([]);
  const [shopTransactions, setShopTransactions] = useState([]);
  const [tableData, setTableData] = useState({});
  const [tableData2, setTableData2] = useState({});
  const [transaction, setTransaction] = useState({
    phone_no: "",
    meter_no: "",
    meter_address: "",
    invoice_no: "",
    amount: "",
    response: "",
    token: "",
    date: "",
    userId: "",
    discoId: "",
    meterTypeId: "",
  });
  const [shopTransaction, setShopTransaction] = useState({
    id: "",
    phone_no: "",
    delivery_address: "",
    invoice_no: "",
    amount: "",
    response: "",
    date: "",
    updatedAt: "",
    userId: "",
  });

  const [alert, setAlert] = useState("");
  const [message, setMessage] = useState(null);
  const [response, setResponse] = useState("false");

  const [alert2, setAlert2] = useState("");

  const [alert3, setAlert3] = useState("");
  const [message3, setMessage3] = useState(null);
  const [response3, setResponse3] = useState("false");

  //Get All transactions

  useEffect(() => {
    setAlert2("Loading Transactions...");

    AdminService.getTransactions().then((data) => {
      let pdata = [];
      var obj;
      const reversed = data.reverse();
      reversed.forEach((transaction) => {
        obj = {
          date: transaction.date,
          amount: transaction.amount,
          meter_no: transaction.meter_no,
          token: transaction.token,
          invoice_no: transaction.invoice_no,
          userId: transaction.userId,
        };
        pdata.push(obj);
      });
      let tableD = {
        columns,
        data: pdata,
      };
      setTableData2(tableD);
      setTransactions(data);
      setAlert2("");
    });
  }, []);

  //Get All shop transactions

  useEffect(() => {
    setAlert2("Loading Transactions...");

    AdminService.getShopTransactions().then((data) => {
      let pdata = [];
      var obj;
      const reversed = data.reverse();

      reversed.forEach((transaction) => {
        obj = {
          date: transaction.date,
          amount: transaction.amount,
          userId: transaction.userId,
          phone_no: transaction.phone_no,
          invoice_no: transaction.invoice_no,
          delivery_address: transaction.delivery_address,
        };
        pdata.push(obj);
      });
      let tableD = {
        columns,
        data: pdata,
      };
      setTableData(tableD);
      setShopTransactions(data);
      setAlert2("");
    });
  }, []);

  //Search Transaction
  const resetResponse = (e) => {
    setResponse("false");
    setMessage("");
  };
  const onChange = (e) => {
    setInvoice({
      ...invoice,
      [e.target.name]: e.target.value,
    });
  };
  const resetForm = () => {
    setInvoice({
      invoice_no: "",
    });
  };

  const getTransaction = (e) => {
    e.preventDefault();
    setAlert("Searching");
    try {
      AdminService.findInvoice(invoice).then((data) => {
        if (!data.message) {
          setAlert("");
          setResponse("true");
          setMessage("");
          resetForm();
          setTransaction(data);
        } else {
          const { message } = data;
          setAlert("");
          setResponse("false");
          setMessage(message);
        }
      });
    } catch (error) {}
  };

  //Search shop transaction
  const resetResponse2 = (e) => {
    setResponse3("false");
    setMessage3("");
  };
  const onChange2 = (e) => {
    setInvoice2({
      ...invoice2,
      [e.target.name]: e.target.value,
    });
  };
  const resetForm2 = () => {
    setInvoice2({
      invoice_no: "",
    });
  };
  const getShopTransaction = (e) => {
    e.preventDefault();
    setAlert3("Searching");
    try {
      AdminService.findShopInvoice(invoice2).then((data) => {
        if (!data.message) {
          setAlert3("");
          setResponse3("true");
          setMessage3("");
          resetForm2();
          setShopTransaction(data);
        } else {
          const { message } = data;
          setAlert3("");
          setResponse3("false");
          setMessage3(message);
        }
      });
    } catch (error) {}
  };

  return (
    <div>
      <Card
        className="card-chart"
        body
        inverse
        style={{
          backgroundColor: colors.dashboard,
          color: colors.black,
        }}
      >
        <CardHeader>
          <Nav className="justify-content-center" role="tablist" tabs>
            <NavItem>
              <NavLink
                className={iconTabs === "1" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("1");
                }}
              >
                <i className="now-ui-icons education_glasses"></i>
                Power
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs === "2" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("2");
                }}
              >
                <i className="now-ui-icons  education_glasses"></i>
                Shop
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs === "3" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("3");
                }}
              >
                <i className="now-ui-icons media-2_sound-wave"></i>
                PowerT
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs === "4" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("4");
                }}
              >
                <i className="now-ui-icons shopping_cart-simple"></i>
                ShopT
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent className="text-center" activeTab={"iconTabs" + iconTabs}>
            <TabPane tabId="iconTabs1" style={{ color: colors.userTitle }}>
              {response === "false" ? (
                <div>
                  <div
                    className="col-md-6 offset-md-3"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                    }}
                  >
                    <Form onSubmit={getTransaction} className="form">
                      <Input
                        placeholder="Invoice number"
                        type="text"
                        name="invoice_no"
                        value={invoice.invoice_no}
                        required
                        onChange={onChange}
                        id="invoice_no"
                      ></Input>
                      <Button
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Find Invoice
                      </Button>
                    </Form>
                  </div>
                  {alert}
                  {message ? <Message message={message} /> : null}
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "50px",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "50px",
                    padding: "20px",
                    backgroundColor: colors.primary,
                  }}
                >
                  <div>
                    <Button
                      className="btn-round"
                      onClick={resetResponse}
                      style={{
                        color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      size="sm"
                    >
                      Reset
                    </Button>
                  </div>
                  <Row>
                    <Col className>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        PhoneNo
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.phone_no}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Amount
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.amount}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        MeterType
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.meterTypeId}
                      </h5>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        invoiceNo
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.invoice_no}
                      </h5>
                    </Col>

                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Date
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.date.slice(0, 10)}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        User ID
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.userId}
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        meterAddress
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.meter_address}
                      </h5>
                    </Col>

                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        DiscoId
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.discoId}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Meter No
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.meter_no}
                      </h5>
                    </Col>
                  </Row>

                  <br></br>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Token
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.token}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Response
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.response}
                      </h5>
                    </Col>
                  </Row>
                </div>
              )}
            </TabPane>
            <TabPane tabId="iconTabs2">
              {response3 === "false" ? (
                <div>
                  <div
                    className="col-md-6 offset-md-3"
                    style={{
                      color: colors.black,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                    }}
                  >
                    <Form onSubmit={getShopTransaction} className="form">
                      <Input
                        placeholder="Invoice number"
                        type="text"
                        name="invoice_no"
                        value={invoice2.invoice_no}
                        required
                        onChange={onChange2}
                        id="invoice_no"
                      ></Input>
                      <Button
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Find Invoice
                      </Button>
                    </Form>
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "50px",
                    }}
                  />
                  {alert3}
                  <div>{message3 ? <Message message={message3} /> : null}</div>
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "50px",
                    padding: "20px",
                    backgroundColor: colors.primary,
                  }}
                >
                  <div>
                    <Button
                      className="btn-round"
                      onClick={resetResponse2}
                      style={{
                        color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      size="sm"
                    >
                      Reset
                    </Button>
                  </div>
                  <Row>
                    <Col className>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        PhoneNo
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {shopTransaction.phone_no}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Amount
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {shopTransaction.amount}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        User ID
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {shopTransaction.userId}
                      </h5>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        invoiceNo
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {shopTransaction.invoice_no}
                      </h5>
                    </Col>

                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Date
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {shopTransaction.date.slice(0, 10)}
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Delivery Address
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {shopTransaction.delivery_address}
                      </h5>
                    </Col>

                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Id
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {shopTransaction.id}
                      </h5>
                    </Col>
                  </Row>

                  <br></br>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Updated Date
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {shopTransaction.updatedAt}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Response
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {shopTransaction.response}
                      </h5>
                    </Col>
                  </Row>
                </div>
              )}
            </TabPane>
            <TabPane tabId="iconTabs3">
              <DataTableExtensions
                {...tableData2}
                filter={true}
                export={false}
                print={false}
                filterHidden={false}
              >
                <DataTable
                  title="Transactions"
                  responsive={true}
                  pagination={true}
                  columns={columns2}
                  data={transactions}
                  progressComponent={alert2}
                  striped={true}
                  theme="solarized"
                  customStyles={customStyles}
                />
              </DataTableExtensions>
            </TabPane>
            <TabPane tabId="iconTabs4">
              <DataTableExtensions
                {...tableData}
                filter={true}
                export={false}
                print={false}
                filterHidden={false}
              >
                <DataTable
                  title="Shop transactions"
                  responsive={true}
                  pagination={true}
                  columns={columns}
                  data={shopTransactions}
                  theme="solarized"
                  customStyles={customStyles}
                />
              </DataTableExtensions>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
}

export default TransactionsQueries;
