import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import * as legoData from "../pops.json";

import {
  Row,
  Col,
  Card,
  UncontrolledTooltip,
  Button,
  Badge,
  CardHeader,
  CardBody,
  Modal,
} from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar";
import TransparentFooter from "components/Footers/TransparentFooter";
import MeterService from "services/meterService";
import { AuthContext } from "context/authContext";
import colors from "config/colors.config";

//import * as legoData from "../pops.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: legoData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

// function loadError(onError) {
//   console.error(`Failed ${onError.target.src} didn't load correctly`);
// }

function MeterPage(props) {
  let rechargeDetails = useParams({}).id;
  let history = useHistory();
  const rechargeItems = rechargeDetails.split("|");
  const [rechargeAmount] = useState({
    amount: rechargeItems[3].replace(",", ""),
  });
  const { user } = useContext(AuthContext);
  const [meterDetails, setMeterDetails] = useState({
    accountNo: "",
    accountType: "",
    address: "",
    balance: "",
    contactNo: "",
    message: "",
    meterNo: "",
    minAmount: "",
    name: "",
  });
  const [modalSmall, setModalSmall] = React.useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  let timerID = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timerID);
    };
  }, []);

  // function selectType(params) {
  //   if (params === "1") {
  //     return "Prepaid";
  //   }
  //   if (params === "4") {
  //     return "Prepaid";
  //   }
  //   if (params === "5") {
  //     return "Postpaid";
  //   }
  //   if (params === "2") {
  //     return "Postpaid";
  //   }
  // }

  // function roundToTwo(num) {
  //   return +(Math.round(num + "e+2") + "e-2");
  // }

  function rechargeTotal() {
    const commission = 100;
    const charge = 1.5 / 100;

    if (rechargeAmount.amount === "undefined") {
      return commission.toFixed(2);
    }
    if (rechargeAmount.amount <= 5000) {
      // return roundToTwo(Number(rechargeAmount.amount) + commission);
      return (Number(rechargeAmount.amount) + commission).toFixed(2);
    }
    if (rechargeAmount.amount > 5000) {
      // return roundToTwo(Number(rechargeAmount.amount) + commission);
      return (
        Number(rechargeAmount.amount) +
        charge * rechargeAmount.amount
      ).toFixed(2);
    }
  }
  const serviceCharge = (
    rechargeTotal() - Number(rechargeAmount.amount)
  ).toFixed(2);

  useEffect(() => {
    MeterService.findMeterDetails({
      meterNo: rechargeItems[1],
      disco: rechargeItems[2],
    }).then((data) => {
      setIsLoaded(true);
      const { status, info } = data;

      if (status !== 200) {
        if (info.includes("This meter is not correct")) {
          alert(`${rechargeItems[2]} Electric is not available.`);
        } else {
          alert(info);
        }
        history.push("/dashboard-page/");
        return;
      } else {
        setMeterDetails({
          ...meterDetails,
          accountType: info.accountType ? info.accountType : rechargeItems[4],
          accountNo: info.accountNo,
          address: info.address,
          balance: info.balance,
          contactNo: info.contactNo,
          message: info.message,
          meterNo: info.meterNo ? info.meterNo : rechargeItems[1],
          minAmount: info.minAmount,
          name: info.name,
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   // //production
  //   script.src = "https://api.gladepay.com/checkout.js";
  //   // //Staging
  //   //script.src = "http://demo.api.glade.ng/checkout.js";

  //   //script.async = typeof async === "undefined" ? true : async;
  //   //script.id = "cpay";
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  //Mobile Menu

  useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    //window.scrollTo(0, 0);
    //document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  function getDiscoID(params) {
    if (rechargeItems[2] === "ikeja") {
      return 2;
    }
    if (rechargeItems[2] === "eko") {
      return 1;
    }
    if (rechargeItems[2] === "ibadan") {
      return 3;
    }

    if (rechargeItems[2] === "abuja") {
      return 4;
    }

    if (rechargeItems[2] === "kaduna") {
      return 5;
    }

    if (rechargeItems[2] === "yola") {
      return 6;
    }

    if (rechargeItems[2] === "aba") {
      return 7;
    }

    if (rechargeItems[2] === "benin") {
      return 8;
    }

    if (rechargeItems[2] === "enugu") {
      return 9;
    }

    if (rechargeItems[2] === "jos") {
      return 10;
    }

    if (rechargeItems[2] === "portharcourt") {
      return 11;
    }

    if (rechargeItems[2] === "kano") {
      return 12;
    }
  }

  // function getDiscoID(rechargeItems) {
  //   const discoMap = {
  //     ikeja: 2,
  //     eko: 1,
  //     ibadan: 3,
  //     abuja: 4,
  //     kaduna: 5,
  //     yola: 6,
  //     aba: 7,
  //     benin: 8,
  //     enugu: 9,
  //     jos: 10,
  //     portharcourt: 11,
  //     kano: 12,
  //   };

  //   return discoMap[rechargeItems[2]] || null;
  // }

  const payNow = (e) => {
    e.preventDefault();
    //setIsLoaded(false);
    const billNo = "CP" + Math.floor(1000000000 + Math.random() * 900000000);
    if (rechargeItems[3].includes("-" || "+")) {
      window.alert("Negative amount not allowed");
      history.push("/dashboard-page/");
      return false;
    }
    if (
      parseFloat(rechargeAmount.amount) < parseFloat(meterDetails.minAmount)
    ) {
      window.alert("Amount lower than minimum amount");
      history.push("/dashboard-page/");
      return false;
    }

    window.MonnifySDK.initialize({
      amount: rechargeTotal(),
      currency: "NGN",
      reference: billNo,
      //reference: new String(new Date().getTime()),
      customerFullName: user.firstname + "" + user.lastname,
      customerEmail: user.email,
      apiKey: "MK_PROD_V3MDEW7G6A",
      contractCode: "253116876891",
      paymentDescription: "Power Vending",
      metadata: {
        name: user.firstname,
      },
      onLoadStart: () => {
        console.log("loading has started");
      },
      onLoadComplete: () => {
        console.log("SDK is UP");
      },

      onComplete: function (response) {
        if (response.status === "SUCCESS") {
          setIsLoaded(false);
          let meterType = meterDetails.accountType;
          MeterService.postTransaction({
            meterNo: meterDetails.meterNo,
            disco: rechargeItems[2],
            Amount: Number(rechargeAmount.amount).toFixed(2).toString(),
            PhoneNo: user.phone,
            Email: user.email,
            userId: user.id,
            meterTypeId: meterType.toLowerCase() === "prepaid" ? 1 : 2,
            meterAddress: meterDetails.address,
            invoiceNo: billNo,
            discoId: getDiscoID(),
            charge: serviceCharge,
            accountName: meterDetails.name,
            Name: user.firstname + ", " + user.lastname,
            provider: rechargeItems[2],
            gladeReference: response.transactionReference,
            accountNumber: meterDetails.accountNo,
          }).then((data) => {
            console.log(data);
            const { message } = data;
            if (message.status === 200) {
              setIsLoaded(true);
              history.push("/invoice-page/" + billNo);
            } else {
              window.alert(
                "Transaction not processed,contact admin ! " + message.msgBody
              );
              setIsLoaded(true);
              setModalSmall(true);
            }
          });
        } else {
          window.alert("Transaction failed!");
        }

        //Implement what happens when the transaction is completed.
        console.log(response);
      },
      onClose: function (data) {
        if (!data.hasOwnProperty("paymentReference")) {
          alert("You abandon the transaction by closing it.");
        }

        console.log(data);
      },
    });

    // window.initPayment({
    //   MID: "GP_EIuQ0u2r6n8cHha2cQsgGDZVwL0g4xWs",
    //   email: user.email,
    //   firstname: user.firstname,
    //   logo: "https://res.cloudinary.com/comparelight-com/image/upload/v1598268099/comparelight_logo.png",
    //   lastname: user.lastname,
    //   description: "Power Vending",
    //   customer_txnref: billNo,
    //   title: "Comparelight Power Vending",
    //   amount: rechargeTotal(),
    //   country: "NG",
    //   currency: "NGN",
    //   onclose: function () {
    //     alert("You abandon the transaction by closing it.");
    //   },

    //   callback: function (response) {
    //     setIsLoaded(false);

    //     if (response.txnStatus === "successful") {
    //       if (rechargeItems[2] === "Ikeja(IE)") {
    //         // window.alert("Getting your token... please wait");
    //         MeterService.postIETransaction({
    //           meterNo: meterDetails.accountNo,
    //           Amount: Number(rechargeAmount.amount).toFixed(2).toString(),
    //           PhoneNo: user.phone,
    //           Email: user.email,
    //           userId: user.id,
    //           meterTypeId: meterDetails.accountType,
    //           meterAddress: meterDetails.address,
    //           invoiceNo: billNo,
    //           discoId: getDiscoID(),
    //           charge: serviceCharge,
    //           accountName: meterDetails.name,
    //           Name: user.firstname + ", " + user.lastname,
    //           provider: rechargeItems[2],
    //           gladeReference: response.txnRef,
    //           accountNumber: meterDetails.accountNo,
    //         }).then((data) => {
    //           const { message } = data;
    //           if (message.status === 200) {
    //             setIsLoaded(true);
    //             history.push("/invoice-page/" + billNo);
    //           } else {
    //             setIsLoaded(true);
    //             setModalSmall(true);
    //           }
    //         });
    //       }
    //       if (rechargeItems[2] === "Ibadan(IBEDC)") {
    //         MeterService.postIBEDCTransaction({
    //           meterNo: meterDetails.accountNo,
    //           Amount: Number(rechargeAmount.amount).toFixed(2).toString(),
    //           PhoneNo: user.phone,
    //           Email: user.email,
    //           userId: user.id,
    //           meterTypeId: meterDetails.accountType,
    //           meterAddress: meterDetails.address,
    //           invoiceNo: billNo,
    //           discoId: getDiscoID(),
    //           charge: serviceCharge,
    //           accountName: meterDetails.name,
    //           Name: user.firstname + ", " + user.lastname,
    //           provider: rechargeItems[2],
    //           gladeReference: response.txnRef,
    //           accountNumber: meterDetails.accountNo,
    //         }).then((data) => {
    //           const { message } = data;
    //           if (message.status === 200) {
    //             setIsLoaded(true);
    //             history.push("/invoice3-page/" + billNo);
    //           } else {
    //             setIsLoaded(true);
    //             setModalSmall(true);
    //           }
    //         });
    //       }
    //       if (rechargeItems[2] === "Eko(EKEDC)") {
    //         // window.alert("Getting your token... please wait");
    //         MeterService.postEKOTransaction({
    //           meterNo: meterDetails.accountNo,
    //           Amount: Number(rechargeAmount.amount).toFixed(2).toString(),
    //           PhoneNo: user.phone,
    //           Email: user.email,
    //           userId: user.id,
    //           meterTypeId: meterDetails.accountType,
    //           meterAddress: meterDetails.address,
    //           invoiceNo: billNo,
    //           discoId: getDiscoID(),
    //           charge: serviceCharge,
    //           accountName: meterDetails.name,
    //           Name: user.firstname + ", " + user.lastname,
    //           provider: rechargeItems[2],
    //           gladeReference: response.txnRef,
    //           accountNumber: meterDetails.accountNo,
    //         }).then((data) => {
    //           const { message } = data;
    //           if (message.status === 200) {
    //             setIsLoaded(true);
    //             history.push("/invoice1-page/" + billNo);
    //           } else {
    //             setIsLoaded(true);
    //             setModalSmall(true);
    //           }
    //         });
    //       }
    //     } else {
    //       window.alert("Transaction failed!");
    //     }
    //   },
    // });

    return true;
    //"GP_EIuQ0u2r6n8cHha2cQsgGDZVwL0g4xWs",
    //"GP0000001"
  };

  return (
    <div>
      <AuthNavbar />
      {isLoaded === true ? (
        <div>
          <h2
            style={{
              color: colors.pageHeader,
              marginBottom: "0px",
              fontWeight: "600",
              marginTop: "62px",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            Meter Recharge Details
          </h2>
          <Modal
            isOpen={modalSmall}
            className="modal-sm"
            modalClassName="bd-example-modal-sm"
            toggle={() => setModalSmall(false)}
          >
            <div className="modal-header">
              <h4 className="modal-title" id="mySmallModalLabel">
                Token failed!
              </h4>
              <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModalSmall(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Button
                className="btn-round"
                style={{
                  backgroundColor: colors.primary,
                  color: colors.secondary,
                }}
                target="blank"
                type="button"
                href="https://wa.me/2348174735017"
              >
                Chat
              </Button>
            </div>
          </Modal>
          <Row
            style={{
              marginTop: "20px",
              marginLeft: "20px",
              marginRight: "20px",
              backgroundImage:
                "url(https://res.cloudinary.com/dfszquucy/image/upload/v1573809865/Group_132_h7ftcl.png)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center right",
              backgroundPositionX: "500px",
              backgroundPositionY: "150px",
              backgroundSize: "contain",
              minHeight: "900px",
            }}
          >
            <Col>
              <Card
                style={{
                  backgroundColor: "rgba(248, 244, 238, 60%)",
                  //minHeight: "850px"
                }}
              >
                <CardBody>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CardHeader>
                      <h4
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "300",
                          lineHeight: "1.2em",
                          marginTop: " 0px",
                          color: "#4D4D4D",
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        Distribution Company
                      </h4>
                      <hr
                        className="warning"
                        style={{
                          backgroundColor: colors.primary,
                          marginBottom: "10px",
                        }}
                      />
                    </CardHeader>

                    <h3
                      style={{
                        fontSize: "1.8em",
                        fontWeight: "bold",
                        lineHeight: "0.8em",
                        marginTop: " 0px",
                        marginBottom: "10px",
                        color: "black",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      {rechargeItems[2]}
                    </h3>
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CardHeader>
                      <h4
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "300",
                          lineHeight: "1.2em",
                          marginTop: " 0px",
                          color: "#4D4D4D",
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        Meter Number
                      </h4>
                      <hr
                        className="warning"
                        style={{
                          backgroundColor: colors.primary,
                          marginBottom: "10px",
                        }}
                      />
                    </CardHeader>

                    <h3
                      style={{
                        fontSize: "1.8em",
                        fontWeight: "bold",
                        lineHeight: "0.8em",
                        marginTop: " 0px",
                        marginBottom: "10px",
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      {meterDetails.meterNo}
                    </h3>
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CardHeader>
                      <h4
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "300",
                          lineHeight: "1.2em",
                          marginTop: " 0px",
                          color: "#4D4D4D",
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        Account Number
                      </h4>
                      <hr
                        className="warning"
                        style={{
                          backgroundColor: colors.primary,
                          marginBottom: "10px",
                        }}
                      />
                    </CardHeader>

                    <h3
                      style={{
                        fontSize: "1.8em",
                        fontWeight: "bold",
                        lineHeight: "0.8em",
                        marginTop: " 0px",
                        marginBottom: "10px",
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      {meterDetails.accountNo}
                    </h3>
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CardHeader>
                      <h4
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "300",
                          lineHeight: "1.2em",
                          marginTop: " 0px",
                          color: "#4D4D4D",
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        Meter Type
                      </h4>
                      <hr
                        className="warning"
                        style={{
                          backgroundColor: colors.primary,
                          marginBottom: "10px",
                        }}
                      />
                    </CardHeader>

                    <h3
                      style={{
                        fontSize: "1.8em",
                        fontWeight: "bold",
                        lineHeight: "0.8em",
                        marginTop: " 0px",
                        marginBottom: "10px",
                        color: "black",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      {meterDetails.accountType}
                    </h3>
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CardHeader>
                      <h4
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "300",
                          lineHeight: "1.2em",
                          marginTop: " 0px",
                          color: "#4D4D4D",
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        Account Name
                      </h4>
                      <hr
                        className="warning"
                        style={{
                          backgroundColor: colors.primary,
                          marginBottom: "10px",
                        }}
                      />
                    </CardHeader>

                    <h3
                      style={{
                        fontSize: "1.8em",
                        fontWeight: "bold",
                        lineHeight: "0.8em",
                        marginTop: " 0px",
                        marginBottom: "10px",
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      {meterDetails.name}
                    </h3>
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CardHeader>
                      <h4
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "300",
                          lineHeight: "1.2em",
                          marginTop: " 0px",
                          color: "#4D4D4D",
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        Meter Address
                      </h4>
                      <hr
                        className="warning"
                        style={{
                          backgroundColor: colors.primary,
                          marginBottom: "10px",
                        }}
                      />
                    </CardHeader>

                    <h3
                      style={{
                        fontSize: "1.8em",
                        fontWeight: "bold",
                        lineHeight: "0.8em",
                        marginTop: " 0px",
                        marginBottom: "10px",
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      {meterDetails.address}
                    </h3>
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CardHeader>
                      <h4
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "300",
                          lineHeight: "1.2em",
                          marginTop: " 0px",
                          color: "#4D4D4D",
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        Amount
                      </h4>
                      <hr
                        className="warning"
                        style={{
                          backgroundColor: colors.primary,
                          marginBottom: "10px",
                        }}
                      />
                    </CardHeader>

                    <h3
                      style={{
                        fontSize: "1.8em",
                        fontWeight: "bold",
                        lineHeight: "0.8em",
                        marginTop: " 0px",
                        marginBottom: "10px",
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ color: colors.green }}>₦</span>
                      {rechargeAmount.amount === "undefined"
                        ? "0.00"
                        : rechargeItems[3]}
                    </h3>
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CardHeader>
                      <h4
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "300",
                          lineHeight: "1.2em",
                          marginTop: " 0px",
                          color: "#4D4D4D",
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        Minimum Payment
                      </h4>
                      <hr
                        className="warning"
                        style={{
                          backgroundColor: colors.primary,
                          marginBottom: "10px",
                        }}
                      />
                    </CardHeader>

                    <h3
                      style={{
                        fontSize: "1.8em",
                        fontWeight: "bold",
                        lineHeight: "0.8em",
                        marginTop: " 0px",
                        marginBottom: "10px",
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ color: colors.green }}>₦</span>
                      {meterDetails.minAmount}
                    </h3>
                    {/* <div className="tooltip">
      <h6>
        + Convenience charge: ₦100
        <br />
        What is convenience charge? Click here{" "}
        <span className="tooltiptext">
          A CBN charge plus transaction cost for fund transfer
          within banks and this platform. Thank you for your
          understanding.
        </span>
      </h6>
    </div> */}
                    <Badge
                      style={{
                        // color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      id="html"
                    >
                      What is this?
                    </Badge>
                    {` `}
                    <UncontrolledTooltip
                      placement="top"
                      target="#html"
                      delay={0}
                    >
                      <em>Minimum payment required on your account</em>{" "}
                      <u>with</u> <b>IE</b>
                    </UncontrolledTooltip>
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {/* <CardHeader>
      <h4
        style={{
          fontSize: "1.5em",
          fontWeight: "300",
          lineHeight: "1.2em",
          marginTop: " 0px",
          color: "#4D4D4D",
          marginBottom: "0px",
          textAlign: "center",
        }}
      >
        Outstanding
      </h4>
      <hr
        className="warning"
        style={{
          backgroundColor: colors.primary,
          //marginTop: "30px",
        }}
      />
    </CardHeader> */}
                    <h4
                      style={{
                        fontSize: "1.5em",
                        fontWeight: "300",
                        lineHeight: "1.2em",
                        marginTop: " 0px",
                        color: "#4D4D4D",
                        marginBottom: "0px",
                        textAlign: "center",
                      }}
                    >
                      Payment Total
                    </h4>
                    <br />
                    <h3
                      style={{
                        fontSize: "1.8em",
                        fontWeight: "bold",
                        lineHeight: "0.8em",
                        marginTop: " 0px",
                        marginBottom: "10px",
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ color: colors.green }}>₦</span>{" "}
                      {rechargeTotal()}
                    </h3>
                    <p
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Total payable includes N100 transaction fee for
                      transactions less than{" "}
                      <span style={{ color: colors.green }}>₦</span> 5000{" "}
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                      }}
                    >
                      or 1.5% for transactions greater than{" "}
                      <span style={{ color: colors.green }}>₦</span>5000{" "}
                    </p>
                    <Button
                      className="btn-round"
                      onClick={payNow}
                      style={{
                        color: colors.secondary,
                        // marginLeft: "-20px",
                        backgroundColor: colors.primary,
                      }}
                    >
                      Pay Now
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{ marginTop: "10rem" }}>
          <FadeIn>
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Lottie options={defaultOptions} height={300} width={300} />
              <h5 style={{ position: "center" }}>Pls wait...</h5>
            </div>
          </FadeIn>
        </div>
      )}

      <TransparentFooter />
    </div>
  );
}

export default MeterPage;
