import React, { useState, useRef, useEffect } from "react";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import ReactTextTransition, { presets } from "react-text-transition";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar";
import { useUser } from "components/Headers/IndexHeader";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import colors from "config/colors.config";
import Message from "components/Message";
import AuthService from "../services/authService";
import * as legoData from "../pops.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: legoData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function RegisterPage(props) {
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [firstnameFocus, setFirstnameFocus] = useState(false);
  const [lastnameFocus, setLastnameFocus] = useState(false);
  const [meterFocus, setMeterFocus] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);
  const [discoFocus, setDiscoFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [message, setMessage] = useState(null);
  const [isLoaded, setIsLoaded] = useState(undefined);
  const [alert] = useState("Creating Account...");
  const [user, setUser] = useUser({
    username: "",
    password: "set Password",
    email: "",
    phone: "",
    disco: "",
    meter_no: "",
    firstname: "",
    lastname: "",
  });
  let timerID = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timerID);
    };
  }, []);

  const onChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
    console.log(user);
  };

  const resetForm = () => {
    setUser({
      password: "",
      username: "",
      email: "",
      phone: "",
      disco: "",
      meter_no: "",
      firstname: "",
      lastname: "",
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    AuthService.register({
      username: user.phone,
      email: user.email,
      phone: user.phone,
      firstname: user.firstname,
      lastname: user.lastname,
      meter_no: user.meter_no,
      password: user.password,
      disco: user.disco,
    }).then((data) => {
      console.log(data);
      const { message } = data;
      setMessage(message);
      resetForm();
      timerID = setTimeout(() => {
        setIsLoaded(true);
        if (!message.msgError) {
          timerID = setTimeout(() => {
            props.history.push("/login-page");
          }, 1000);
        }
      }, 5000);
    });
  };
  useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <IndexNavbar />
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(https://res.cloudinary.com/comparelight-com/image/upload/v1598344044/page_background.jpg)",
          }}
        ></div>
        <div className="content">
          <Container>
            {isLoaded === false ? (
              <FadeIn>
                <div
                  className="container"
                  style={{
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Lottie options={defaultOptions} height={300} width={300} />
                  <ReactTextTransition
                    text={alert}
                    spring={presets.gentle}
                    delay={300}
                    className="h3"
                    inline
                  />
                </div>
              </FadeIn>
            ) : (
              <div>
                <h2 style={{ marginTop: "100px" }}>Register Here</h2>
                <Col className="ml-auto mr-auto" lg="6">
                  <Card className="card-login card-plain " lg="6">
                    <Form onSubmit={onSubmit} className="form">
                      <CardHeader className="text-center">
                        <div
                          className="logo-container"
                          style={{ marginBottom: "10px", width: "100px" }}
                        >
                          <img
                            alt="comparelight logo"
                            src={
                              "https://res.cloudinary.com/comparelight-com/image/upload/v1598268099/comparelight_logo.png"
                            }
                          ></img>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col className="col-md-6">
                            <InputGroup
                              className={
                                "no-border input-lg" +
                                (firstnameFocus ? " input-group-focus" : "")
                              }
                            >
                              <Input
                                placeholder="FirstName..."
                                value={user.firstname}
                                onChange={onChange}
                                required
                                id="firstname"
                                label="FirstName"
                                name="firstname"
                                type="firstname"
                                onFocus={() => setFirstnameFocus(true)}
                                onBlur={() => setFirstnameFocus(false)}
                              ></Input>
                            </InputGroup>
                          </Col>
                          <Col className="col-md-6">
                            <InputGroup
                              className={
                                "no-border input-lg" +
                                (lastnameFocus ? " input-group-focus" : "")
                              }
                            >
                              <Input
                                placeholder="LastName..."
                                value={user.lastname}
                                onChange={onChange}
                                required
                                id="lastname"
                                label="LastName"
                                name="lastname"
                                type="lastname"
                                onFocus={() => setLastnameFocus(true)}
                                onBlur={() => setLastnameFocus(false)}
                              ></Input>
                            </InputGroup>
                          </Col>
                        </Row>

                        <InputGroup
                          className={
                            "no-border input-lg" +
                            (phoneFocus ? " input-group-focus" : "")
                          }
                        >
                          <Input
                            placeholder="PhoneNumber..."
                            value={user.phone}
                            onChange={onChange}
                            required
                            id="phone"
                            label="PhoneNumber"
                            name="phone"
                            type="tel"
                            onFocus={() => setPhoneFocus(true)}
                            onBlur={() => setPhoneFocus(false)}
                          ></Input>
                        </InputGroup>
                        <InputGroup
                          className={
                            "no-border input-lg" +
                            (meterFocus ? " input-group-focus" : "")
                          }
                        >
                          <Input
                            placeholder="MeterNumber..."
                            value={user.meter_no}
                            onChange={onChange}
                            required
                            id="meter_no"
                            label="MeterNumber"
                            name="meter_no"
                            type="text"
                            onFocus={() => setMeterFocus(true)}
                            onBlur={() => setMeterFocus(false)}
                          ></Input>
                        </InputGroup>

                        <InputGroup
                          className={
                            "no-border input-lg" +
                            (emailFocus ? " input-group-focus" : "")
                          }
                        >
                          <Input
                            placeholder="Email..."
                            variant="outlined"
                            required
                            value={user.email}
                            onChange={onChange}
                            id="email"
                            name="email"
                            type="email"
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                          ></Input>
                        </InputGroup>
                        <InputGroup
                          className={
                            "no-border input-lg" +
                            (passwordFocus ? " input-group-focus" : "")
                          }
                        >
                          <Input
                            placeholder="Set Password"
                            variant="outlined"
                            required
                            value={user.password}
                            onChange={onChange}
                            name="password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onFocus={() => setPasswordFocus(true)}
                            onBlur={() => setPasswordFocus(false)}
                          ></Input>
                        </InputGroup>
                        <br></br>
                        <InputGroup
                          className={
                            "no-border input-lg" +
                            (discoFocus ? " input-group-focus" : "")
                          }
                        >
                          <Input
                            variant="outlined"
                            required
                            value={user.disco}
                            onChange={onChange}
                            id="disco"
                            name="disco"
                            autoComplete="disco"
                            type="select"
                            onFocus={() => setDiscoFocus(true)}
                            onBlur={() => setDiscoFocus(false)}
                          >
                            <option>Disco...</option>
                            <option>Ikeja(IE)</option>
                            <option>Eko(EKEDC)</option>
                            <option>Ibadan(IBEDC)</option>
                            <option>Yola(YEDC)</option>
                            <option>Aba(ABA)</option>
                            <option>Benin(BEDC)</option>
                            <option>Enugu(EEDC)</option>
                            <option>Abuja(AEDC)</option>
                            <option>Kaduna(KAEDCO)</option>
                            <option>Jos(JED)</option>
                            <option>Ph(PHED)</option>
                            <option>Kano(KEDCO)</option>
                          </Input>
                        </InputGroup>
                      </CardBody>
                      <CardFooter className="text-center">
                        <Button
                          block
                          className="btn-round"
                          type="submit"
                          style={{
                            color: colors.secondary,
                            backgroundColor: colors.primary,
                          }}
                          size="lg"
                        >
                          Register
                        </Button>
                        {message ? <Message message={message} /> : null}
                        <div className="pull-left">
                          <h6>
                            <a className="link" href="/login-page">
                              Login
                            </a>
                          </h6>
                        </div>
                        <div className="pull-right">
                          <h6>
                            <a
                              className="link"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Need Help?
                            </a>
                          </h6>
                        </div>
                      </CardFooter>
                      <br></br>
                      <br></br>
                    </Form>
                  </Card>
                </Col>
              </div>
            )}
          </Container>
        </div>

        <div style={{ marginTop: "50px" }}>
          <TransparentFooter />
        </div>
      </div>
    </>
  );
}

export default RegisterPage;
