import React, { useEffect } from "react";

//reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import CustomSolutions from "./index-section/CustomSolutions";
import GetInTouch from "./index-section/GetInTouch.js";
import Download from "./index-section/Download.js";
import colors from "config/colors.config";
import AuthService from "services/authService";

// sections for this page

function IndexPage(props) {
  const onClickRechargeHandler = () => {
    AuthService.isAuthenticated().then((data) => {
      const { isAuthenticated } = data;
      if (isAuthenticated === true) {
        props.history.push("/dashboard-page");
      } else {
        props.history.push("/login-page");
      }
    });
  };

  useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <IndexHeader />
        <div className="section ">
          <Container className="text-center">
            <Row className="justify-content-md-center">
              <Col lg="8" md="12">
                <h2 className="text-center">
                  Recharge & manage your home or office electricity. <br></br>
                  <small>Prepaid & Postpaid meters.</small>
                </h2>

                <h5 className="description">
                  Join thousands of electricity consumers <br /> presently using
                  comparelight.com.
                </h5>
              </Col>

              <Col md="10">
                <Button
                  className="btn-round"
                  style={{
                    color: colors.secondary,
                    backgroundColor: colors.primary,
                  }}
                  onClick={onClickRechargeHandler}
                >
                  Recharge
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-tabs">
          <Container className="text-center">
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Our recent solutions</h2>
                <h5 className="description">
                  Comparelight solutions for homes & businesses.
                </h5>
                <br />
                <Button
                  className="btn-round"
                  style={{
                    color: colors.secondary,
                    backgroundColor: colors.primary,
                  }}
                  href="/shop-page"
                >
                  See Details
                </Button>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="3">
                <div className="info">
                  <img
                    alt="Pump water remotely"
                    width="50px"
                    className="n-logo"
                    src={
                      "https://res.cloudinary.com/comparelight-com/image/upload/v1598281455/drop_icon.png"
                    }
                  ></img>
                  <div className="description">
                    <h4 className="info-title">Domestic Water</h4>
                    <p className="description">
                      Pump water remotely & check water level.
                    </p>
                    <Button
                      className="btn-link"
                      style={{ color: colors.primary }}
                      href="/domesticwater"
                    >
                      Learn more
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <img
                    alt="power alert"
                    width="50px"
                    className="n-logo"
                    src={
                      "https://res.cloudinary.com/comparelight-com/image/upload/v1598281769/powerAlert_icon.png"
                    }
                  ></img>
                  <div className="description">
                    <h4 className="info-title">Power Alert</h4>
                    <p>Power supply alert by text & in-app.</p>
                    <Button
                      className="btn-link"
                      style={{ color: colors.primary }}
                      href="#pablo"
                    >
                      Learn more
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <img
                    alt="shared electricity"
                    width="50px"
                    className="n-logo"
                    src={
                      "https://res.cloudinary.com/comparelight-com/image/upload/v1598281936/share_electricity.png"
                    }
                  ></img>
                  <div className="description">
                    <h4 className="info-title">Share Electricity</h4>
                    <p>Share electricity within a shared apartment.</p>
                    <Button
                      className="btn-link"
                      style={{ color: colors.primary }}
                      href="#pablo"
                    >
                      Learn more
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <img
                    alt="Energy management"
                    width="50px"
                    className="n-logo"
                    src={
                      "https://res.cloudinary.com/comparelight-com/image/upload/v1598282137/energy-management.png"
                    }
                  ></img>
                  <div className="description">
                    <h4 className="info-title">Energy Manager</h4>
                    <p>Monitor & control your appliances remotely.</p>
                    <Button
                      className="btn-link"
                      style={{ color: colors.primary }}
                      href="#pablo"
                    >
                      Learn more
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <CustomSolutions />
        <div className="section">
          <Container className="text-center">
            <Row className="justify-content-md-center">
              <Col lg="8" md="12">
                <h2 className="text-center">Why use comparelight</h2>
                <h5 className="description">
                  We don't just sell power. We help you save on your energy
                  consumption. We answer your energy related technical
                  questions. With comparelight, you are first in the scheme of
                  things!
                </h5>
              </Col>
            </Row>
          </Container>
        </div>
        <GetInTouch />
        <Download />
        <DarkFooter />
      </div>
    </>
  );
}

export default IndexPage;
