import Cookies from "universal-cookie";
const cookies = new Cookies();
let tok = cookies.get("access_token");
let url = "https://smswallet.com.ng:9091";
//let url = "http://localhost:7070";

export default {
  findMeterDetails: (payload) => {
    return fetch(url + "/meter/getmeterdetails", {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
  // findIbMeterDetails: (meterNo) => {
  //   return fetch(url + "/ib/getmeterdetails", {
  //     method: "post",
  //     body: JSON.stringify(meterNo),
  //     headers: {
  //       "Content-Type": "application/json",
  //       access_token: tok,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => data);
  // },
  getMeterOutstanding: (payload) => {
    return fetch(url + "/meter/getMeterOutstanding", {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
  // getIbMeterOutstanding: (meterNo) => {
  //   return fetch(url + "/ib/getMeterOutstanding", {
  //     method: "post",
  //     body: JSON.stringify(meterNo),
  //     headers: {
  //       "Content-Type": "application/json",
  //       access_token: tok,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => data);
  // },
  // postIETransaction: (chargeLoad) => {
  //   return fetch(url + "/ie/rechargeie", {
  //     method: "post",
  //     body: JSON.stringify(chargeLoad),
  //     headers: {
  //       "Content-Type": "application/json",
  //       access_token: tok,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => data);
  // },
  postTransaction: (chargeLoad) => {
    return fetch(url + "/meter/recharge", {
      method: "post",
      body: JSON.stringify(chargeLoad),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  getDiscoSlug(disco) {
    let discoHubs = {
      "Ikeja(IE)": "ikeja",
      "Eko(EKEDC)": "eko",
      "Ibadan(IBEDC)": "ibadan",
      "Yola(YEDC)": "yola",
      "Aba(ABA)": "aba",
      "Benin(BEDC)": "benin",
      "Enugu(EEDC)": "enugu",
      "Abuja(AEDC)": "abuja",
      "Kaduna(KAEDCO)": "kaduna",
      "Jos(JED)": "jos",
      "Ph(PHED)": "portharcourt",
      "Kano(KEDCO)": "kano",
    };

    return discoHubs[disco];
  },

  // postEKOTransaction: (chargeLoad) => {
  //   return fetch(url + "/ek/rechargeek2", {
  //     method: "post",
  //     body: JSON.stringify(chargeLoad),
  //     headers: {
  //       "Content-Type": "application/json",
  //       access_token: tok,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => data);
  // },
};
