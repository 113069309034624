import React, { useState, useContext, useRef, useEffect } from "react";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import ReactTextTransition, { presets } from "react-text-transition";
import Cookies from "universal-cookie";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar";
import { useUser } from "components/Headers/IndexHeader";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import * as legoData from "../pops.json";
import Message from "components/Message";
import AuthService from "../services/authService";
import { AuthContext } from "../context/authContext";
import colors from "config/colors.config";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: legoData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function LoginPage(props) {
  const [alert, setAlert] = useState("");
  const [isLoaded, setIsLoaded] = useState(undefined);
  const [user, setUser] = useUser({ username: "", password: "" });
  const [message, setMessage] = useState(null);
  const authContext = useContext(AuthContext);
  const [nameFocus, setNameFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  let timerID = useRef(null);

  const onChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerID);
    };
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setAlert("Validating User...");
    setIsLoaded(false);
    try {
      timerID = setTimeout(() => {
        AuthService.welcome(user).then((data) => {
          const { message } = data;

          if (message.msgBody === "Please Register") {
            setIsLoaded(true);
            setMessage(message);
          }
          if (message.msgBody === "Please Login" && message.user === "") {
            setIsLoaded(true);
            props.history.push("/update-page/" + user.username);
          }
          if (message.msgBody === "Please Login" && message.user !== "") {
            AuthService.login(user).then((data) => {
              const { isAuthenticated, user, message, access_token } = data;

              const cookies = new Cookies();
              cookies.set("access_token", access_token, { path: "/" });

              setMessage(message);
              if (isAuthenticated) {
                setAlert("Logging in...");
                timerID = setTimeout(() => {
                  setIsLoaded(true);
                }, 2000);
                timerID = setTimeout(() => {
                  authContext.setUser(user);
                  authContext.setIsAuthenticated(isAuthenticated);
                  props.history.push("dashboard-page");
                  window.location.href = "/dashboard-page";
                }, 1000);
              } else {
                setIsLoaded(true);
                setMessage(message);
              }
            });
          }
        });
      }, 2000);
    } catch (error) {
      console.log(error);
      setMessage(message);
    }
  };

  useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <IndexNavbar />
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(https://res.cloudinary.com/comparelight-com/image/upload/v1598344044/page_background.jpg)",
          }}
        ></div>
        <div className="content">
          <Container>
            {isLoaded === false ? (
              <FadeIn>
                <div
                  className="container"
                  style={{
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Lottie options={defaultOptions} height={300} width={300} />
                  <ReactTextTransition
                    text={alert}
                    spring={presets.gentle}
                    delay={300}
                    className="h3"
                    inline
                  />
                </div>
              </FadeIn>
            ) : (
              <div>
                <h2 style={{ marginTop: "100px" }}>Login</h2>
                <Col className="ml-auto mr-auto" md="4">
                  <Card className="card-login card-plain">
                    <Form onSubmit={onSubmit} className="form">
                      <CardHeader className="text-center">
                        <div
                          className="logo-container"
                          style={{ marginBottom: "10px", width: "100px" }}
                        >
                          <img
                            alt="comparelight logo"
                            src={
                              "https://res.cloudinary.com/comparelight-com/image/upload/v1598268099/comparelight_logo.png"
                            }
                          ></img>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <InputGroup
                          className={
                            "no-border input-lg" +
                            (nameFocus ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons users_circle-08"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Phone number..."
                            variant="outlined"
                            required
                            value={user.username || ""}
                            id="username"
                            onChange={onChange}
                            label="UserName"
                            name="username"
                            type="text"
                            onFocus={() => setNameFocus(true)}
                            onBlur={() => setNameFocus(false)}
                          ></Input>
                        </InputGroup>
                        <InputGroup
                          className={
                            "no-border input-lg" +
                            (passwordFocus ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons text_caps-small"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Enter password..."
                            variant="outlined"
                            required
                            name="password"
                            onChange={onChange}
                            type="password"
                            id="password"
                            onFocus={() => setPasswordFocus(true)}
                            onBlur={() => setPasswordFocus(false)}
                          ></Input>
                        </InputGroup>
                      </CardBody>
                      <CardFooter className="text-center">
                        <Button
                          block
                          className="btn-round"
                          type="submit"
                          style={{
                            color: colors.secondary,
                            backgroundColor: colors.primary,
                          }}
                          size="lg"
                        >
                          Login
                        </Button>
                        {message ? <Message message={message} /> : null}
                        <div className="pull-left">
                          <h6>
                            <a className="link" href="/register-page">
                              Register
                            </a>
                          </h6>
                        </div>
                        <div className="pull-right">
                          <h6>
                            <a className="link" href="/forgotPassword-page">
                              Forgot Password?
                            </a>
                          </h6>
                        </div>
                      </CardFooter>
                    </Form>
                  </Card>
                </Col>
              </div>
            )}
          </Container>
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default LoginPage;
