import Cookies from "universal-cookie";
const cookies = new Cookies();
let tok = cookies.get("access_token");
let url = "https://smswallet.com.ng:9091";
//let url = "http://localhost:7070";

export default {
  findInvoice: (invoice) => {
    return fetch(url + "/findinvoice", {
      method: "post",
      body: JSON.stringify(invoice),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
  findShopInvoice: (invoice) => {
    return fetch(url + "/findshopinvoice", {
      method: "post",
      body: JSON.stringify(invoice),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
  createTransaction: (transaction) => {
    return fetch(url + "/createtransaction", {
      method: "post",
      body: JSON.stringify(transaction),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  updateTransaction: (transaction) => {
    return fetch(url + "/updatetransaction", {
      method: "put",
      body: JSON.stringify(transaction),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  updateFailedTransaction: (transaction) => {
    return fetch(url + "/updatefailedtransaction", {
      method: "put",
      body: JSON.stringify(transaction),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  getAllLiveFailedTransaction: () => {
    return fetch(url + "/alllivefailedtransactions", {
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    }).then((response) => {
      if (response.status !== 500) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "Error", msgError: true } };
    });
  },

  deleteTransaction: (transaction) => {
    return fetch(url + "/deletetransaction", {
      method: "delete",
      body: JSON.stringify(transaction),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  findUser: (userPhone) => {
    return fetch(url + "/finduser", {
      method: "post",
      body: JSON.stringify(userPhone),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  createUser: (user) => {
    return fetch(url + "/register", {
      method: "post",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  updateUser: (user) => {
    return fetch(url + "/updateuser", {
      method: "put",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  verifyUsername: (user) => {
    return fetch(url + "/verifyusername", {
      method: "post",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  findFailedInvoice: (invoice) => {
    return fetch(url + "/findfailedinvoice", {
      method: "post",
      body: JSON.stringify(invoice),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  getFailedTransactions: () => {
    return fetch(url + "/allfailedtransactions", {
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    }).then((response) => {
      if (response.status !== 500) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "Error", msgError: true } };
    });
  },

  getWaitingTransactions: () => {
    return fetch(url + "/allwaitingtransactions", {
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    }).then((response) => {
      if (response.status !== 500) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "Error", msgError: true } };
    });
  },

  getResolvedTransactions: () => {
    return fetch(url + "/allresolvedtransactions", {
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    }).then((response) => {
      if (response.status !== 500) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "Error", msgError: true } };
    });
  },

  // findAllDiscoFailedTransaction: (discoId) => {
  //   return fetch(url + "/discofailedtransactions", {
  //     method: "post",
  //     body: JSON.stringify(discoId),
  //     headers: {
  //       "Content-Type": "application/json",
  //       access_token: tok,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => data);
  // },

  findAllDiscoFailedTransaction: () => {
    return fetch(url + "/discofailedtransactions", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  findProduct: (productName) => {
    return fetch(url + "/findproduct", {
      method: "post",
      body: JSON.stringify(productName),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  createProduct: (product) => {
    return fetch(url + "/createproduct", {
      method: "post",
      body: JSON.stringify(product),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  updateProduct: (product) => {
    return fetch(url + "/updateproduct", {
      method: "put",
      body: JSON.stringify(product),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  getTransactions: () => {
    return fetch(url + "/alltransactions", {
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  getMonthlyTransactions: () => {
    return fetch(url + "/allmonthlyarray", {
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    }).then((response) => {
      if (response.status !== 500) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "Error", msgError: true } };
    });
  },

  getSuccessfulMonthlyTransactions: () => {
    return fetch(url + "/allsuccessfulmonthlyarray", {
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    }).then((response) => {
      if (response.status !== 500) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "Error", msgError: true } };
    });
  },

  getShopMonthlyTransactions: () => {
    return fetch(url + "/allshopmonthlyarray", {
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    }).then((response) => {
      if (response.status !== 500) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "Error", msgError: true } };
    });
  },

  getShopTransactions: () => {
    return fetch(url + "/allshoptransactions", {
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  deleteProduct: (product) => {
    return fetch(url + "/deleteproduct", {
      method: "delete",
      body: JSON.stringify(product),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  findProductTag: (tagDetail) => {
    return fetch(url + "/findtag", {
      method: "post",
      body: JSON.stringify(tagDetail),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  createProductTag: (tag) => {
    return fetch(url + "/createtag", {
      method: "post",
      body: JSON.stringify(tag),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  updateProductTag: (tag) => {
    return fetch(url + "/updatetag", {
      method: "put",
      body: JSON.stringify(tag),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  deleteProductTag: (tag) => {
    return fetch(url + "/deletetag", {
      method: "delete",
      body: JSON.stringify(tag),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  createDisco: (disco) => {
    return fetch(url + "/createdisco", {
      method: "post",
      body: JSON.stringify(disco),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  findProductFeature: (featureDetail) => {
    return fetch(url + "/findfeature", {
      method: "post",
      body: JSON.stringify(featureDetail),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  createProductFeature: (feature) => {
    return fetch(url + "/createfeature", {
      method: "post",
      body: JSON.stringify(feature),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  updateProductFeature: (feature) => {
    return fetch(url + "/updatefeature", {
      method: "put",
      body: JSON.stringify(feature),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  deleteProductFeature: (feature) => {
    return fetch(url + "/deletefeature", {
      method: "delete",
      body: JSON.stringify(feature),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  deleteNewsFeed: (title) => {
    return fetch(url + "/deletenews", {
      method: "delete",
      body: JSON.stringify(title),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
  createNewsFeed: () => {
    return fetch(url + "/createnews", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
};
