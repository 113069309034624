import Cookies from "universal-cookie";
const cookies = new Cookies();
let tok = cookies.get("access_token");
let url = "https://smswallet.com.ng:9091";
//let url = "http://localhost:7070";

export default {
  login: (user) => {
    return fetch(url + "/login", {
      method: "post",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  welcome: (user) => {
    return fetch(url + "/welcome", {
      method: "post",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  updateUser: (user) => {
    return fetch(url + "/updateuser", {
      method: "put",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  userUpdatePassword: (user) => {
    console.log(user);
    return fetch(url + "/userUpdatePassword", {
      method: "post",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  register: (user) => {
    return fetch(url + "/register", {
      method: "post",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
  logout: () => {
    return fetch(url + "/logout", {
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  isAuthenticated: async () => {
    return fetch(url + "/authenticated", {
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    }).then((res) => {
      if (res.status !== 401) return res.json().then((data) => data);
      else
        return {
          isAuthenticated: false,
          user: {
            username: "",
            role: "",
          },
        };
    });
  },

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("userID"));
  },

  sendEmail: (user) => {
    return fetch(url + "/forgotPassword", {
      method: "post",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
};
