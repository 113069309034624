export default {
  primary: "#FFA500",
  secondary: "blue",
  black: "#000",
  white: "white",
  pageHeader: "#696969",
  dashboard: "rgba(248, 244, 238, 80%)",
  userTitle: "RGB(128, 128, 128)",
  green: "green",
  icon: "RGB(169, 169, 169)",
};
