import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import { AuthContext } from "context/authContext";
import Logout from "components/Modal/LogoutModal";
import { CartContext } from "context/cartContext";

import colors from "config/colors.config";

function AuthNavbar(props) {
  const [navbarColor, setNavbarColor] = useState("navbar-warning");
  const [cart] = useContext(CartContext);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const { isAuthenticated, user } = useContext(AuthContext);

  useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-warning");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const unauthenticatedNavBar = () => {
    return (
      <>
        {/* <NavItem>
          <NavLink href="/login-page">
            <i className="now-ui-icons objects_key-25"></i>
            <p>Login</p>
          </NavLink>
        </NavItem> */}
        <NavItem>
          <Button
            className="nav-link btn-warning"
            href="/register-page"
            id="upgrade-to-pro"
            style={{ color: colors.primary, backgroundColor: colors.white }}
          >
            <i className="now-ui-icons users_single-02"></i>
            <p>Register Here</p>
          </Button>
        </NavItem>
        <NavItem>
          <NavLink
            href="https://twitter.com/comparelight"
            target="_blank"
            id="twitter-tooltip"
          >
            <i className="fab fa-twitter"></i>
            <p className="d-lg-none d-xl-none">Twitter</p>
          </NavLink>
          <UncontrolledTooltip target="#twitter-tooltip">
            Follow us on Twitter
          </UncontrolledTooltip>
        </NavItem>
        <NavItem>
          <NavLink
            href="https://www.facebook.com/compare.light"
            target="_blank"
            id="facebook-tooltip"
          >
            <i className="fab fa-facebook-square"></i>
            <p className="d-lg-none d-xl-none">Facebook</p>
          </NavLink>
          <UncontrolledTooltip target="#facebook-tooltip">
            Like us on Facebook
          </UncontrolledTooltip>
        </NavItem>
        <NavItem>
          <NavLink
            href="https://www.instagram.com/comparelight_nigeria/"
            target="_blank"
            id="instagram-tooltip"
          >
            <i className="fab fa-instagram"></i>
            <p className="d-lg-none d-xl-none">Instagram</p>
          </NavLink>
          <UncontrolledTooltip target="#instagram-tooltip">
            Follow us on Instagram
          </UncontrolledTooltip>
        </NavItem>
      </>
    );
  };
  const authenticatedNavBar = () => {
    return (
      <>
        <NavItem>
          {" "}
          {user.role.includes("admin") ? (
            <NavLink href="/admin-page">
              <i className="now-ui-icons users_single-02"></i>
              <p>Admin</p>
            </NavLink>
          ) : null}{" "}
        </NavItem>
        <NavItem>
          {" "}
          {user.role.includes("manager") ? (
            <NavLink href="/manager-page">
              <i className="now-ui-icons business_badge"></i>
              <p>Manager</p>
            </NavLink>
          ) : null}{" "}
        </NavItem>
        <NavItem>
          {" "}
          {user.role.includes("rep") ? (
            <NavLink href="/rep-page">
              <i className="now-ui-icons users_circle-08"></i>
              <p>Rep</p>
            </NavLink>
          ) : null}{" "}
        </NavItem>
        <NavItem>
          {" "}
          {user.role.includes("user") ? (
            <NavLink href="/transactions-page">
              <i className="now-ui-icons files_single-copy-04"></i>
              <p>Transactions</p>
            </NavLink>
          ) : null}{" "}
        </NavItem>
        <NavItem>
          {" "}
          {user.role.includes("user") ? (
            <NavLink href="/dashboard-page">
              <i className="now-ui-icons business_chart-bar-32"></i>
              <p>Dashboard</p>
            </NavLink>
          ) : null}{" "}
        </NavItem>
        <NavItem>
          <NavLink
            href="https://twitter.com/comparelight"
            target="_blank"
            id="twitter-tooltip"
          >
            <i className="fab fa-twitter"></i>
            <p className="d-lg-none d-xl-none">Twitter</p>
          </NavLink>
          <UncontrolledTooltip target="#twitter-tooltip">
            Follow us on Twitter
          </UncontrolledTooltip>
        </NavItem>
        <NavItem>
          <NavLink
            href="https://www.facebook.com/compare.light"
            target="_blank"
            id="facebook-tooltip"
          >
            <i className="fab fa-facebook-square"></i>
            <p className="d-lg-none d-xl-none">Facebook</p>
          </NavLink>
          <UncontrolledTooltip target="#facebook-tooltip">
            Like us on Facebook
          </UncontrolledTooltip>
        </NavItem>
        <NavItem>
          <NavLink
            href="https://www.instagram.com/comparelight_nigeria/"
            target="_blank"
            id="instagram-tooltip"
          >
            <i className="fab fa-instagram"></i>
            <p className="d-lg-none d-xl-none">Instagram</p>
          </NavLink>
          <UncontrolledTooltip target="#instagram-tooltip">
            Follow us on Instagram
          </UncontrolledTooltip>
        </NavItem>
        <NavItem>
          <Logout />
        </NavItem>
      </>
    );
  };

  return (
    <>
      {" "}
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={"fixed-top " + navbarColor}
        expand="lg"
        color="warning"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand href="/index-page" id="navbar-brand">
              Comparelight
            </NavbarBrand>

            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>

          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle caret color="default" href="/about-page" nav>
                  <i className="now-ui-icons business_briefcase-24"></i>
                  <p>ABOUT</p>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem to="/policy-page" tag={Link}>
                    <i className="now-ui-icons business_globe"></i>
                    Policy
                  </DropdownItem>
                  <DropdownItem to="/faq-page" tag={Link}>
                    <i className="now-ui-icons design_bullet-list-67 mr-1"></i>
                    FAQ
                  </DropdownItem>
                  <DropdownItem to="/terms-page" tag={Link}>
                    <i className="now-ui-icons files_single-copy-04"></i>
                    Terms
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              {!cart.length ? (
                <NavItem>
                  <NavLink href="/shop-page">
                    <i className="now-ui-icons  shopping_cart-simple"></i>
                    <p>Shop</p>
                  </NavLink>
                </NavItem>
              ) : (
                <NavItem>
                  <NavLink href="/cart-page">
                    <i className="now-ui-icons  shopping_cart-simple"></i>
                    <p>View Cart</p>
                  </NavLink>
                </NavItem>
              )}
              {!isAuthenticated
                ? unauthenticatedNavBar()
                : authenticatedNavBar()}{" "}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AuthNavbar;
