import React, { useState } from "react";

// reactstrap components
import {
  Button,
  FormGroup,
  Row,
  Form,
  Input,
  Col,
  Card,
  CardTitle,
  CardBody,
  CardHeader,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";

// core components

import colors from "config/colors.config";
import AdminService from "services/adminService";
import Message from "components/Message";

function UserQueries(props) {
  const [iconTabs2, setIconTabs2] = React.useState("5");

  const [userPhone, setUserPhone] = useState({ phone: "" });
  const [userPhone2, setUserPhone2] = useState({ phone: "" });
  const [user, setUser] = useState({
    username: "",
    note: "",
    id: "",
    email: "",
    phone: "",
    disco: "",
    meter_no: "",
    firstname: "",
    lastname: "",
  });
  const [user2, setUser2] = useState({
    username: "",
    email: "",
    phone: "",
    password: "",
    disco: "",
    meter_no: "",
    firstname: "",
    lastname: "",
    roles: ["user", "rep"],
  });
  const [user3, setUser3] = useState({
    username: "",
    note: "",
    id: "",
    email: "",
    phone: "",
    disco: "",
    meter_no: "",
    firstname: "",
    lastname: "",
  });
  const [alert5, setAlert5] = useState("");
  const [alert6, setAlert6] = useState("");
  const [alert7, setAlert7] = useState("");

  const [message5, setMessage5] = useState(null);
  const [message6, setMessage6] = useState(null);
  const [message7, setMessage7] = useState(null);

  const [response4, setResponse4] = useState("false");
  const [response5, setResponse5] = useState("false");

  //Search user
  const resetResponse4 = (e) => {
    setResponse4("false");
    setMessage5("");
  };
  const onChange4 = (e) => {
    setUserPhone({
      ...userPhone,
      [e.target.name]: e.target.value,
    });
  };
  const resetForm4 = () => {
    setUserPhone({
      phone: "",
    });
  };

  const getUser = (e) => {
    e.preventDefault();
    setAlert5("Searching");
    try {
      AdminService.findUser(userPhone).then((data) => {
        console.log(user);
        if (!data.message) {
          setAlert5("");
          setResponse4("true");
          resetForm4();
          setUser(data);
        } else {
          const { message } = data;
          setAlert5("");
          setResponse4("false");
          setMessage5(message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Create user
  const onChange5 = (e) => {
    setUser2({
      ...user2,
      [e.target.name]: e.target.value,
    });
  };

  const resetUser2 = () => {
    setUser2({
      username: "",
      email: "",
      phone: "",
      disco: "",
      meter_no: "",
      firstname: "",
      password: "",
      lastname: "",
      roles: [""],
    });
  };

  const createUser = (e) => {
    e.preventDefault();
    setAlert6("Creating User");
    try {
      AdminService.createUser(user2).then((data) => {
        if (data.message.msgBody === "Account successfully created") {
          const { message } = data;
          setAlert6("");
          setMessage6(message);
          resetUser2();
        } else {
          const { message } = data;
          setAlert6("");
          setMessage6(message);
        }
      });
    } catch (error) {}
  };

  //Update user
  const resetResponse5 = (e) => {
    setResponse5("false");
    setMessage7("");
  };
  const onChange6 = (e) => {
    setUserPhone2({
      ...userPhone2,
      [e.target.name]: e.target.value,
    });
  };
  const resetUserPhone = () => {
    setUserPhone2({
      phone: "",
    });
  };
  const getUser2 = (e) => {
    e.preventDefault();
    setAlert7("Searching");
    try {
      AdminService.findUser(userPhone2).then((data) => {
        if (!data.message) {
          setAlert7("");
          setResponse5("true");
          setMessage7(null);
          resetUserPhone();
          setUser3(data);
        } else {
          const { message } = data;
          setAlert7("");
          setResponse5("false");
          setMessage7(message);
        }
      });
    } catch (error) {}
  };
  const onChangeUser3 = (e) => {
    setUser3({
      ...user3,
      [e.target.name]: e.target.value,
    });
  };
  const resetUser3 = () => {
    setUser3({
      username: "",
      note: "",
      id: "",
      email: "",
      phone: "",
      disco: "",
      meter_no: "",
      firstname: "",
      lastname: "",
    });
  };
  const updateUser = (e) => {
    e.preventDefault();
    setAlert7("Updating user");
    try {
      AdminService.updateUser(user3).then((data) => {
        if (data.message.msgBody === "User was updated successfully.") {
          const { message } = data;
          setAlert7("");
          setMessage7(message);
          resetUser3();
        } else {
          const { message } = data;
          setAlert7("");
          setMessage7(message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Card
        className="card-chart"
        body
        inverse
        style={{
          backgroundColor: colors.dashboard,
          color: colors.black,
        }}
      >
        <CardHeader>
          <Nav className="justify-content-center" role="tablist" tabs>
            <NavItem>
              <NavLink
                className={iconTabs2 === "5" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs2("5");
                }}
              >
                <i className="now-ui-icons education_glasses"></i>
                Search
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs2 === "6" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs2("6");
                }}
              >
                <i className="now-ui-icons users_single-02"></i>
                Create
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs2 === "7" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs2("7");
                }}
              >
                <i className="now-ui-icons text_caps-small"></i>
                Update
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent
            className="text-center"
            activeTab={"iconTabs" + iconTabs2}
          >
            <TabPane tabId="iconTabs5" style={{ color: colors.userTitle }}>
              {response4 === "false" ? (
                <div>
                  <div
                    className="col-md-6 offset-md-3"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                    }}
                  >
                    <Form onSubmit={getUser} className="form">
                      <Input
                        placeholder="Phone number"
                        type="text"
                        name="phone"
                        value={userPhone.phone}
                        required
                        onChange={onChange4}
                        id="phone"
                      ></Input>
                      <Button
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Find Phone
                      </Button>
                    </Form>

                    <p>{alert5}</p>
                    {message5 ? <Message message={message5} /> : null}
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "50px",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "50px",
                    padding: "20px",
                    backgroundColor: colors.primary,
                  }}
                >
                  <div>
                    <Button
                      className="btn-round"
                      onClick={resetResponse4}
                      style={{
                        color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      size="sm"
                    >
                      Reset
                    </Button>
                  </div>
                  <Row>
                    <Col className>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Id
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {user.id}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Username
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {user.username}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Email
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {user.email}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Phone No
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {user.phone}
                      </h5>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Disco
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {user.disco}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Meter No
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {user.meter_no}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Firstname
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {user.firstname}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Lastname
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {user.lastname}
                      </h5>
                    </Col>
                  </Row>

                  <br></br>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Note
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {user.note}
                      </h5>
                    </Col>
                  </Row>
                </div>
              )}
            </TabPane>
            <TabPane tabId="iconTabs6">
              <div style={{ color: colors.black }}>
                <Form onSubmit={createUser} className="form">
                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <label htmlFor="phoneNo">Phone No</label>
                      <Input
                        id="phone"
                        placeholder="Enter number"
                        type="text"
                        name="phone"
                        value={user2.phone}
                        required
                        onChange={onChange5}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label htmlFor="meterNo">Meter No</label>
                      <Input
                        id="meter_no"
                        placeholder="Enter number"
                        type="text"
                        name="meter_no"
                        value={user2.meter_no}
                        required
                        onChange={onChange5}
                      ></Input>
                    </FormGroup>
                  </div>
                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <label htmlFor="username">Username</label>
                      <Input
                        id="username"
                        placeholder="UserName"
                        type="text"
                        name="username"
                        value={user2.username}
                        required
                        onChange={onChange5}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label htmlFor="password">Password</label>
                      <Input
                        id="password"
                        placeholder="Enter password"
                        type="text"
                        name="password"
                        value={user2.password}
                        required
                        onChange={onChange5}
                      ></Input>
                    </FormGroup>
                  </div>
                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <label htmlFor="firstname">Firstname</label>
                      <Input
                        id="firstname"
                        placeholder="Enter name"
                        type="text"
                        name="firstname"
                        value={user2.firstname}
                        required
                        onChange={onChange5}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label htmlFor="lastname">Lastname</label>
                      <Input
                        id="lastname"
                        placeholder="Enter name"
                        type="text"
                        name="lastname"
                        value={user2.lastname}
                        required
                        onChange={onChange5}
                      />
                    </FormGroup>
                  </div>
                  <FormGroup>
                    <label htmlFor="email">Email</label>
                    <Input
                      id="email"
                      placeholder="Enter email"
                      type="email"
                      name="email"
                      value={user2.email}
                      required
                      onChange={onChange5}
                    ></Input>
                  </FormGroup>

                  <div className="form-row">
                    <FormGroup className="col-md-4">
                      <label htmlFor="disco">Disco</label>
                      <Input
                        id="disco"
                        type="select"
                        name="disco"
                        required
                        value={user2.disco}
                        onChange={onChange5}
                      >
                        <option value="">Choose...</option>
                        <option value="Ikeja(IE)">Ikeja(IE)</option>
                        <option value="Eko(EKEDC)">Eko(EKEDC)</option>
                        <option value="Ibadan-(IBEDC)">Ibadan-(IBEDC)</option>
                        <option value="Abuja(AEDC)">Abuja(AEDC)</option>
                        <option value="Yola(YEDC)">Yola(YEDC)</option>
                        <option value="Aba(ABA)">Aba(ABA)</option>
                        <option value="Benin(BEDC)">Benin(BEDC)</option>
                        <option value="Enugu(EEDC)">Enugu(EEDC)</option>
                        <option value="Jos(JED)">Jos(JED)</option>
                        <option value="Ph(PHED)">Ph(PHED)</option>
                        <option value="Kano(KEDCO)">Kano(KEDCO)</option>
                        <option value="Kaduna(KAEDCO)">Kaduna(KAEDCO)</option>
                      </Input>
                    </FormGroup>
                  </div>
                  <Button
                    className="btn-round"
                    type="submit"
                    style={{
                      color: colors.secondary,
                      backgroundColor: colors.primary,
                    }}
                    size="lg"
                  >
                    Create
                  </Button>
                </Form>
                <hr
                  className="warning"
                  style={{
                    backgroundColor: colors.primary,
                    marginTop: "25px",
                  }}
                />
                <p>{alert6}</p>
                <div>{message6 ? <Message message={message6} /> : null}</div>
              </div>
            </TabPane>
            <TabPane tabId="iconTabs7">
              {response5 === "false" ? (
                <div>
                  <div
                    className="col-md-6 offset-md-3"
                    style={{
                      color: colors.black,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                    }}
                  >
                    <Form onSubmit={getUser2} className="form">
                      <Input
                        placeholder="Enter number"
                        type="text"
                        name="phone"
                        value={userPhone2.phone}
                        required
                        onChange={onChange6}
                        id="phone"
                      ></Input>
                      <Button
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Find User
                      </Button>
                    </Form>
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "50px",
                    }}
                  />
                  <p>{alert7}</p>
                  <div>{message7 ? <Message message={message7} /> : null}</div>
                </div>
              ) : (
                <div style={{ color: colors.black }}>
                  <Button
                    className="btn-round"
                    onClick={resetResponse5}
                    style={{
                      color: colors.secondary,
                      backgroundColor: colors.primary,
                    }}
                    size="sm"
                  >
                    Reset
                  </Button>
                  <Form onSubmit={updateUser} className="form">
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="phone">Phone No</label>
                        <Input
                          id="phone"
                          defaultValue={user3.phone}
                          placeholder="Enter number"
                          type="text"
                          name="phone"
                          value={user3.phone}
                          onChange={onChangeUser3}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="meterNo">Meter No</label>
                        <Input
                          id="meter_no"
                          placeholder="Enter number"
                          defaultValue={user3.meter_no}
                          type="text"
                          name="meter_no"
                          value={user3.meter_no}
                          onChange={onChangeUser3}
                        ></Input>
                      </FormGroup>
                    </div>
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="username">UserName</label>
                        <Input
                          id="username"
                          placeholder="Username"
                          type="text"
                          name="username"
                          defaultValue={user3.username}
                          value={user3.username}
                          required
                          onChange={onChangeUser3}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="email">Email</label>
                        <Input
                          id="email"
                          placeholder="Enter email"
                          type="email"
                          name="email"
                          defaultValue={user3.email}
                          value={user3.email}
                          onChange={onChangeUser3}
                        ></Input>
                      </FormGroup>
                    </div>
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="firstname">Firstname</label>
                        <Input
                          id="firstname"
                          placeholder="Enter firstname"
                          type="text"
                          name="firstname"
                          defaultValue={user3.firstname}
                          value={user3.firstname}
                          onChange={onChangeUser3}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="lastname">Lastname</label>
                        <Input
                          id="lastname"
                          placeholder="Enter lastname"
                          type="text"
                          name="lastname"
                          defaultValue={user3.lastname}
                          value={user3.lastname}
                          onChange={onChangeUser3}
                        />
                      </FormGroup>
                    </div>
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="id">id</label>
                        <Input
                          id="id"
                          type="text"
                          name="id"
                          defaultValue={user3.lastname}
                          value={user3.lastname}
                          readOnly="true"
                          onChange={onChangeUser3}
                        />
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="disco">Disco</label>
                        <Input
                          id="disco"
                          type="select"
                          name="disco"
                          defaultValue={user3.disco}
                          value={user3.disco}
                          onChange={onChange5}
                        >
                          <option selected="" value="">
                            Choose...
                          </option>
                          <option value="Ikeja(IE)">Ikeja(IE)</option>
                          <option value="Eko(EKEDC)">Eko(EKEDC)</option>
                          <option value="Ibadan-(IBEDC)">Ibadan-(IBEDC)</option>
                          <option value="Abuja(AEDC)">Abuja(AEDC)</option>
                          <option value="Yola(YEDC)">Yola(YEDC)</option>
                          <option value="Aba(ABA)">Aba(ABA)</option>
                          <option value="Benin(BEDC)">Benin(BEDC)</option>
                          <option value="Enugu(EEDC)">Enugu(EEDC)</option>
                          <option value="Jos(JED)">Jos(JED)</option>
                          <option value="Ph(PHED)">Ph(PHED)</option>
                          <option value="Kano(KEDCO)">Kano(KEDCO)</option>
                          <option value="Kaduna(KAEDCO)">Kaduna(KAEDCO)</option>
                        </Input>
                      </FormGroup>
                    </div>

                    <FormGroup>
                      <label htmlFor="note">Notes</label>
                      <Input
                        id="note"
                        placeholder="Enter Note"
                        type="textarea"
                        name="note"
                        defaultValue={user3.note}
                        value={user3.note}
                        onChange={onChangeUser3}
                      ></Input>
                    </FormGroup>

                    <Button
                      className="btn-round"
                      type="submit"
                      style={{
                        color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      size="lg"
                    >
                      Update
                    </Button>
                  </Form>
                  <p>{alert7}</p>
                  <div>{message7 ? <Message message={message7} /> : null}</div>
                </div>
              )}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
}

export default UserQueries;
