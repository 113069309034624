import React, { useState, useEffect, useRef, useContext } from "react";
import TransactionService from "services/transactionService";
import ReactTextTransition, { presets } from "react-text-transition";
import DataTable, { createTheme } from "react-data-table-component";

//import { useHistory } from "react-router-dom";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";

// reactstrap components
import { Row, Col, Card, CardBody, Button } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar";
import TransparentFooter from "components/Footers/TransparentFooter";

import { AuthContext } from "context/authContext";
//import Paginations from "components/Pagination";
import colors from "config/colors.config";
import * as legoData from "../pops.json";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: legoData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

createTheme("solarized", {
  context: {
    background: "#0399DA",
    text: "#FFFFFF",
  },
  divider: {
    default: "#ddd",
  },
});

const customStyles = {
  headCells: {
    style: {
      color: "#0399DA",
      fontWeight: "600",
    },
  },
};

const columns = [
  {
    name: "Date",
    selector: "date",
    sortable: true,
  },
  {
    name: "Amount",
    selector: "amount",
    sortable: true,
  },
  {
    name: "Meter No",
    selector: "meter_no",
    sortable: true,
  },
  {
    name: "Token",
    selector: "token",
  },
  {
    name: "Invoice No",
    selector: "invoice_no",
    sortable: true,
  },
  {
    name: "Receipt",
    selector: "treceipt",
  },
];

function Transactions(props) {
  //let history = useHistory();
  const [transactions, setTransactions] = useState([]);
  const { user } = useContext(AuthContext);
  //const [currentPage, setCurrentPage] = useState(1);
  // const [transactionsPerPage] = useState(5);
  const [isLoaded, setIsLoaded] = useState(false);
  const [alert, setAlert] = useState("");
  //const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState({});
  let timerID = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timerID);
    };
  }, []);

  useEffect(() => {
    setAlert("Loading Transactions...");
    setIsLoaded(false);
    TransactionService.getUserTransactions({ userId: user.id }).then((data) => {
      let pdata = [];
      var treceipt;
      var obj;
      const reversed = data.reverse();
      reversed.forEach((transaction) => {
        const getReceipt = (e) => {
          e.preventDefault();
          // history.push("/receipt" + transaction.invoice_no);
          const win = window.open(
            "/invoice-page/" + transaction.invoice_no,
            "_blank"
          );
          win.focus();
        };

        treceipt = (
          <Button
            onClick={getReceipt}
            target="blank"
            style={{
              backgroundColor: colors.primary,
              //marginLeft: "-20px",
              color: colors.secondary,
            }}
            className="btn-round"
            type="button"
            size="sm"
          >
            View
          </Button>
        );

        obj = {
          date: transaction.date,
          amount: transaction.amount,
          meter_no: transaction.meter_no,
          token: transaction.token,
          treceipt: treceipt,
          invoice_no: transaction.invoice_no,
        };
        pdata.push(obj);
      });
      let tableD = {
        columns,
        data: pdata,
      };
      setTableData(tableD);
      setTransactions(pdata);
      setAlert("");
      setIsLoaded(true);
    });
  }, [user]);

  useEffect(() => {
    document.body.classList.add("transactions-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("transactions-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <div>
      <AuthNavbar />
      <h2
        style={{
          color: colors.pageHeader,
          marginBottom: "0px",
          fontWeight: "600",
          marginTop: "62px",
          textAlign: "center",
          paddingTop: "20px",
        }}
      >
        Transactions
      </h2>
      <Row
        style={{
          marginTop: "20px",
          marginLeft: "20px",
          marginRight: "20px",
          backgroundImage:
            "url(https://res.cloudinary.com/dfszquucy/image/upload/v1573809865/Group_132_h7ftcl.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center right",
          backgroundPositionX: "500px",
          backgroundPositionY: "150px",
          backgroundSize: "contain",
          minHeight: "900px",
        }}
      >
        <Col>
          <Card
            body
            inverse
            style={{
              backgroundColor: "rgba(248, 244, 238, 80%)",
              minHeight: "850px",
            }}
          >
            {isLoaded === false ? (
              <Row>
                <div
                  className="container"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FadeIn>
                    <div
                      className="container"
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Lottie
                        options={defaultOptions}
                        height={300}
                        width={300}
                      />
                      <ReactTextTransition
                        text={alert}
                        spring={presets.gentle}
                        delay={300}
                        className="h3"
                        inline
                      />
                    </div>
                  </FadeIn>
                </div>
              </Row>
            ) : (
              <div>
                <CardBody>
                  {
                    <DataTableExtensions
                      {...tableData}
                      filter={true}
                      export={false}
                      print={false}
                      filterHidden={false}
                    >
                      <DataTable
                        title="Recent transactions"
                        responsive={true}
                        pagination={true}
                        columns={columns}
                        data={transactions}
                        theme="solarized"
                        customStyles={customStyles}
                      />
                    </DataTableExtensions>
                  }
                </CardBody>
              </div>
            )}
          </Card>
        </Col>
      </Row>
      <TransparentFooter />
    </div>
  );
}

export default Transactions;
