import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import "./invoice.css";
import CompareLogo from "../assets/img/comparelight_logo.png";
import Printer, { print } from "react-pdf-print";
import { Button } from "reactstrap";
import { AuthContext } from "context/authContext";
import TransactionService from "services/transactionService";
import AuthNavbar from "components/Navbars/AuthNavbar";

function Invoice3() {
  let id = useParams({}).id;
  const { user } = useContext(AuthContext);
  const [transactionDetails, setTransactionDetails] = useState({
    receiptNo: "",
    transactionDate: "",
    meterName: "",

    meterAddress: "",
    meterType: "",
    meterNo: "",
    accountNo: "",
    units: "",
    rate: "",
    amount: "",
    serviceCharge: "",
    token: "",
    token2: "",
    token3: "",
    response: "",
    gladeReference: "",
  });

  const ids = ["1"];
  function getType(params) {
    if (params === 1) {
      return "Prepaid";
    }
    if (params === 4) {
      return "Prepaid";
    }

    if (params === 5) {
      return "Postpaid";
    }

    if (params === 2) {
      return "Postpaid";
    }
  }

  function breakResponse(params) {
    if (params) {
      let resp = JSON.parse(params);
      delete resp.content;
      delete resp.code;
      delete resp.amount;
      delete resp.CustomerName;
      delete resp.CustomerAddress;
      delete resp.Amount;
      let responseKeys = Object.keys(resp);
      let fmtResp = "";
      responseKeys.forEach((key) => {
        fmtResp += `${key}:${resp[key]}|`;
      });

      return fmtResp;
    } else {
      return "Failed";
    }
  }
  function getVat(params) {
    return params * (7.5 / 100);
  }

  useEffect(() => {
    //setAlert("Getting receipt...");
    //setIsLoaded(false);
    TransactionService.getInvoice({ invoice_no: id }).then((data) => {
      setTransactionDetails({
        ...transactionDetails,
        receiptNo: data.invoice_no,
        transactionDate: data.date,
        meterName: data.account_name.substring(0, 51),
        meterAddress: data.meter_address.substring(0, 51),
        meterType: getType(data.meterTypeId),
        meterNo: data.meter_no,
        accountNo: data.account_no,
        units: data.value,
        rate: data.rate,
        amount: data.amount,
        serviceCharge: data.charge,
        token: data.token,
        token2: data.keyChangeToken1,
        token3: data.keyChangeToken2,
        response: breakResponse(data.response),
        gladeReference: data.glade_ref,
      });

      // setTimeout(() => {

      // }, 2000);
    });
  }, [id, transactionDetails]);

  useEffect(() => {
    document.body.classList.add("transactions-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("transactions-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  function getUnitCost(param1, param2) {
    return transactionDetails.rate * transactionDetails.units;
  }

  return (
    <>
      <AuthNavbar />
      <div id="example" style={{ marginTop: "35px" }}>
        <div className="box wide hidden-on-narrow">
          <div className="box-col" />

          <div className="box-col" />
        </div>

        <div className="page-container hidden-on-narrow">
          <Printer>
            <div
              id={ids[0]}
              className="pdf-page size-a4"
              style={{
                // width: " 6.2in",

                // height: " 8.7in",
                width: "210mm",
                height: "297mm",
                position: "relative",
                backgroundImage:
                  "url(" +
                  require("assets/img/IB-Receipt_Background.png") +
                  ")",
              }}
            >
              <div className="inner-page">
                <div className="pdf-header">
                  <span className="company-logo">
                    <img
                      src={CompareLogo}
                      width="100px"
                      alt="Kendoka Company Logo"
                    />{" "}
                    Comparelight
                  </span>

                  <span className="invoice-number">
                    Ibadan Electricity Payment
                  </span>
                </div>

                <div className="pdf-footer">
                  <p>
                    Web: comparelight.com | Mail: info@comparelight.com | Phone:
                    +2348174735017 | whatsApp: +2348174735017
                  </p>
                </div>

                <div className="addresses">
                  <div className="for">
                    <h3>Thank you for your payment!</h3>
                    <p>
                      {" "}
                      Account Holder: {user.firstname + ", " + user.lastname}
                    </p>
                    <p>Phone Number: {user.phone}</p>
                    <p>Dealer: Compare Price Market Limited</p>
                    <p>Receipt No: {transactionDetails.receiptNo}</p>
                    <p>Glade No: {transactionDetails.gladeReference}</p>
                    <p>
                      Transaction Date: {transactionDetails.transactionDate}
                    </p>
                    <p
                      style={{
                        whiteSpace: "pre-line",
                      }}
                    >
                      Name on Meter:{" "}
                      <span style={{ fontSize: "10px" }}>
                        {" "}
                        {transactionDetails.meterName}
                      </span>
                    </p>
                    <p
                      style={{
                        whiteSpace: "pre-line",
                      }}
                    >
                      {" "}
                      Address:{" "}
                      <span style={{ fontSize: "10px" }}>
                        {" "}
                        {transactionDetails.meterAddress}
                      </span>
                    </p>
                    <p> Meter Type: {transactionDetails.meterType}</p>

                    {/* <p>
                      Account Holder: {user.firstname + ", " + user.lastname}
                      <br /> <br />
                      Phone Number: {user.phone}
                      <br /> <br />
                      Dealer: Compare Price Market Limited
                      <br /> <br />
                      Receipt No: {transactionDetails.receiptNo}
                      <br /> <br />
                      Glade No: {transactionDetails.gladeReference}
                      <br /> <br />
                      Transaction Date: {transactionDetails.transactionDate}
                      <br /> <br />
                      Name on Meter: {transactionDetails.meterName}
                      <br /> <br />
                      Address: {transactionDetails.meterAddress}
                      <br /> <br />
                      Provider: Ibadan Electricity
                      <br /> <br />
                      Meter Type: {transactionDetails.meterType}
                    </p> */}
                  </div>

                  <div className="from">
                    <h3>.</h3>

                    <p>
                      Meter No: {transactionDetails.meterNo}
                      <br /> <br />
                      Amount: {transactionDetails.amount}
                      <br /> <br />
                      Charge: {transactionDetails.serviceCharge}
                      <br /> <br />
                      Units:{" "}
                      {transactionDetails.units.includes(
                        "Your transaction was successful"
                      )
                        ? ""
                        : transactionDetails.units}
                      Kwh
                      <br /> <br />
                      Rate per KWh: ₦{transactionDetails.rate}k
                    </p>

                    <p>
                      VAT: ₦
                      {parseFloat(getVat(transactionDetails.amount), 2).toFixed(
                        2
                      )}
                      k
                      <br /> <br />
                      Cost of Units: ₦{getUnitCost().toFixed(2)}
                      k
                      <br /> <br />
                      Token Credit: {transactionDetails.token}
                      <br /> <br />
                      KeyChangeToken 1: {transactionDetails.token2}
                      <br /> <br />
                      KeyChangeToken 2: {transactionDetails.token3}
                    </p>
                  </div>
                </div>

                <div className="pdf-chart" style={{ marginTop: "50px" }}>
                  <p>
                    {/* {transactionDetails.response
                    ? transactionDetails.replace("|TranId:", "  |TranId:")
                    : "failed!"} */}
                    Response: {transactionDetails.response}
                  </p>
                </div>

                <div className="pdf-body">
                  <div id="grid" />

                  <p className="signature"></p>
                </div>
              </div>
            </div>
          </Printer>
          <div
            style={{
              display: "grid",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Button color="warning" type="button" onClick={() => print(ids)}>
              Download
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Invoice3;
