import React, { useState, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";

// core components

import colors from "config/colors.config";
import AdminService from "services/adminService";
import Message from "components/Message";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

// reactstrap components
import {
  Button,
  FormGroup,
  Row,
  Form,
  Input,
  Col,
  Card,
  CardTitle,
  CardBody,
  CardHeader,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";

createTheme("solarized", {
  context: {
    background: "#0399DA",
    text: "#FFFFFF",
  },
  divider: {
    default: "#ddd",
  },
});

const customStyles = {
  headCells: {
    style: {
      color: "#0399DA",
      fontWeight: "600",
    },
  },
};

const columns = [
  {
    name: "Date",
    selector: "date",
    sortable: true,
  },
  {
    name: "Amount",
    selector: "amount",
    sortable: true,
  },
  {
    name: "Meter No",
    selector: "meter_no",
    sortable: true,
  },
  {
    name: "Token",
    selector: "token",
  },
  {
    name: "Invoice No",
    selector: "invoice_no",
    sortable: true,
  },

  {
    name: "Status",
    selector: "status",
  },
];

function FailedTransactionsQueries(props) {
  const [iconTabs, setIconTabs] = React.useState("1");
  const [invoice, setInvoice] = useState({ invoice_no: "" });
  const [invoice2, setInvoice2] = useState({ invoice_no: "" });
  const [failedTransactions, setFailedTransactions] = useState([]);
  const [liveFailedTransactions, setLiveFailedTransactions] = useState([]);

  const [transaction, setTransaction] = useState({
    phone_no: "",
    meter_no: "",
    meter_address: "",
    invoice_no: "",
    amount: "",
    response: "",
    token: "",
    date: "",
    status: "",
    userId: "",
    discoId: "",
    description: "",
    owner_id: "",
    meterTypeId: "",
  });
  const [transaction2, setTransaction2] = useState({
    phone_no: "",
    meter_no: "",
    status: "",
    account_no: "",
    account_name: "",
    meter_address: "",
    invoice_no: "",
    amount: "",
    response: "",
    token: "",
    date: "",
    charge: "",
    userId: "",
    discoId: "",
    meterTypeId: "",
    transaction_id: "",
    glade_ref: "",
    rate: "",
    value: "",
    balance: "",
    keyChangeToken1: "",
    keyChangeToken2: "",
  });
  const [tableData, setTableData] = useState({});
  const [tableData2, setTableData2] = useState({});
  const [alert, setAlert] = useState("");
  const [message, setMessage] = useState(null);
  const [response, setResponse] = useState("false");

  const [alert3, setAlert3] = useState("");
  const [message3, setMessage3] = useState(null);
  const [response3, setResponse3] = useState("false");

  //Get All failed transactions

  useEffect(() => {
    setAlert("Loading Transactions...");

    AdminService.getFailedTransactions().then((data) => {
      let pdata = [];
      var obj;
      //const reversed = data.reverse();
      data.forEach((transaction) => {
        obj = {
          date: transaction.date,
          amount: transaction.amount,
          meter_no: transaction.meter_no,
          token: transaction.token,
          invoice_no: transaction.invoice_no,
          status: transaction.status,
        };
        pdata.push(obj);
      });
      let tableD = {
        columns,
        data: pdata,
      };
      setTableData(tableD);
      setFailedTransactions(data);
      setAlert("");
    });
  }, []);

  //Get All live failed transactions

  useEffect(() => {
    setAlert("Loading Transactions...");

    AdminService.getWaitingTransactions().then((data) => {
      let pdata = [];
      var obj;
      const reversed = data.reverse();
      reversed.forEach((transaction) => {
        obj = {
          date: transaction.date,
          amount: transaction.amount,
          meter_no: transaction.meter_no,
          token: transaction.token,
          invoice_no: transaction.invoice_no,
          status: transaction.status,
        };
        pdata.push(obj);
      });
      let tableD = {
        columns,
        data: pdata,
      };
      setTableData2(tableD);
      setLiveFailedTransactions(data);
      setAlert("");
    });
  }, []);

  //Search Transaction
  const resetResponse = (e) => {
    setResponse("false");
    setMessage("");
  };
  const onChange = (e) => {
    setInvoice({
      ...invoice,
      [e.target.name]: e.target.value,
    });
  };
  const resetForm = () => {
    setInvoice({
      invoice_no: "",
    });
  };

  const getTransaction = (e) => {
    e.preventDefault();
    setAlert("Searching");
    AdminService.findFailedInvoice(invoice).then((data) => {
      if (!data.message) {
        setAlert("");
        setResponse("true");
        setMessage("");
        resetForm();
        setTransaction(data);
      } else {
        const { message } = data;
        setAlert("");
        setResponse("false");
        setMessage(message);
      }
    });
  };

  function breakResponse(params) {
    if (params) {
      if (!params.includes('"code"')) {
        return params.replace("|TranId:", "  |TranId:");
      }
      let resp = JSON.parse(params);
      delete resp.content;
      delete resp.code;
      delete resp.amount;
      delete resp.CustomerName;
      delete resp.CustomerAddress;
      delete resp.Amount;
      let responseKeys = Object.keys(resp);
      let fmtResp = "";
      responseKeys.forEach((key) => {
        fmtResp += `${key}:${resp[key]}|`;
      });

      return fmtResp;
    } else {
      return "Failed";
    }
  }

  //Update Transaction
  const resetResponse2 = (e) => {
    setResponse3("false");
    setMessage3("");
  };
  const onChange2 = (e) => {
    setInvoice2({
      ...invoice2,
      [e.target.name]: e.target.value,
    });
  };
  const resetForm2 = () => {
    setInvoice2({
      invoice_no: "",
    });
  };
  const getTransaction2 = (e) => {
    e.preventDefault();
    setAlert3("Searching");
    AdminService.findFailedInvoice(invoice2).then((data) => {
      if (!data.message) {
        setAlert3("");
        setResponse3("true");
        setMessage3("");
        resetForm2();
        setTransaction2(data);
      } else {
        const { message } = data;
        setAlert3("");
        setResponse3("false");
        setMessage3(message);
      }
    });
  };

  const onChangeTransaction2 = (e) => {
    setTransaction2({
      ...transaction2,
      [e.target.name]: e.target.value,
    });
  };

  const resetTransaction2 = () => {
    setTransaction2({
      phone_no: "",
      meter_no: "",
      status: "",
      account_no: "",
      account_name: "",
      meter_address: "",
      invoice_no: "",
      amount: "",
      response: "",
      token: "",
      date: "",
      charge: "",
      userId: "",
      discoId: "",
      meterTypeId: "",
      transaction_id: "",
      glade_ref: "",
      rate: "",
      value: "",
      balance: "",
      keyChangeToken1: "",
      keyChangeToken2: "",
    });
  };

  const updateTransaction = (e) => {
    e.preventDefault();
    setAlert3("Updating transaction");
    AdminService.updateFailedTransaction(transaction2).then((data) => {
      console.log(data);
      if (data.message.msgBody === "Transaction was updated successfully.") {
        const { message } = data;
        setAlert3("");
        setMessage3(message);
        resetTransaction2();
      } else {
        const { message } = data;
        setAlert3("");
        setMessage3(message);
      }
    });
  };

  return (
    <div>
      <Card
        className="card-chart"
        body
        inverse
        style={{
          backgroundColor: colors.dashboard,
          color: colors.black,
        }}
      >
        <CardHeader>
          <Nav className="justify-content-center" role="tablist" tabs>
            <NavItem>
              <NavLink
                className={iconTabs === "1" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("1");
                }}
              >
                <i className="now-ui-icons education_glasses"></i>
                Search
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs === "2" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("2");
                }}
              >
                <i className="now-ui-icons text_caps-small"></i>
                Update
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs === "3" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("3");
                }}
              >
                <i className="now-ui-icons ui-2_settings-90"></i>
                Failed
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs === "4" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("4");
                }}
              >
                <i className="now-ui-icons ui-2_time-alarm"></i>
                Waiting
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent className="text-center" activeTab={"iconTabs" + iconTabs}>
            <TabPane tabId="iconTabs1" style={{ color: colors.userTitle }}>
              {response === "false" ? (
                <div>
                  <div
                    className="col-md-6 offset-md-3"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                    }}
                  >
                    <Form onSubmit={getTransaction} className="form">
                      <Input
                        placeholder="Invoice number"
                        type="text"
                        name="invoice_no"
                        value={invoice.invoice_no}
                        required
                        onChange={onChange}
                        id="invoice_no"
                      ></Input>
                      <Button
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Find Invoice
                      </Button>
                    </Form>
                  </div>
                  {alert}
                  {message ? <Message message={message} /> : null}
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "50px",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "50px",
                    padding: "20px",
                    backgroundColor: colors.primary,
                  }}
                >
                  <div>
                    <Button
                      className="btn-round"
                      onClick={resetResponse}
                      style={{
                        color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      size="sm"
                    >
                      Reset
                    </Button>
                  </div>
                  <Row>
                    <Col className>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        PhoneNo
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.phone_no}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Amount
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.amount}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        MeterType
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.meterTypeId}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Meter No
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.meter_no}
                      </h5>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        invoiceNo
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.invoice_no}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Owner Id
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.owner_id}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Date
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.date.slice(0, 10)}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        User ID
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.userId}
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        meterAddress
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.meter_address}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Status
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.status}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        DiscoId
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.discoId}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Description
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.descriptions}
                      </h5>
                    </Col>
                  </Row>

                  <br></br>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Token
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.token}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Response
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {breakResponse(transaction.response)}
                      </h5>
                    </Col>
                  </Row>
                </div>
              )}
            </TabPane>
            <TabPane tabId="iconTabs2">
              {response3 === "false" ? (
                <div>
                  <div
                    className="col-md-6 offset-md-3"
                    style={{
                      color: colors.black,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                    }}
                  >
                    <Form onSubmit={getTransaction2} className="form">
                      <Input
                        placeholder="Invoice number"
                        type="text"
                        name="invoice_no"
                        value={invoice2.invoice_no}
                        required
                        onChange={onChange2}
                        id="invoice_no"
                      ></Input>
                      <Button
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Find Invoice
                      </Button>
                    </Form>
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "50px",
                    }}
                  />
                  {alert3}
                  <div>{message3 ? <Message message={message3} /> : null}</div>
                </div>
              ) : (
                <div style={{ color: colors.black }}>
                  <Button
                    className="btn-round"
                    onClick={resetResponse2}
                    style={{
                      color: colors.secondary,
                      backgroundColor: colors.primary,
                    }}
                    size="sm"
                  >
                    Reset
                  </Button>

                  <Form onSubmit={updateTransaction} className="form">
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="phoneNo">Phone No</label>
                        <Input
                          id="phone_no"
                          placeholder="Enter number"
                          type="text"
                          name="phone_no"
                          value={transaction2.phone_no}
                          onChange={onChangeTransaction2}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="meterNo">Meter No</label>
                        <Input
                          id="meter_no"
                          placeholder="Enter number"
                          type="text"
                          name="meter_no"
                          value={transaction2.meter_no}
                          onChange={onChangeTransaction2}
                        ></Input>
                      </FormGroup>
                    </div>
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="invoiceNo">Invoice No</label>
                        <Input
                          id="invoice_no"
                          placeholder="Transaction number"
                          type="text"
                          name="invoice_no"
                          value={transaction2.invoice_no}
                          required
                          onChange={onChangeTransaction2}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="amount">Amount</label>
                        <Input
                          id="amount"
                          placeholder="Enter amount"
                          type="text"
                          name="amount"
                          value={transaction2.amount}
                          onChange={onChangeTransaction2}
                        ></Input>
                      </FormGroup>
                    </div>

                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="date">Transaction ID</label>
                        <Input
                          id="transaction_id"
                          placeholder="Enter transaction ID"
                          type="text"
                          name="transaction_id"
                          value={transaction2.transaction_id}
                          onChange={onChangeTransaction2}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="date">Monnify Ref</label>
                        <Input
                          id=" glade_ref"
                          placeholder="Enter monnify ref"
                          type="text"
                          name="glade_ref"
                          value={transaction2.glade_ref}
                          onChange={onChangeTransaction2}
                        />
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="response">Charge</label>
                        <Input
                          id="charge"
                          placeholder="Enter charge"
                          type="text"
                          name="charge"
                          value={transaction2.charge}
                          onChange={onChangeTransaction2}
                        ></Input>
                      </FormGroup>
                    </div>

                    <div className="form-row">
                      <FormGroup className="col-md-4">
                        <label htmlFor="date">Rate</label>
                        <Input
                          id="rate"
                          placeholder="Enter rate"
                          type="text"
                          name="rate"
                          value={transaction2.rate}
                          onChange={onChangeTransaction2}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-4">
                        <label htmlFor="date">Value</label>
                        <Input
                          id="value"
                          placeholder="Enter value"
                          type="text"
                          name="value"
                          value={transaction2.value}
                          onChange={onChangeTransaction2}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-4">
                        <label htmlFor="date">Balance</label>
                        <Input
                          id="balance"
                          placeholder="Enter  balance"
                          type="text"
                          name="balance"
                          value={transaction2.balance}
                          onChange={onChangeTransaction2}
                        />
                      </FormGroup>
                    </div>
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="token">keyChangeToken1</label>
                        <Input
                          id="keyChangeToken1"
                          placeholder="Enter number"
                          type="text"
                          name="keyChangeToken1"
                          value={transaction2.keyChangeToken1}
                          onChange={onChangeTransaction2}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="date">keyChangeToken2</label>
                        <Input
                          id="keyChangeToken2"
                          placeholder="Enter number"
                          type="text"
                          name="keyChangeToken1"
                          value={transaction2.keyChangeToken2}
                          onChange={onChangeTransaction2}
                        />
                      </FormGroup>
                    </div>

                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="token">Token</label>
                        <Input
                          id="token"
                          placeholder="Enter number"
                          type="text"
                          name="token"
                          value={transaction2.token}
                          onChange={onChangeTransaction2}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="date">Date</label>
                        <Input
                          id="date"
                          placeholder="Enter date"
                          type="text"
                          name="date"
                          value={transaction2.date}
                          onChange={onChangeTransaction2}
                        />
                      </FormGroup>
                    </div>
                    <div className="form-row">
                      <FormGroup className="col-md-4">
                        <label htmlFor="status">Status</label>
                        <Input
                          id="status"
                          type="select"
                          name="status"
                          value={transaction2.status}
                          onChange={onChangeTransaction2}
                        >
                          <option value="">Choose...</option>
                          <option value="200">Resolved</option>
                          <option value="100">Failed</option>
                          <option value="900">Waiting</option>
                        </Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="date">Account No</label>
                        <Input
                          id="account_no"
                          placeholder="Enter account number"
                          type="text"
                          name="account_no"
                          value={transaction2.account_no}
                          onChange={onChangeTransaction2}
                        />
                      </FormGroup>
                    </div>
                    <div className="form-row">
                      <FormGroup className="col-md-2">
                        <label htmlFor="userID">User ID</label>
                        <Input
                          id="userId"
                          placeholder="Enter userId"
                          type="text"
                          name="userId"
                          value={transaction2.userId}
                          onChange={onChangeTransaction2}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-4">
                        <label htmlFor="discoId">DiscoId</label>
                        <Input
                          id="discoId"
                          type="select"
                          name="discoId"
                          value={transaction2.discoId}
                          onChange={onChangeTransaction2}
                        >
                          <option value="">Choose...</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="meterTypeId">MeterTypeId</label>
                        <Input
                          id="meterTypeId"
                          type="select"
                          name="meterTypeId"
                          value={transaction2.meterTypeId}
                          onChange={onChangeTransaction2}
                        >
                          <option selected="" value="">
                            Choose...
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                        </Input>
                      </FormGroup>
                    </div>
                    <FormGroup>
                      <label htmlFor="meterAddress">Meter Address</label>
                      <Input
                        id="meter_address"
                        placeholder="Enter Address"
                        type="text"
                        name="meter_address"
                        value={transaction2.meter_address}
                        onChange={onChangeTransaction2}
                      ></Input>
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="response">Response</label>
                      <Input
                        id="response"
                        placeholder="Enter response"
                        type="textarea"
                        name="response"
                        value={breakResponse(transaction2.response)}
                        onChange={onChangeTransaction2}
                      ></Input>
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="response">Account Name</label>
                      <Input
                        id=" account_name"
                        placeholder="Enter account name"
                        type="textarea"
                        name=" account_name"
                        defaultValue={transaction2.account_name}
                        value={transaction2.account_name}
                        onChange={onChangeTransaction2}
                      ></Input>
                    </FormGroup>

                    <Button
                      className="btn-round"
                      type="submit"
                      style={{
                        color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      size="lg"
                    >
                      Update
                    </Button>
                  </Form>
                  {alert3}
                  <div>{message3 ? <Message message={message3} /> : null}</div>
                </div>
              )}
            </TabPane>
            <TabPane tabId="iconTabs3">
              <DataTableExtensions
                {...tableData}
                filter={true}
                export={false}
                print={false}
                filterHidden={false}
              >
                <DataTable
                  title="Failed transactions"
                  responsive={true}
                  pagination={true}
                  columns={columns}
                  data={failedTransactions}
                  theme="solarized"
                  customStyles={customStyles}
                />
              </DataTableExtensions>
            </TabPane>
            <TabPane tabId="iconTabs4">
              <DataTableExtensions
                {...tableData2}
                filter={true}
                export={false}
                print={false}
                filterHidden={false}
              >
                <DataTable
                  title="Waiting transactions"
                  responsive={true}
                  pagination={true}
                  columns={columns}
                  data={liveFailedTransactions}
                  theme="solarized"
                  customStyles={customStyles}
                />
              </DataTableExtensions>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
}

export default FailedTransactionsQueries;
