import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import UnPrivateRoute from "../src/hocs/unPrivateRoute";
import PrivateRoute from "../src/hocs/privateRoute";
import IndexPage from "../src/views/Index";
import LoginPage from "../src/views/loginPage";
import RegisterPage from "../src/views/registerPage.js";
import RepPage from "./views/repPage";
import AdminPage from "../src/views/adminPage";
import ManagerPage from "../src/views/managerPage";
import ForgotPasswordPage from "../src/views/forgotPasswordPage";
import ResetPasswordPage from "../src/views/resetPasswordPage";
import DashboardPage from "../src/views/dashboardPage";

import Transactions from "views/transactionPage";
import AboutPage from "views/aboutPage";
import ShopPage from "views/shopPage";
import PolicyPage from "views/policyPage";
import ProductPage from "views/productPage";
import FAQPage from "views/faqPage";
import TermsPage from "views/termsPage";
import CartPage from "views/CartPage";
import MeterPage from "views/meterPage";
import Invoice from "views/invoice";
import Invoice3 from "views/invoice3";
import UpdatePasswordPage from "views/updatePasswordPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/index-page" component={IndexPage} />
          <UnPrivateRoute
            path="/forgotPassword-page"
            component={ForgotPasswordPage}
          />

          <Route path="/shop-page" component={ShopPage} />
          <PrivateRoute
            roles="user"
            path="/invoice-page/:id"
            component={Invoice}
          />
          <Route path="/update-page/:username" component={UpdatePasswordPage} />
          <PrivateRoute
            roles="user"
            path="/invoice3-page/:id"
            component={Invoice3}
          />
          <Route path="/cart-page" component={CartPage} />
          <Route path="/about-page" component={AboutPage} />
          <Route path="/policy-page" component={PolicyPage} />
          <Route path="/faq-page" component={FAQPage} />
          <Route path="/terms-page" component={TermsPage} />

          <UnPrivateRoute
            path="/resetpassword-page/:token"
            component={ResetPasswordPage}
          />

          <Route path="/product-page/:id" component={ProductPage} />

          <UnPrivateRoute path="/login-page" component={LoginPage} />
          <UnPrivateRoute path="/register-page" component={RegisterPage} />
          <PrivateRoute roles="rep" path="/rep-page" component={RepPage} />
          <PrivateRoute
            roles="user"
            path="/dashboard-page"
            component={DashboardPage}
          />

          <PrivateRoute
            roles="user"
            path="/transactions-page"
            component={Transactions}
          />

          <PrivateRoute
            roles="admin"
            path="/admin-page"
            component={AdminPage}
          />

          <PrivateRoute
            roles="user"
            path="/meter-page/:id"
            component={MeterPage}
          />

          <PrivateRoute
            roles="manager"
            path="/manager-page"
            component={ManagerPage}
          />

          <Redirect from="/" to="/index-page" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
