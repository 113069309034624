import React from "react";
import Lottie from "react-lottie";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components
import * as legoData from "../../Workflow.json";
import colors from "config/colors.config";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: legoData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function CustomSolutions() {
  return (
    <>
      <div className="section section-dark section-nucleo-icons">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <h2 className="text-center">Custom Solutions</h2>
              <br />
              <p className="description">
                Our custom IOT solutions provides you with custom energy
                management services tailored to meet your need and services. if
                you desire quality, low-cost and quick turnaround, contact us
                today and our engineers will schedule a visit to your office.
              </p>
              <br />
              <Button
                className="btn-round"
                href="tel:+2348174735017"
                target="_blank"
                style={{
                  color: colors.secondary,
                  backgroundColor: colors.primary,
                }}
              >
                Call us
              </Button>
              <Button
                className="btn-round ml-1"
                href="mailto:info@comparelight.com"
                outline
                style={{
                  color: colors.secondary,
                }}
                target="_blank"
              >
                Mail us
              </Button>
            </Col>
            <Col lg="6" md="12">
              <div className="icons-container">
                <Lottie options={defaultOptions} maxWidth={500} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
}

export default CustomSolutions;
