import Cookies from "universal-cookie";
import axios from "axios";
const cookies = new Cookies();
let tok = cookies.get("access_token");
let url = "https://smswallet.com.ng:9091";
//let url = "http://localhost:7070";

export default {
  createTransaction: (transaction) => {
    return fetch(url + "/createtransaction", {
      method: "post",
      body: JSON.stringify(transaction),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  getUserTransactions: async (userId) => {
    return await fetch(url + "/usertransactions", {
      method: "post",
      body: JSON.stringify(userId),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
  getUserSuccessfulTransactions: async (userId) => {
    return await fetch(url + "/usersuccessfultransactions", {
      method: "post",
      body: JSON.stringify(userId),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
  getUserSuccessfulTransactionsAmount: async (userId) => {
    return await fetch(url + "/usersuccessfultransactionsamount", {
      method: "post",
      body: JSON.stringify(userId),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  getMonthTotal: async (userId) => {
    return await fetch(url + "/monthlytotal", {
      method: "post",
      body: JSON.stringify(userId),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
  getMonthSuccessfulTotal: async (payload) => {
    return await fetch(url + "/successfulmonthlytotal", {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
  getUnitMonthTotal: async (userId) => {
    return await fetch(url + "/unitsmonthlytotal", {
      method: "post",
      body: JSON.stringify(userId),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
  getSuccessfulUnitMonthTotal: async (userId) => {
    return await fetch(url + "/unitssuccessfulmonthlytotal", {
      method: "post",
      body: JSON.stringify(userId),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
  getSuccessfulRateMonthTotal: async (userId) => {
    return await fetch(url + "/ratessuccessfulmonthlytotal", {
      method: "post",
      body: JSON.stringify(userId),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
  getSuccessfulBalanceMonthTotal: async (userId) => {
    return await fetch(url + "/balancessuccessfulmonthlytotal", {
      method: "post",
      body: JSON.stringify(userId),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
  getTotalByMonth: async (userId) => {
    return await fetch(url + "/monthlyarray", {
      method: "post",
      body: JSON.stringify(userId),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  getSuccessfulTotalByMonth: async (userId) => {
    return await fetch(url + "/successfulmonthlyarray", {
      method: "post",
      body: JSON.stringify(userId),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  getUnitTotalByMonth: async (userId) => {
    return await fetch(url + "/monthlyunitarray", {
      method: "post",
      body: JSON.stringify(userId),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
  getSuccessfulUnitTotalByMonth: async (userId) => {
    return await fetch(url + "/successfulmonthlyunitarray", {
      method: "post",
      body: JSON.stringify(userId),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  getSuccessfulRateTotalByMonth: async (userId) => {
    return await fetch(url + "/successfulmonthlyratearray", {
      method: "post",
      body: JSON.stringify(userId),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  getSuccessfulBalanceTotalByMonth: async (userId) => {
    return await fetch(url + "/successfulmonthlybalancearray", {
      method: "post",
      body: JSON.stringify(userId),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  getInvoice: async (invoice_no) => {
    return await fetch(url + "/findinvoice", {
      method: "post",
      body: JSON.stringify(invoice_no),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },

  getVtpass: async (
    request_id,
    serviceID,
    billersCode,
    variation_code,
    amount,
    phone
  ) => {
    return axios("https://api-service.vtpass.com/api/pay", {
      method: "post",
      body: JSON.stringify(
        request_id,
        serviceID,
        billersCode,
        variation_code,
        amount,
        phone
      ),
      headers: {
        "secret-key": "SK_853137bf5a1333c7ca87c0af26998927e6954feb2d8",
        "primary-key": "PK_5312be1743966f7ea35fbb126e16879338814923c43",
        "Content-Type": "application/json",
        "api-key": "ca7b1f3e203c407669ec7ac79bcb9501",
      },
    }).then((response) => {
      return response;
    });
  },
};
