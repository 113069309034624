import React, { useEffect } from "react";

// reactstrap components
import { Row } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar";
import colors from "config/colors.config";
import TransparentFooter from "components/Footers/TransparentFooter";
import LandingPageHeader from "components/Headers/LandingPageHeader";

function FAQPage(props) {
  //Mobile menu
  useEffect(() => {
    document.body.classList.add("manager-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("manager-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <AuthNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section">
          <h2
            style={{
              color: colors.pageHeader,
              marginBottom: "0px",
              fontWeight: "600",

              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            Frequently asked questions
          </h2>
          <Row style={{ margin: "50px" }}>
            <div style={{ marginBottom: "10px" }}>
              <h3>What is a token?</h3>
              <p>
                A token is the 20 digits number you receive after making payment
                to recharge your prepaid meter. The token is what you enter into
                your meter so that you can have electricity.
              </p>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <h3>What is minimum payment?</h3>
              <p>
                Minimum payment is the amount your electricity distribution
                company is asking you to pay before you can have electricity.
                The amount is usually arrived at based on your oustanding
                payments on your postpaid meter and or amount for the 100 units
                of power that was on your prepaid meter when it was installed.
              </p>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <h3>Why register on comparelight.com?</h3>
              <p>
                Comparelight.com insist that you should register before you vend
                because by registering we are able to keep records of your
                previous transactions for record purposes and for your own use.
                Registered customers are able to print their receipts anytime
                they want and they can also easily calculate how much they spend
                on electricity each month. Apart from that, we also give
                freebies to our registered customers on regular basis.
              </p>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <h3>What is meter number?</h3>
              <p>
                Dependiing on your meter, your meter number is usually written
                on your smart card or you can find it on your receipt from
                previous recharges. if in doubt please{" "}
                <a href="tel:+2348174735017">contact our customer service</a>{" "}
                for help
              </p>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <h3>What is my account number?</h3>
              <p>
                Depending on your meter type. Most postpaid customers recharge
                by using their account number which is visible on your ikeja
                electric bill or nepa bill.
              </p>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <h3>Who is a prepaid customer?</h3>
              <p>
                A prepaid customer uses a prepaid meter. They pay for
                electricity before they can use it and they do not get monthly
                bill.
              </p>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <h3>Who is a postpaid customer?</h3>
              <p>
                A postpaid customer is given a bill by the electricity
                distribution company on a monthly basis. They pay for
                electricity after consumption.
              </p>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <h3>What is my tarrif class?</h3>
              <p>
                The tarrif class is clearly stated on the bill. You can also
                check this page to have an idea here
              </p>
            </div>
            <Row>
              <div style={{ marginBottom: "10px" }}>
                <h3>My money is deducted and i have not received a token</h3>
                <p>
                  If you made the transaction on comparelight.com pleasse
                  contact us otherwise contact{" "}
                  <a href="http://www.ikejaelectric.com/contact-ikeja-electric/">
                    ikeja electric customer care
                  </a>
                </p>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <h3>Why is my monitor displaying an Error E9 message?</h3>
                <p>
                  This could be as a result of the following; The monitor card
                  is not properly connected to the meter or the monitor unit of
                  the meter is faulty. Contact Ikeja electric for further
                  assistance.
                </p>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <h3>How can I get a prepaid meter?</h3>
                <p>Contact Ikeja electric for the most recent procedure.</p>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <h3>I lost my prepaid smart meter card what can i do?</h3>
                <p>
                  Visit any of ikeja electric business units closest to you with
                  the copy of your previous vending/purchase receipt. Repacing
                  your card costs =N=5,000.00, and payment should be made into
                  Zenith Bank (1013104220).
                </p>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <h3>Why is my meter displaying an error message?</h3>
                <p>
                  This could be due to low voltage. Please wait until the
                  voltage is normal.
                </p>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <h3>As a tenant, can I register the meter in my name?</h3>
                <p>
                  No, you cannot, as meters are registered in the name of the
                  landlord. Please let your landlord Ikeja electric for more
                  information on how to register your meter.
                </p>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <h3>
                  Whose responsibility is it to maintain the meters
                  post-installation?
                </h3>
                <p>
                  It is the responsibility of Ikeja Electric, at no cost to the
                  customer. However please note that this does not cover cost of
                  willful or malicious damages. therefore customers are
                  encouraged to safeguard their meters.
                </p>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <h3>My meter has just been installed, what do I do?</h3>
                <p>
                  Visit the ikeja electric business unit office with the Meter
                  form and photocopy of the installation form with valid means
                  of identification i.e. (international passport, driver’s
                  license or voter’s card) to be submitted for documentation
                  after being duly signed.
                </p>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <h3>What is AMI</h3>
                <p>
                  Ikeja Electrics Advanced Meter Infrastructure (AMI) is a
                  state-of-the-art technology that enables utilities to read,
                  disconnect and connect meters remotely and to detect
                  individual customer outages quickly using a wireless
                  communications network. Information derived from the AMI
                  system can be used to improve electric system performance,
                  customer service and operational efficiency.
                </p>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <h3>Will I get a meter if I am indebted to Ikeja Electric?</h3>
                <p>
                  Yes you will get a meter, but the debt will be factored into
                  your account.
                </p>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <h3>Why is my monitor displaying an Error E5 message?</h3>
                <p>
                  Your smart card or your monitor unit might be faulty. Contact
                  ikeja electric for further assistance.
                </p>
              </div>
            </Row>
          </Row>
        </div>

        <TransparentFooter />
      </div>
    </>
  );
}

export default FAQPage;
