import Cookies from "universal-cookie";
const cookies = new Cookies();
let tok = cookies.get("access_token");
let url = "https://smswallet.com.ng:9091";
//let url = "http://localhost:7070";

export default {
  getNewsFeed: () => {
    return fetch(url + "/allnews", {
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
};
