/*eslint-disable*/
import React, { createRef, useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { createLocalStorageStateHook } from "use-local-storage-state";
import ReactTextTransition, { presets } from "react-text-transition";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";

// reactstrap components
import {
  Container,
  Col,
  Card,
  Form,
  InputGroup,
  Input,
  Button,
} from "reactstrap";

// core components
import AuthService from "services/authService";
import Typewrite from "components/TypeWrite";
import * as legoData from "../../pops.json";
import colors from "config/colors.config";
import Message from "components/Message";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: legoData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const useUser = createLocalStorageStateHook("user");

function IndexHeader(props) {
  const [user, setUser] = useUser({ username: "" });
  const [message, setMessage] = useState(null);
  const [alert, setAlert] = useState("");
  const [isLoaded, setIsLoaded] = useState(undefined);
  let timerID = useRef(null);
  let pageHeader = createRef();

  useEffect(() => {
    return () => {
      clearTimeout(timerID);
    };
  }, []);

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  // timerID = setTimeout(() => {}, 2000);

  const onSubmit = (e) => {
    setIsLoaded(false);
    setAlert("Verifying User...");
    e.preventDefault();
    try {
      AuthService.welcome(user).then((data) => {
        const { message } = data;
        timerID = setTimeout(() => {
          setAlert("Redirecting...");
        }, 2000);

        if (message.msgBody === "Please Login" && message.user === "") {
          setIsLoaded(true);
          props.history.push("/update-page/" + user.username);
        }
        if (message.msgBody === "Please Login" && message.user != "") {
          setIsLoaded(true);
          props.history.push("/login-page");
        }

        if (message.msgBody === "Please Register") {
          timerID = setTimeout(() => {
            setMessage(null);
            setIsLoaded(true);
            props.history.push("/register-page");
          }, 3000);
        } else {
          setMessage(null);
          setIsLoaded(true);
        }
      });
    } catch (error) {
      setMessage(error || "Something went wrong");
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        // let windowScrollTop = window.pageYOffset / 3;
        // pageHeader.current.style.transform =
        //   "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div className="page-header clear-filter" filter-color={colors.secondary}>
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(https://res.cloudinary.com/comparelight-com/image/upload/v1598266843/homepage_Background_Image.jpg)",
          }}
          ref={pageHeader}
        ></div>
        <Container>
          {isLoaded === false ? (
            <FadeIn>
              <Lottie
                options={defaultOptions}
                height={300}
                width={300}
                style={{ marginTop: "150px" }}
              />
              <div className="container">
                <ReactTextTransition
                  text={alert}
                  spring={presets.gentle}
                  delay={300}
                  className="h3"
                  inline
                />
              </div>
            </FadeIn>
          ) : (
            <div>
              <div className=" brand">
                <img
                  alt="..."
                  width="150px"
                  className="n-logo"
                  src={
                    "https://res.cloudinary.com/comparelight-com/image/upload/v1598268099/comparelight_logo.png"
                  }
                ></img>
                <Col className="ml-auto mr-auto " md="4">
                  <Card className="card-login card-plain">
                    <Form className="form" onSubmit={onSubmit}>
                      <InputGroup
                        className={
                          " no-border input-lg"
                          //+
                          //(userFocus ? " input-group-focus" : "")
                        }
                      >
                        {/* <Input
                          placeholder="Enter phoneNumber"
                          variant="outlined"
                          required
                          id="username"
                          onChange={onChange}
                          label="UserName"
                          name="username"
                          autoComplete="username"
                          type="text"
                       
                        /> */}
                        <Input
                          placeholder="Enter phoneNumber"
                          variant="outlined"
                          required
                          id="username"
                          onChange={onChange}
                          label="UserName"
                          name="username"
                          autoComplete="username"
                          type="text"
                          className="custom-input"
                        />
                      </InputGroup>
                      <Button
                        block
                        className="btn-round"
                        type="submit"
                        mt="-3"
                        size="lg"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                      >
                        CONTINUE
                      </Button>
                    </Form>
                    {message ? <Message message={message} /> : null}
                  </Card>
                </Col>
              </div>
              <h1>Smart Energy Management</h1>
              <Typewrite />
            </div>
          )}
        </Container>
        <div
          className="moving-clouds"
          style={{
            backgroundImage:
              "url(https://res.cloudinary.com/comparelight-com/image/upload/v1598271964/moving_clouds.png)",
          }}
        />
      </div>
    </>
  );
}

export default withRouter(IndexHeader);
