import React, { useEffect, createRef } from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components
import colors from "config/colors.config";

function LandingPageHeader() {
  let pageHeader = createRef();

  useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        // let windowScrollTop = window.pageYOffset / 3;
        // pageHeader.current.style.transform =
        //   "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/shopheaderimage.png") + ")",
          }}
          ref={pageHeader}
        ></div>
        <div className="content-center">
          <Container>
            <h1 className="title">Comparelight.com</h1>
            <div className="text-center">
              <Button
                className="btn-icon btn-round"
                color="info"
                href="https://www.facebook.com/comparelight"
                target="_blank"
                style={{
                  backgroundColor: colors.primary,
                }}
              >
                <i className="fab fa-facebook-square"></i>
              </Button>
              <Button
                className="btn-icon btn-round"
                color="info"
                href="https://twitter.com/comparelight"
                target="_blank"
                style={{
                  backgroundColor: colors.primary,
                }}
              >
                <i className="fab fa-twitter"></i>
              </Button>
              <Button
                className="btn-icon btn-round"
                color="info"
                style={{
                  backgroundColor: colors.primary,
                }}
                href="https://www.instagram.com/comparelight_nigeria/"
                target="_blank"
              >
                <i className="fab  fa-instagram"></i>
              </Button>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
