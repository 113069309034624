import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar";
import AdminService from "services/adminService";
import colors from "config/colors.config";
import TransparentFooter from "components/Footers/TransparentFooter";
import InvoiceQueries from "components/Queries.js/rep/invoice.queries";
import FailedTransactionQueries from "components/Queries.js/rep/failedTransactions.queries";
import UserQueries from "components/Queries.js/rep/user.queries";
import ProductQueries from "components/Queries.js/rep/product.queries";

import TransactionQueries from "components/Queries.js/rep/transactions.queries";

function RepPage() {
  const [liveFailedTransaction, setLiveFailedTransaction] = useState();
  const [waitingFailedTransaction, setWaitingFailedTransaction] = useState();
  const [totalFailedTransactions, setTotalFailedTransactions] = useState();

  //All live failed transactions
  useEffect(() => {
    try {
      AdminService.getAllLiveFailedTransaction().then((data) => {
        setLiveFailedTransaction(data.length);
      });
    } catch (error) {}
  }, []);
  // All waiting failed transactions
  useEffect(() => {
    try {
      AdminService.getWaitingTransactions().then((data) => {
        setWaitingFailedTransaction(data.length);
      });
    } catch (error) {}
  }, []);

  // All failed transactions
  useEffect(() => {
    try {
      AdminService.getFailedTransactions().then((data) => {
        setTotalFailedTransactions(data.length);
      });
    } catch (error) {}
  }, []);

  //Failed Transaction Chart
  const data2 = () => {
    return {
      labels: ["Live", "Waiting"],
      datasets: [
        {
          label: "Failed Transactions",
          backgroundColor: ["#FF8C00", "#DC143C"],
          hoverBackgroundColor: ["#FFA500", "#FF0000"],
          data: [liveFailedTransaction, waitingFailedTransaction],
        },
      ],
    };
  };

  //Mobile menu
  useEffect(() => {
    document.body.classList.add("rep-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("rep-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <AuthNavbar />

      <div className="wrapper">
        <div className="section">
          <h2
            style={{
              color: colors.pageHeader,
              marginBottom: "0px",
              fontWeight: "600",

              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            RepBoard
          </h2>
          <Row
            style={{
              marginTop: "20px",
              marginLeft: "20px",
              marginRight: "20px",
              backgroundImage:
                "url(https://res.cloudinary.com/comparelight-com/image/upload/v1598357632/dashboard_background.png)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center right",
              backgroundPositionX: "500px",
              backgroundPositionY: "150px",
              backgroundSize: "contain",
              minHeight: "900px",
            }}
          >
            <Col md="8">
              <Card body inverse color="warning">
                <CardTitle tag="h2" style={{ color: colors.secondary }}>
                  Hello
                  <span style={{ color: colors.white, fontWeight: "600" }}>
                    {" "}
                    Rep
                  </span>
                </CardTitle>
                <CardText style={{ color: colors.black }}>
                  Manage and Control accounts here
                </CardText>
              </Card>
              <Row md="2">
                <Col>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    User Queries
                  </h3>
                  <UserQueries />
                </Col>

                <Col>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Invoice Queries
                  </h3>
                  <InvoiceQueries />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Failed Transaction Queries
                  </h3>
                  <FailedTransactionQueries />
                </Col>
              </Row>
              <Row md="2">
                <Col>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Product Queries
                  </h3>
                  <ProductQueries />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Transaction Queries
                  </h3>
                  <TransactionQueries />
                </Col>
              </Row>
            </Col>

            <Col md="4">
              <Card
                body
                inverse
                style={{
                  backgroundColor: colors.dashboard,
                  minHeight: "200px",
                }}
              >
                <CardHeader>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                      textAlign: "left",
                    }}
                  >
                    Failed Transactions{" "}
                  </h3>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "30px",
                    }}
                  />
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Doughnut data={data2} type="doughnut" />
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="stats">
                    <h5 style={{ color: colors.black, marginTop: "10px" }}>
                      Total Failed Transactions: {totalFailedTransactions}
                    </h5>
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "30px",
                    }}
                  />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default RepPage;
