import Cookies from "universal-cookie";
const cookies = new Cookies();
let tok = cookies.get("access_token");
let url = "https://smswallet.com.ng:9091";
//let url = "http://localhost:7070";
export default {
  sendMail: (user) => {
    return fetch(url + "/sendmail", {
      method: "post",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
        access_token: tok,
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
};
