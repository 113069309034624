import React, { useContext } from "react";

// reactstrap components
import { Row, Col, Label, Input } from "reactstrap";

//core component
import { CartContext } from "context/cartContext";
import AuthNavbar from "components/Navbars/AuthNavbar";
import TransparentFooter from "components/Footers/TransparentFooter";
import LandingPageHeader from "components/Headers/LandingPageHeader";
import colors from "config/colors.config";

function CartPage(props) {
  const [cart] = useContext(CartContext);

  return (
    <>
      <AuthNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section">
          <h2
            style={{
              color: colors.pageHeader,
              marginBottom: "0px",
              fontWeight: "600",

              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            Cart
          </h2>

          {!cart.length ? (
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3
                className="text-warning"
                style={{ textAlign: "center", marginTop: "10px" }}
              >
                Your cart is empty
              </h3>
              <img
                src="https://res.cloudinary.com/sivadass/image/upload/v1495427934/icons/empty-cart.png"
                alt="empty-cart"
              />
            </div>
          ) : (
            cart.map((obj) => {
              return (
                <Row key={obj.id}>
                  <Col md="2">
                    <div>
                      <img alt="..." src={obj.image1_url}></img>
                    </div>
                  </Col>
                  <Col>
                    <h3>{obj.name}</h3>
                  </Col>

                  <Col md="2">
                    <p>
                      Unit Price: <span style={{ color: colors.green }}>₦</span>
                      {obj.price}
                    </p>
                  </Col>
                  <Col md="2">
                    <Label>Quantity</Label>
                    <Input
                      id="quantity"
                      type="number"
                      name="quantity"
                      defaultValue="1"
                      required
                    ></Input>
                  </Col>
                  <Col md="2"></Col>
                  <Col md="2"></Col>
                </Row>
              );
            })
          )}
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default CartPage;
