import React, { useState } from "react";

// reactstrap components
import {
  Button,
  FormGroup,
  Row,
  Form,
  Input,
  Col,
  Card,
  CardTitle,
  CardBody,
  CardHeader,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";

// core components

import colors from "config/colors.config";
import AdminService from "services/adminService";
import Message from "components/Message";

function ProductQueries(props) {
  const [iconTabs, setIconTabs] = useState("1");
  const [productName, setProductName] = useState({ name: "" });
  const [productName2, setProductName2] = useState({ name: "" });
  const [productName3, setProductName3] = useState({ name: "" });
  const [product, setProduct] = useState({
    id: "",
    vendor: "",
    name: "",
    model: "",
    price: "",
    quantity: "",
    status: "",
    description: "",
    image_url: "",
    image1_url: "",
    image2_url: "",
    image3_url: "",
    image4_url: "",
    why: "",
    video_url: "",
    tutorial_url: "",
    updatedAt: "",
  });
  const [product2, setProduct2] = useState({
    id: "",
    vendor: "",
    name: "",
    model: "",
    price: "",
    quantity: "",
    status: "",
    description: "",
    image_url: "",
    image1_url: "",
    image2_url: "",
    image3_url: "",
    image4_url: "",
    why: "",
    video_url: "",
    tutorial_url: "",
  });
  const [product3, setProduct3] = useState({
    id: "",
    vendor: "",
    name: "",
    model: "",
    price: "",
    quantity: "",
    status: "",
    description: "",
    image_url: "",
    image1_url: "",
    image2_url: "",
    image3_url: "",
    image4_url: "",
    why: "",
    video_url: "",
    tutorial_url: "",
  });
  const [alert, setAlert] = useState("");
  const [alert2, setAlert2] = useState("");
  const [alert3, setAlert3] = useState("");
  const [alert4, setAlert4] = useState("");

  const [message, setMessage] = useState(null);
  const [message2, setMessage2] = useState(null);
  const [message3, setMessage3] = useState(null);
  const [message4, setMessage4] = useState(null);

  const [response, setResponse] = useState("false");
  const [response2, setResponse2] = useState("false");
  const [response3, setResponse3] = useState("false");

  //Search product
  const resetResponse = (e) => {
    setResponse("false");
    setMessage("");
  };
  const onChange = (e) => {
    setProductName({
      ...productName,
      [e.target.name]: e.target.value,
    });
  };
  const resetForm = () => {
    setProductName({
      name: "",
    });
  };

  const getProduct = (e) => {
    e.preventDefault();
    setAlert("Searching");
    try {
      AdminService.findProduct(productName).then((data) => {
        if (!data.message) {
          setAlert("");
          setResponse("true");
          setMessage("");
          resetForm();
          setProduct(data);
        } else {
          const { message } = data;
          setAlert("");
          setResponse("false");
          setMessage(message);
        }
      });
    } catch (error) {}
  };

  //Create product
  const onChangeProduct = (e) => {
    setProduct2({
      ...product2,
      [e.target.name]: e.target.value,
    });
  };

  const resetProduct2 = () => {
    setProduct2({
      id: "",
      vendor: "",
      name: "",
      model: "",
      price: "",
      quantity: "",
      status: "",
      description: "",
      image_url: "",
      image1_url: "",
      image2_url: "",
      image3_url: "",
      image4_url: "",
      why: "",
      video_url: "",
      tutorial_url: "",
    });
  };

  const createProduct = (e) => {
    e.preventDefault();
    setAlert2("Creating product");
    try {
      AdminService.createProduct(product2).then((data) => {
        if (data.message.msgBody === "Product successfully created") {
          const { message } = data;
          setAlert2("");
          setMessage2(message);
          resetProduct2();
        } else {
          const { message } = data;
          setAlert2("");
          setMessage2(message);
        }
      });
    } catch (error) {}
  };

  //Update product
  const resetResponse2 = (e) => {
    setResponse2("false");
    setMessage3("");
  };
  const onChange2 = (e) => {
    setProductName2({
      ...product2,
      [e.target.name]: e.target.value,
    });
  };
  const resetForm2 = () => {
    setProductName2({
      name: "",
    });
  };

  const getProduct2 = (e) => {
    e.preventDefault();
    setAlert3("Searching");
    try {
      AdminService.findProduct(productName2).then((data) => {
        if (!data.message) {
          setAlert3("");
          setResponse2("true");
          setMessage3("");
          resetForm2();
          setProduct3(data);
        } else {
          const { message } = data;
          setAlert3("");
          setResponse2("false");
          setMessage3(message);
        }
      });
    } catch (error) {}
  };

  const onChangeProduct3 = (e) => {
    setProduct3({
      ...product3,
      [e.target.name]: e.target.value,
    });
  };

  const resetProduct3 = () => {
    setProduct3({
      id: "",
      vendor: "",
      name: "",
      model: "",
      price: "",
      quantity: "",
      status: "",
      description: "",
      image_url: "",
      image1_url: "",
      image2_url: "",
      image3_url: "",
      image4_url: "",
      why: "",
      video_url: "",
      tutorial_url: "",
    });
  };

  const updateProduct = (e) => {
    e.preventDefault();
    setAlert3("Updating product");
    try {
      AdminService.updateProduct(product3).then((data) => {
        if (data.message.msgBody === "Product was updated successfully.") {
          const { message } = data;
          setAlert3("");
          setMessage3(message);
          resetProduct3();
        } else {
          const { message } = data;
          setAlert3("");
          setMessage3(message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Delete Product
  const resetResponse3 = (e) => {
    setResponse3("false");
    setMessage4("");
  };
  const onChange3 = (e) => {
    setProductName3({
      ...productName3,
      [e.target.name]: e.target.value,
    });
  };
  const resetForm3 = () => {
    setProductName3({
      name: "",
    });
  };
  const deleteProduct = (e) => {
    e.preventDefault();
    setAlert4("Deleting");
    try {
      AdminService.deleteProduct(productName3).then((data) => {
        const { message } = data;
        setAlert4("");
        setResponse3("true");
        setMessage4(message);
        resetForm3();
      });
    } catch (error) {}
  };

  return (
    <div>
      <Card
        className="card-chart"
        body
        inverse
        style={{
          backgroundColor: colors.dashboard,
          color: colors.black,
        }}
      >
        <CardHeader>
          <Nav className="justify-content-center" role="tablist" tabs>
            <NavItem>
              <NavLink
                className={iconTabs === "1" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("1");
                }}
              >
                <i className="now-ui-icons education_glasses"></i>
                Search
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs === "2" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("2");
                }}
              >
                <i className="now-ui-icons files_paper"></i>
                Create
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs === "3" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("3");
                }}
              >
                <i className="now-ui-icons text_caps-small"></i>
                Update
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs === "4" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("4");
                }}
              >
                <i className="now-ui-icons ui-1_simple-delete"></i>
                Delete
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent className="text-center" activeTab={"iconTabs" + iconTabs}>
            <TabPane tabId="iconTabs1" style={{ color: colors.userTitle }}>
              {response === "false" ? (
                <div>
                  <div
                    className="col-md-6 offset-md-3"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                    }}
                  >
                    <Form onSubmit={getProduct} className="form">
                      <Input
                        placeholder="Product Name"
                        type="text"
                        name="name"
                        value={productName.name}
                        required
                        onChange={onChange}
                        id="name"
                      ></Input>
                      <Button
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Find Product
                      </Button>
                    </Form>

                    <p>{alert}</p>
                    {message ? <Message message={message} /> : null}
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "50px",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "50px",
                    padding: "20px",
                    backgroundColor: colors.primary,
                  }}
                >
                  <div>
                    <Button
                      className="btn-round"
                      onClick={resetResponse}
                      style={{
                        color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      size="sm"
                    >
                      Reset
                    </Button>
                  </div>
                  <Row>
                    <Col className>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Id
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.id}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Name
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.name}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Vendor
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.vendor}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Price
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.price}
                      </h5>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Model
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.model}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Quantity
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.quantity}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        UpdatedDate
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.updatedAt.slice(0, 10)}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Status
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.status}
                      </h5>
                    </Col>
                  </Row>

                  <br></br>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        ImageUrl
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.image_url}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        ImageUrl1
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.image1_url}
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        ImageUrl2
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.image2_url}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        ImageUrl3
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.image3_url}
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        ImageUrl4
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.image4_url}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        VideoUrl
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.video_url}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        TutorialUrl
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.tutorial_url}
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Why
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.why}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Description
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {product.description}
                      </h5>
                    </Col>
                  </Row>
                </div>
              )}
            </TabPane>
            <TabPane tabId="iconTabs2">
              <div style={{ color: colors.black }}>
                <Form onSubmit={createProduct} className="form">
                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <label htmlFor="name">Name</label>
                      <Input
                        id="name"
                        placeholder="Enter name"
                        type="text"
                        name="name"
                        value={product2.name}
                        required
                        onChange={onChangeProduct}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label htmlFor="tutorial_url">TutorialUrl</label>
                      <Input
                        id=""
                        placeholder="Enter url"
                        type="text"
                        name="tutorial_url"
                        value={product2.tutorial_url}
                        required
                        onChange={onChangeProduct}
                      ></Input>
                    </FormGroup>
                  </div>
                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <label htmlFor="model">Model</label>
                      <Input
                        id="model"
                        placeholder="Model"
                        type="text"
                        name="model"
                        value={product2.model}
                        required
                        onChange={onChangeProduct}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label htmlFor="price">Price</label>
                      <Input
                        id="price"
                        placeholder="Enter price"
                        type="text"
                        name="price"
                        value={product2.price}
                        required
                        onChange={onChangeProduct}
                      ></Input>
                    </FormGroup>
                  </div>
                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <label htmlFor="quantity">Quantity</label>
                      <Input
                        id="quantity"
                        placeholder="Enter quantity"
                        type="text"
                        name="quantity"
                        value={product2.quantity}
                        required
                        onChange={onChangeProduct}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label htmlFor="video_url">VideoUrl</label>
                      <Input
                        id="video_url"
                        placeholder="Enter url"
                        type="text"
                        name="video_url"
                        value={product2.video_url}
                        required
                        onChange={onChangeProduct}
                      />
                    </FormGroup>
                  </div>
                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <label htmlFor="imageUrl">Image Url</label>
                      <Input
                        id="imageUrl"
                        placeholder="Enter url"
                        type="text"
                        name="image_url"
                        value={product2.image_url}
                        required
                        onChange={onChangeProduct}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label htmlFor="Image1Url">Image1 Url</label>
                      <Input
                        id="image1Url"
                        placeholder="Enter url"
                        type="text"
                        name="image1_url"
                        value={product2.image1_url}
                        required
                        onChange={onChangeProduct}
                      />
                    </FormGroup>
                  </div>
                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <label htmlFor="image2Url">Image2 Url</label>
                      <Input
                        id="image2Url"
                        placeholder="Enter url"
                        type="text"
                        name="image2_url"
                        value={product2.image2_url}
                        required
                        onChange={onChangeProduct}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label htmlFor="Image3Url">Image3 Url</label>
                      <Input
                        id="image3Url"
                        placeholder="Enter url"
                        type="text"
                        name="image3_url"
                        value={product2.image3_url}
                        required
                        onChange={onChangeProduct}
                      />
                    </FormGroup>
                  </div>
                  <FormGroup>
                    <label htmlFor="image4Url">Image4 Url</label>
                    <Input
                      id="image4Url"
                      placeholder="Enter url"
                      type="text"
                      name="image4_url"
                      value={product2.image4_url}
                      required
                      onChange={onChangeProduct}
                    ></Input>
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="why">Why</label>
                    <Input
                      id="why"
                      placeholder="Enter why"
                      type="textarea"
                      name="why"
                      value={product2.why}
                      required
                      onChange={onChangeProduct}
                    ></Input>
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="description">Description</label>
                    <Input
                      id="description"
                      placeholder="Enter description"
                      type="textarea"
                      name="description"
                      value={product2.description}
                      required
                      onChange={onChangeProduct}
                    ></Input>
                  </FormGroup>
                  <div className="form-row">
                    <FormGroup className="col-md-2">
                      <label htmlFor="id">ID</label>
                      <Input
                        id="id"
                        placeholder="Enter id"
                        type="text"
                        name="id"
                        value={product2.id}
                        required
                        onChange={onChangeProduct}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-4">
                      <label htmlFor="status">Status</label>
                      <Input
                        id="status"
                        type="select"
                        name="status"
                        required
                        value={product2.status}
                        onChange={onChangeProduct}
                      >
                        <option value="">Choose...</option>
                        <option value="Published">Published</option>
                        <option value="Draft">Draft</option>
                      </Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label htmlFor="vendor">Vendor</label>
                      <Input
                        id="vendor"
                        type="select"
                        required
                        name="vendor"
                        value={product2.vendor}
                        onChange={onChangeProduct}
                      >
                        <option value="">Choose...</option>
                        <option value="Comparelight">Comparelight</option>
                        <option value="Others">Others</option>
                      </Input>
                    </FormGroup>
                  </div>
                  <Button
                    className="btn-round"
                    type="submit"
                    style={{
                      color: colors.secondary,
                      backgroundColor: colors.primary,
                    }}
                    size="lg"
                  >
                    Create
                  </Button>
                </Form>
                <hr
                  className="warning"
                  style={{
                    backgroundColor: colors.primary,
                    marginTop: "25px",
                  }}
                />
                <p>{alert2}</p>
                <div>{message2 ? <Message message={message2} /> : null}</div>
              </div>
            </TabPane>
            <TabPane tabId="iconTabs3">
              {response2 === "false" ? (
                <div>
                  <div
                    className="col-md-6 offset-md-3"
                    style={{
                      color: colors.black,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                    }}
                  >
                    <Form onSubmit={getProduct2} className="form">
                      <Input
                        placeholder="Product name"
                        type="text"
                        name="name"
                        value={productName2.name}
                        required
                        onChange={onChange2}
                        id="name"
                      ></Input>
                      <Button
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Find Product
                      </Button>
                    </Form>
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "50px",
                    }}
                  />
                  <p>{alert3}</p>
                  <div>{message3 ? <Message message={message3} /> : null}</div>
                </div>
              ) : (
                <div style={{ color: colors.black }}>
                  <Button
                    className="btn-round"
                    onClick={resetResponse2}
                    style={{
                      color: colors.secondary,
                      backgroundColor: colors.primary,
                    }}
                    size="sm"
                  >
                    Reset
                  </Button>
                  <Form onSubmit={updateProduct} className="form">
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="name">Name</label>
                        <Input
                          id="name"
                          placeholder="Enter name"
                          type="text"
                          name="name"
                          value={product3.name}
                          onChange={onChangeProduct3}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="model">Model</label>
                        <Input
                          id=""
                          placeholder="Enter model"
                          type="text"
                          name="model"
                          value={product3.model}
                          onChange={onChangeProduct3}
                        ></Input>
                      </FormGroup>
                    </div>
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="price">Price</label>
                        <Input
                          id="price"
                          placeholder="Enter price"
                          type="text"
                          name="price"
                          value={product3.price}
                          required
                          onChange={onChangeProduct3}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="quantity">Quantity</label>
                        <Input
                          id="quantity"
                          placeholder="Enter quantity"
                          type="text"
                          name="quantity"
                          value={product3.quantity}
                          onChange={onChangeProduct3}
                        ></Input>
                      </FormGroup>
                    </div>
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="imageUrl">Image Url</label>
                        <Input
                          id="ImageUrl"
                          placeholder="Enter url"
                          type="text"
                          name="image_url"
                          value={product3.image_url}
                          onChange={onChangeProduct3}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="image1Url">Image1 Url</label>
                        <Input
                          id="Image1Url"
                          placeholder="Enter url"
                          type="text"
                          name="image1_url"
                          value={product3.image1_url}
                          onChange={onChangeProduct3}
                        ></Input>
                      </FormGroup>
                    </div>
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="image2Url">Image2 Url</label>
                        <Input
                          id="Image2Url"
                          placeholder="Enter url"
                          type="text"
                          name="image2_url"
                          value={product3.image2_url}
                          onChange={onChangeProduct3}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="image3Url">Image3 Url</label>
                        <Input
                          id="Image3Url"
                          placeholder="Enter url"
                          type="text"
                          name="image3_url"
                          value={product3.image3_url}
                          onChange={onChangeProduct3}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="image4Url">Image4 Url</label>
                        <Input
                          id="Image4Url"
                          placeholder="Enter url"
                          type="text"
                          name="image4_url"
                          value={product3.image4_url}
                          onChange={onChangeProduct3}
                        ></Input>
                      </FormGroup>
                    </div>
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="tutorial_url">TutorialUrl</label>
                        <Input
                          id="tutorial_url"
                          placeholder="Enter url"
                          type="text"
                          name="tutorial_url"
                          value={product3.tutorial_url}
                          onChange={onChangeProduct3}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="video_url">VideoUrl</label>
                        <Input
                          id="video_url"
                          placeholder="Enter url"
                          type="text"
                          name="video_url"
                          value={product3.video_url}
                          onChange={onChangeProduct3}
                        />
                      </FormGroup>
                    </div>

                    <FormGroup>
                      <label htmlFor="description">Description</label>
                      <Input
                        id="description"
                        placeholder="Enter description"
                        type="textarea"
                        name="description"
                        value={product3.description}
                        onChange={onChangeProduct3}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label htmlFor="why">why</label>
                      <Input
                        id="why"
                        placeholder="Enter why"
                        type="textarea"
                        name="why"
                        defaultValue={product3.why}
                        value={product3.why}
                        onChange={onChangeProduct3}
                      ></Input>
                    </FormGroup>
                    <div className="form-row">
                      <FormGroup className="col-md-2">
                        <label htmlFor="id">ID</label>
                        <Input
                          id="id"
                          placeholder="Enter id"
                          type="text"
                          name="id"
                          value={product3.id}
                          onChange={onChangeProduct3}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-4">
                        <label htmlFor="status">Status</label>
                        <Input
                          id="status"
                          type="select"
                          name="status"
                          value={product3.status}
                          onChange={onChangeProduct3}
                        >
                          <option selected="" value="">
                            Choose...
                          </option>
                          <option value="Draft">Draft</option>
                          <option value="Published">Published</option>
                        </Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="vendor">Vendor</label>
                        <Input
                          id="vendor"
                          type="select"
                          name="vendor"
                          value={product3.vendor}
                          onChange={onChangeProduct3}
                        >
                          <option value="">Choose...</option>
                          <option value="Comparelight">Comparelight</option>
                          <option value="Others">Others</option>
                        </Input>
                      </FormGroup>
                    </div>
                    <Button
                      className="btn-round"
                      type="submit"
                      style={{
                        color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      size="lg"
                    >
                      Update
                    </Button>
                  </Form>
                  <p>{alert3}</p>
                  <div>{message3 ? <Message message={message3} /> : null}</div>
                </div>
              )}
            </TabPane>
            <TabPane tabId="iconTabs4">
              {response3 === "false" ? (
                <div>
                  <div
                    className="col-md-6 offset-md-3"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                    }}
                  >
                    <Form onSubmit={deleteProduct} className="form">
                      <Input
                        placeholder="Product name"
                        type="text"
                        name="name"
                        value={productName3.name}
                        required
                        onChange={onChange3}
                        id="name"
                      ></Input>
                      <Button
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Delete
                      </Button>
                    </Form>
                    <p>{alert4}</p>
                    {message4 ? <Message message={message4} /> : null}
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "50px",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "50px",
                  }}
                >
                  <div>
                    <Button
                      className="btn-round"
                      onClick={resetResponse3}
                      style={{
                        color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      size="sm"
                    >
                      Reset
                    </Button>
                  </div>
                  <p>{alert4}</p>
                  {message4 ? <Message message={message4} /> : null}
                </div>
              )}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
}

export default ProductQueries;
