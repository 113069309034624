import React, { useEffect } from "react";
// reactstrap components
import { Row } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar";
import colors from "config/colors.config";
import TransparentFooter from "components/Footers/TransparentFooter";
import LandingPageHeader from "components/Headers/LandingPageHeader";

function PolicyPage(props) {
  //Mobile menu
  useEffect(() => {
    document.body.classList.add("manager-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("manager-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <AuthNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section">
          <h2
            style={{
              color: colors.pageHeader,
              marginBottom: "0px",
              fontWeight: "600",

              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            Policy
          </h2>
          <Row style={{ margin: "50px" }}>
            <row>
              <p>
                This privacy policy has been compiled to better serve those who
                are concerned with how their 'Personally Identifiable
                Information' (PII) is being used online. PII, as described in US
                privacy law and information security, is information that can be
                used on its own or with other information to identify, contact,
                or locate a single person, or to identify an individual in
                context. Please read our privacy policy carefully to get a clear
                understanding of how we collect, use, protect or otherwise
                handle your Personally Identifiable Information in accordance
                with our website. <br></br>
              </p>
              <h3>
                Whatpersonal information do we collect from the people that
                visit our blog, website or app? <br></br>
              </h3>
              <p>
                When ordering or registering on our site, as appropriate, you
                may be asked to enter your name, email address, phone number or
                other details to help you with your experience.<br></br>
                Provide us with feedback on our products or services.<br></br>
              </p>
              <h3>
                How do we use your information ? <br></br>
              </h3>
              <p>
                We may use the information we collect from you when you
                register, make a purchase, sign up for our newsletter, respond
                to a survey or marketing communication, surf the website, or use
                certain other site features in the following ways<br></br>
              </p>
              <ul>
                <li>To improve our website in order to better serve you.</li>
                <li>
                  To allow us to better service you in responding to your
                  customer service requests.
                </li>
                <li>To ask for ratings and reviews of services or products.</li>
                <li>
                  To follow up with them after correspondence (live chat, email
                  or phone inquiries)
                </li>
              </ul>
            </row>
            <h3>
              How do we protect your information?<br></br>
            </h3>
            <p>
              Our website is scanned on a regular basis for security holes and
              known vulnerabilities in order to make your visit to our site as
              safe as possible.<br></br> We use regular Malware Scanning.
              <br></br> Your personal information is contained behind secured
              networks and is only accessible by a limited number of persons who
              have special access rights to such systems, and are required to
              keep the information confidential. In addition, all
              sensitive/credit information you supply is encrypted via Secure
              Socket Layer (SSL) technology.<br></br> We implement a variety of
              security measures when a user places an order enters, submits, or
              accesses their information to maintain the safety of your personal
              information. <br></br>All transactions are processed through a
              gateway provider and are not stored or processed on our servers.
              <br></br>
            </p>
            <h3>
              Do we use 'cookies'?<br></br>
            </h3>
            <p>
              We do not use cookies for tracking purposes.<br></br>You can
              choose to have your computer warn you each time a cookie is being
              sent, or you can choose to turn off all cookies. You do this
              through your browser settings. Since browser is a little
              different, look at your browser's Help Menu to learn the correct
              way to modify your cookies. <br></br>If you turn cookies off, Some
              of the features that make your site experience more efficient may
              not function properly.that make your site experience more
              efficient and may not function properly.
              <br></br>
            </p>
            <h3>
              Third-party disclosure<br></br>
            </h3>
            <p>
              We do not sell, trade, or otherwise transfer to outside parties
              your Personally Identifiable Information unless we provide users
              with advance notice. This does not include website hosting
              partners and other parties who assist us in operating our website,
              conducting our business, or serving our users, so long as those
              parties agree to keep this information confidential. We may also
              release information when it's release is appropriate to comply
              with the law, enforce our site policies, or protect ours or
              others' rights, property or safety.<br></br> However,
              non-personally identifiable visitor information may be provided to
              other parties for marketing, advertising, or other uses.
              <br></br>
            </p>
            <h3>
              Third-party links<br></br>
            </h3>
            <p>
              Occasionally, at our discretion, we may include or offer
              third-party products or services on our website. These third-party
              sites have separate and independent privacy policies. We therefore
              have no responsibility or liability for the content and activities
              of these linked sites. Nonetheless, we seek to protect the
              integrity of our site and welcome any feedback about these sites.
              <br></br>
            </p>
            <h3>
              Google<br></br>
            </h3>
            <p>
              Google's advertising requirements can be summed up by{" "}
              <a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en">
                Google's Advertising Principles
              </a>
              .They are put in place to provide a positive experience for users.
              We use Google AdSense Advertising on our website.
              <br></br>
            </p>
            <p>
              Google, as a third-party vendor, uses cookies to serve ads on our
              site. Google's use of the DART cookie enables it to serve ads to
              our users based on previous visits to our site and other sites on
              the Internet. Users may opt-out of the use of the DART cookie by
              visiting the Google Ad and Content Network privacy policy.
              <br></br>
            </p>
            <p>
              We have implemented the following: <br></br>
            </p>

            <ul>
              <li> Remarketing with Google AdSense</li>
              <li>Google Display Network Impression Reporting</li>
            </ul>
            <br></br>
            <p>
              We, along with third-party vendors such as Google use first-party
              cookies (such as the Google Analytics cookies) and third-party
              cookies (such as the DoubleClick cookie) or other third-party
              identifiers together to compile data regarding user interactions
              with ad impressions and other ad service functions as they relate
              to our website.
              <br></br>
            </p>
            <h3>
              Opting out:<br></br>
            </h3>
            <p>
              Users can set preferences for how Google advertises to you using
              the Google Ad Settings page. Alternatively, you can opt out by
              visiting the Network Advertising Initiative Opt Out page or by
              using the Google Analytics Opt Out Browser add on.
              <br></br>
            </p>
            <p>You will be notified of any Privacy Policy changes:</p>
            <br></br>
            <ul>
              <li>On our Privacy Policy Page</li>
            </ul>
            <br></br>
            <br></br>
            <p>Can change your personal information:</p>
            <br></br>
            <ul>
              <li>By emailing us</li>
              <li>By calling us</li>
              <li>By logging in to your account</li>
              <li> By chatting with us or by sending us a support ticket</li>
            </ul>
            <br></br>
            <row>
              <h3>
                Fair Information Practices<br></br>
              </h3>
              <p>
                The Fair Information Practices Principles form the backbone of
                privacy law in the United States and the concepts they include
                have played a significant role in the development of data
                protection laws around the globe. Understanding the Fair
                Information Practice Principles and how they should be
                implemented is critical to comply with the various privacy laws
                that protect personal information. <br></br>In order to be in
                line with Fair Information Practices we will take the following
                responsive action, should a data breach occur: <br></br>
                We will notify you via email Within 1<br></br>
                business day We also agree to the Individual Redress Principle
                which requires that individuals have the right to legally pursue
                enforceable rights against data collectors and processors who
                fail to adhere to the law. This principle requires not only that
                individuals have enforceable rights against data users, but also
                that individuals have recourse to courts or government agencies
                to investigate and/or prosecute non-compliance by data
                processors.
                <br></br>
              </p>
            </row>
            <h3>
              CAN SPAM Act<br></br>
            </h3>
            <p>
              The CAN-SPAM Act is a law that sets the rules for commercial
              email, establishes requirements for commercial messages, gives
              recipients the right to have emails stopped from being sent to
              them, and spells out tough penalties for violations.
              <br></br>
            </p>
            <div>
              <p>We collect your email address in order to:</p>
              <ul>
                <li>
                  Send information, respond to inquiries, and/or other requests
                  or questions
                </li>
                <li>
                  Process orders and to send information and updates pertaining
                  to orders.
                </li>
                <li>
                  Send you additional information related to your product and/or
                  service
                </li>
              </ul>
            </div>
            <br></br>

            <div>
              <p>
                To be in accordance with CANSPAM, we agree to the following:
              </p>
              <ul>
                <li>
                  Not use false or misleading subjects or email addresses.
                </li>
                <li>
                  Identify the message as an advertisement in some reasonable
                  way.
                </li>
                <li>
                  Include the physical address of our business or site
                  headquarters.
                </li>
                <li>
                  {" "}
                  Monitor third-party email marketing services for compliance,
                  if one is used.
                </li>
                <li>Honor opt-out/unsubscribe requests quickly.</li>
                <li>
                  Allow users to unsubscribe by using the link at the bottom of
                  each email.
                </li>
              </ul>

              <br></br>
            </div>
            <br></br>
            <p>
              If at any time you would like to unsubscribe from receiving future
              emails, you can email us at info@comparepricemarket.com and we
              will promptly remove you from ALL correspondence.
              <br></br>
            </p>
            <row>
              <h3>
                Contacting Us<br></br>
              </h3>
              <p>
                If there are any questions regarding this privacy policy, you
                may contact us using the information below.
                <br></br>
                <br></br>
              </p>
              <p>
                www.comparelight.com<br></br>
                19, oremeta Street<br></br>
                Oregun, Ikeja, Lagos 23401<br></br>
                Nigeria<br></br>
                info@comparelight.com<br></br>
                <br></br>
                Last Edited on 2018-02-09
                <br></br>
              </p>
            </row>
          </Row>
        </div>

        <TransparentFooter />
      </div>
    </>
  );
}

export default PolicyPage;
