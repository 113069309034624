import React, { useState, useContext } from "react";

import {
  Button,
  Modal,
  Row,
  Col,
  Card,
  CardImg,
  Input,
  Label,
} from "reactstrap";

//core component
import { CartContext } from "context/cartContext";
import colors from "config/colors.config";

function Cart(props) {
  const [cartModal, setCartModal] = useState(false);
  const [cart] = useContext(CartContext);

  return (
    <>
      <Button
        onClick={() => setCartModal(true)}
        style={{
          color: colors.secondary,
          backgroundColor: colors.primary,
        }}
      >
        View Cart
      </Button>
      <Modal
        isOpen={cartModal}
        toggle={() => setCartModal(false)}
        modalClassName="modal-register"
        className="modal-lg"
      >
        <div
          className="modal-header no-border-header text-center"
          style={{ display: "grid" }}
        >
          <div>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              style={{
                color: colors.secondary,
              }}
              onClick={() => setCartModal(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <h3 className="text-muted">Shopping Cart</h3>
          </div>
        </div>
        <div className="modal-body">
          {cart.map((element) => {
            return (
              <Card
                style={{
                  padding: "20px",
                }}
                key={element.id}
              >
                <hr
                  style={{
                    backgroundColor: colors.primary,
                    marginTop: "30px",
                  }}
                />
                <Row>
                  <Col md="3">
                    <CardImg
                      top
                      width="100%"
                      src={element.image_url}
                      alt="product image"
                      style={{ width: "35%" }}
                    />
                  </Col>
                  <Col md="3">
                    <h5>{element.name}</h5>
                  </Col>
                  <Col md="3">
                    <p>
                      Unit Price: <span style={{ color: colors.green }}>₦</span>
                      {element.price}
                    </p>
                  </Col>
                  <Col md="3">
                    <Label>Quantity</Label>
                    <Input
                      id="quantity"
                      type="number"
                      name="quantity"
                      defaultValue="1"
                      required
                    ></Input>
                  </Col>
                </Row>
              </Card>
            );
          })}
        </div>

        {/* <div className="modal-footer no-border-footer">
          <span className="text-muted text-center">
            <a
              href="#pablo"
              onClick={(e) => e.preventDefault()}
              style={{
                color: colors.secondary,
              }}
            >
              Recharge here
            </a>
          </span>
        </div> */}
      </Modal>
    </>
  );
}

export default Cart;
