import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Form,
  Input,
  Card,
  CardBody,
  CardHeader,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";

// core components

import colors from "config/colors.config";
import AdminService from "services/adminService";
import Message from "components/Message";

function NewsFeedQueries(props) {
  const [iconTabs, setIconTabs] = React.useState("2");
  const [newsDetail, setNewsDetail] = useState({
    title: "",
  });
  const [alert, setAlert] = useState("");
  const [alert2, setAlert2] = useState("");

  const [message, setMessage] = useState(null);
  const [message2, setMessage2] = useState(null);

  //const [response, setResponse] = useState("false");
  const [response2, setResponse2] = useState("false");

  //Create news
  //   const resetResponse = (e) => {
  //     setResponse("false");
  //     setMessage("");
  //   };
  const createNewsFeed = (e) => {
    e.preventDefault();
    setAlert("Creating news");
    try {
      AdminService.createNewsFeed().then((data) => {
        const { message } = data;
        setAlert("");
        setMessage(message);
      });
    } catch (error) {}
  };

  //Delete news
  const resetResponse2 = (e) => {
    setResponse2("false");
    setMessage2("");
  };
  const onChange = (e) => {
    setNewsDetail({
      ...newsDetail,
      [e.target.name]: e.target.value,
    });
  };
  const resetForm3 = () => {
    setNewsDetail({
      title: "",
    });
  };
  const deleteNewsFeed = (e) => {
    e.preventDefault();
    setAlert2("Deleting");
    try {
      AdminService.deleteNewsFeed(newsDetail).then((data) => {
        const { message } = data;
        setAlert2("");
        setResponse2("true");
        setMessage2(message);
        resetForm3();
      });
    } catch (error) {}
  };

  return (
    <div>
      <Card
        className="card-chart"
        body
        inverse
        style={{
          backgroundColor: colors.dashboard,
          color: colors.black,
        }}
      >
        <CardHeader>
          <Nav className="justify-content-center" role="tablist" tabs>
            <NavItem>
              <NavLink
                className={iconTabs === "2" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("2");
                }}
              >
                <i className="now-ui-icons files_paper"></i>
                Create
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs === "4" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("4");
                }}
              >
                <i className="now-ui-icons ui-1_simple-delete"></i>
                Delete
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent className="text-center" activeTab={"iconTabs" + iconTabs}>
            <TabPane tabId="iconTabs2">
              <div style={{ color: colors.black }}>
                <Form onSubmit={createNewsFeed} className="form">
                  <Button
                    className="btn-round"
                    type="submit"
                    style={{
                      color: colors.secondary,
                      backgroundColor: colors.primary,
                    }}
                    size="lg"
                  >
                    Create
                  </Button>
                </Form>
                <hr
                  className="warning"
                  style={{
                    backgroundColor: colors.primary,
                    marginTop: "25px",
                  }}
                />
                <p> {alert}</p>
                <div>{message ? <Message message={message} /> : null}</div>
              </div>
            </TabPane>

            <TabPane tabId="iconTabs4">
              {response2 === "false" ? (
                <div>
                  <div
                    className="col-md-6 offset-md-3"
                    style={{
                      color: colors.black,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                    }}
                  >
                    <Form onSubmit={deleteNewsFeed} className="form">
                      <Input
                        placeholder="enter title"
                        type="text"
                        name="title"
                        value={newsDetail.title}
                        required
                        onChange={onChange}
                        id="title"
                      ></Input>
                      <Button
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Delete
                      </Button>
                    </Form>

                    <p>{alert2}</p>
                    {message2 ? <Message message={message2} /> : null}
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "50px",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "50px",
                  }}
                >
                  <div>
                    <Button
                      className="btn-round"
                      onClick={resetResponse2}
                      style={{
                        color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      size="sm"
                    >
                      Reset
                    </Button>
                  </div>
                  <p>{alert2}</p>
                  {message2 ? <Message message={message2} /> : null}
                </div>
              )}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
}

export default NewsFeedQueries;
