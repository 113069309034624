import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

// core components
import colors from "config/colors.config";

function Download() {
  return (
    <>
      <div
        className="section section-download"
        data-background-color="black"
        id="download-section"
      >
        <Container>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h3 className="text-center">Do you love using Comparelight?</h3>
              <h5 className="description">
                Cause if you do, why not be the first to know. Click the button
                below to download the latest comparelight brochure. Where you
                can find our latest smart products that will help you save more
                on your energy consumption.
              </h5>
            </Col>
            <Col className="text-center" lg="8" md="12">
              <Button
                className="btn-round mr-1"
                style={{
                  color: colors.secondary,
                  backgroundColor: colors.primary,
                }}
                href="/"
                size="lg"
              >
                Download Brochure
              </Button>
              <Button
                className="btn-round"
                style={{
                  color: colors.white,
                }}
                href="/shop-page"
                outline
                size="lg"
              >
                Visit Store
              </Button>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <br></br>
          <Row className="text-center mt-5">
            <Col className="ml-auto mr-auto" md="8">
              <h2>Learn more</h2>
              <h5 className="description">
                Watch our tutorials{" "}
                <a href="/" onClick={(e) => e.preventDefault()}>
                  Now on youtube
                </a>
                . Watch our simple how to use tutorials, learn how to install
                your smart devices and other great energy saving tip videos.
              </h5>
            </Col>
            <Col md="12">
              <Button
                className="btn-neutral btn-round"
                style={{
                  color: colors.primary,
                }}
                href="https://www.youtube.com/playlist?list=PLRuALj86UiwOkl1oAo_YvsK41OIkUnHUu"
                size="lg"
              >
                <i className="now-ui-icons arrows-1_share-66 mr-1"></i>
                Watch Video
              </Button>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <Row className="justify-content-md-center sharing-area text-center">
            <Col className="text-center" lg="8" md="12">
              <h3>Thank you for supporting us!</h3>
            </Col>
            <Col className="text-center" lg="8" md="12">
              <Button
                className="btn-neutral btn-icon btn-round"
                color="twitter"
                href="https://twitter.com/comparelight"
                id="tooltip86114138"
                size="lg"
                target="_blank"
              >
                <i className="fab fa-twitter"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip86114138">
                Follow us
              </UncontrolledTooltip>
              <Button
                className="btn-neutral btn-icon btn-round"
                color="facebook"
                href="https://www.facebook.com/compare.light"
                id="tooltip735272548"
                size="lg"
                target="_blank"
              >
                <i className="fab fa-facebook-square"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip735272548">
                Like us
              </UncontrolledTooltip>
              <Button
                className="btn-neutral btn-icon btn-round"
                color="linkedin"
                href="/"
                id="tooltip647117716"
                size="lg"
                target="_blank"
              >
                <i className="fab fa-linkedin"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip647117716">
                Follow us
              </UncontrolledTooltip>
              <Button
                className="btn-neutral btn-icon btn-round"
                color="instagram"
                href="https://www.instagram.com/comparelight_nigeria/"
                id="tooltip331904895"
                size="lg"
                target="_blank"
              >
                <i className="fab fa-instagram"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip331904895">
                Follow us
              </UncontrolledTooltip>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Download;
