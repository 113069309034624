import React, { useEffect, useState, useContext, useRef } from "react";

import Switch from "react-bootstrap-switch";
//import { useHistory } from "react-router-dom";
import { Line, Bar } from "react-chartjs-2";
import DataTable, { createTheme } from "react-data-table-component";
import dateFormat from "dateformat";

// reactstrap components
import {
  Col,
  Row,
  Badge,
  Card,
  CardTitle,
  CardText,
  CardBody,
  CardFooter,
  CardHeader,
  CardLink,
  Button,
  Modal,
  Form,
  FormGroup,
  Input,
  //Table,
} from "reactstrap";

// core components
import colors from "config/colors.config";
import greetings from "components/greetings";
import Message from "components/Message";
import TransactionService from "services/transactionService";
import TransparentFooter from "components/Footers/TransparentFooter";
import PanelHeader from "components/Headers/PanelHeader";
import Recharge from "components/Modal/RechargeModal";
import MeterStatus from "components/Modal/MeterModal";
import AuthNavbar from "components/Navbars/AuthNavbar";
import NewsFeedService from "services/newsFeedService";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import MeterService from "services/meterService";
import { AuthContext } from "context/authContext";
import AuthService from "services/authService";
// eslint-disable-next-line
import meterService from "services/meterService";
//import transactionService from "services/transactionService";

createTheme("solarized", {
  background: {
    default: colors.dashboard,
  },

  context: {
    background: colors.dashboard,
    text: colors.primary,
  },
  divider: {
    default: "#ddd",
  },
});

const customStyles = {
  headCells: {
    style: {
      color: colors.userTitle,
      fontWeight: "400",
    },
    table: {
      style: {
        color: "rgba(248, 244, 238, 80%)",
        backgroundColor: colors.dashboard,
      },
    },
    noData: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        //color: colors.dashboard,
        backgroundColor: colors.dashboard,
      },
    },
  },
};

const columns = [
  {
    name: "Date",
    selector: "date",
    sortable: true,
  },
  {
    name: "Amount",
    selector: "amount",
    sortable: true,
  },

  {
    name: "Token",
    selector: "token",
  },
  {
    name: "Receipt No",
    selector: "invoice_no",
    sortable: true,
  },
  {
    name: "Action",
    selector: "treceipt",
  },
];

function DashboardPage(props) {
  // let history = useHistory();
  //const [user, setUser] = useState([]);
  let timerID = useRef(null);
  const [modalLive, setModalLive] = React.useState(false);
  const { user } = useContext(AuthContext);
  const [meterOutstanding, setMeterOutstanding] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(undefined);
  // const [currentPage] = useState(1);
  //const [transactionsPerPage] = useState(5);
  const [tableData, setTableData] = useState({});
  const [alert, setAlert] = useState("");
  const [alert10, setAlert10] = useState("");
  const [dashboardData, setDashboardData] = useState([]);
  const [successfulAmount, setSuccessfulAmount] = useState([]);
  const [message, setMessage] = useState(null);
  const [meterDetails, setMeterDetails] = useState({
    meter_no: "",
  });

  //Authenticate  user
  useEffect(() => {
    MeterService.getMeterOutstanding({
      meterNo: user.meter_no,
      disco: meterService.getDiscoSlug(user.disco),
      //meterNo: "0207213086540",
      //disco: "aba",
    }).then((data) => {
      const { status, info } = data;

      if (status !== 200) {
        setMeterOutstanding("Not available");
      } else {
        setMeterOutstanding(info.minAmount);
      }
    });
  }, [user.disco, user.meter_no]);

  useEffect(() => {
    TransactionService.getUserSuccessfulTransactions({ userId: user.id }).then(
      (data) => {
        setDashboardData(data);
      }
    );
    TransactionService.getUserSuccessfulTransactionsAmount({
      userId: user.id,
    }).then((data) => {
      if (data.length > 0) {
        setSuccessfulAmount(data);
      } else {
        setSuccessfulAmount([0, 0]);
      }
    });
    setAlert("Loading Transactions...");
    setIsLoaded(false);
    TransactionService.getUserTransactions({ userId: user.id }).then((data) => {
      let pdata = [];
      var treceipt;
      var obj;
      const reversed = data.reverse();
      reversed.forEach((transaction) => {
        const getReceipt = (e) => {
          e.preventDefault();
          const win = window.open(
            "/invoice-page/" + transaction.invoice_no,
            "_blank"
          );
          win.focus();
        };

        treceipt = (
          <Badge
            onClick={getReceipt}
            target="blank"
            style={{
              backgroundColor: colors.primary,
              //marginLeft: "-20px",
              color: colors.secondary,
            }}
            className="btn-round"
            type="button"
            size="sm"
          >
            View receipt
          </Badge>
        );

        obj = {
          //dateFormat(transaction.date, "mm/dd/yyyy"),
          date: transaction.date,
          amount: transaction.amount,
          token: transaction.token,
          treceipt: treceipt,
          invoice_no: transaction.invoice_no,
        };
        pdata.push(obj);
      });
      let tableD = {
        columns,
        data: pdata,
      };
      setTableData(tableD);
      setTransactions(pdata);
      setAlert("");
      setIsLoaded(true);
    });
  }, [user]);

  //update meter number
  useEffect(() => {
    return () => {
      clearTimeout(timerID);
    };
  }, []);
  useEffect(() => {
    if (user.meter_no === null) {
      setModalLive(true);
    } else {
      setModalLive(false);
    }
  }, [user.meter_no]);

  const onChange10 = (e) => {
    setMeterDetails({
      ...meterDetails,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit10 = (e) => {
    e.preventDefault();
    setAlert10("Updating...");
    try {
      AuthService.updateUser({
        id: user.id,
        meter_no: meterDetails.meter_no,
      }).then((data) => {
        const { message } = data;
        if (message.msgBody === "User was updated successfully") {
          window.location.reload();

          setAlert10("");
          setModalLive(false);
        } else {
          setAlert10("");
          setMessage(message);
        }
      });
    } catch (error) {
      console.log(error);
      setAlert10("");
      setMessage(message);
    }
  };
  function updateMeterNumber() {
    if (modalLive === false) {
      return "";
    } else {
      return (
        <Modal toggle={() => setModalLive(false)} isOpen={modalLive}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLiveLabel">
              Update Meter Number
            </h5>
          </div>
          {message ? <Message message={message} /> : null}

          <div className="modal-body">
            {alert10}
            <p>Please provide your meter number</p>
            <Form onSubmit={onSubmit10} className="form">
              <FormGroup>
                <label>Meter number</label>
                <Input
                  id="meter_no"
                  name="meter_no"
                  value={meterDetails.meter_no}
                  className="form-control"
                  type="text"
                  onChange={onChange10}
                />
              </FormGroup>
              <Button
                block
                type="submit"
                className="btn-round"
                style={{
                  color: colors.secondary,
                  backgroundColor: colors.primary,
                }}
              >
                Submit
              </Button>
            </Form>
          </div>
        </Modal>
      );
    }
  }

  //users last transaction
  const lastDashTransaction = dashboardData.slice(-1)[0];
  let lastPayment = { ...lastDashTransaction }.amount;
  let lastUnit = { ...lastDashTransaction }.value;
  let lastRate = { ...lastDashTransaction }.rate;

  //Days since user last transaction
  const today = new Date();
  let lastPaymentDate = new Date({ ...lastDashTransaction }.date);
  let daysSinceLastPayment =
    (today.getTime() - lastPaymentDate.getTime()) / (1000 * 3600 * 24);

  //Get user transaction total in current Month
  const [thisMonthTotal, setThisMonthTotal] = useState();
  const [thisMonthUnitTotal, setThisMonthUnitTotal] = useState();
  //const [thisMonthBalanceTotal, setThisMonthBalanceTotal] = useState();
  //const [thisMonthRateTotal, setThisMonthRateTotal] = useState();
  const [lastMonthUnitTotal, setLastMonthUnitTotal] = useState();
  //const [lastMonthBalanceTotal, setLastMonthBalanceTotal] = useState();
  // const [lastMonthRateTotal, setLastMonthRateTotal] = useState();
  const thisMonth = today.getMonth();
  const lastMonth = today.getMonth() - 1;

  useEffect(() => {
    try {
      TransactionService.getMonthSuccessfulTotal({
        userId: user.id,
        requestMonth: thisMonth,
      }).then((data) => {
        const { TotalTransaction } = data;

        setThisMonthTotal(TotalTransaction);
      });
    } catch (error) {
      console.log(error);
    }
  }, [thisMonth, today, user]);

  // Get user Units (balances)
  useEffect(() => {
    try {
      TransactionService.getSuccessfulUnitMonthTotal({
        userId: user.id,
        requestMonth: thisMonth,
      }).then((data) => {
        const TotalUnits = data.TotalUnits;
        setThisMonthUnitTotal(TotalUnits);
      });
    } catch (error) {
      console.log(error);
    }
  }, [thisMonth, today, user]);

  useEffect(() => {
    try {
      TransactionService.getSuccessfulUnitMonthTotal({
        userId: user.id,
        requestMonth: lastMonth,
      }).then((data) => {
        const TotalUnits = data.TotalUnits;
        setLastMonthUnitTotal(TotalUnits);
      });
    } catch (error) {
      console.log(error);
    }
  }, [lastMonth, today, user]);

  //Get user total transaction in current year
  const [monthlyTransactions, setMonthlyTransactions] = useState([]);
  useEffect(() => {
    try {
      TransactionService.getSuccessfulTotalByMonth({
        userId: user.id,
      }).then((data) => {
        const { MonthlyArray } = data;
        setMonthlyTransactions(MonthlyArray);
      });
    } catch (error) {
      console.log(error);
    }
  }, [user]);

  const getArraySum = (a) => {
    try {
      var total = 0;
      for (var i in a) {
        total += a[i];
      }
      return total;
    } catch (error) {
      console.log(error);
    }
  };

  const thisYearTotalTransactions = getArraySum(monthlyTransactions);

  // Get user total units in a year/month

  const [monthlyTransactionsUnits, setMonthlyTransactionsUnits] = useState([]);

  useEffect(() => {
    try {
      TransactionService.getSuccessfulUnitTotalByMonth({
        userId: user.id,
      }).then((data) => {
        const MonthlyArray = data.MonthlyUnitArray;

        setMonthlyTransactionsUnits(MonthlyArray);
      });
    } catch (error) {
      console.log(error);
    }
  }, [user]);

  const thisYearTotalTransactionsUnits = getArraySum(monthlyTransactionsUnits);

  //Get highest user payment
  const getArrayMax = (numArray) => {
    try {
      return Math.max(...numArray);
    } catch (error) {}
  };
  const highestPayment = getArrayMax(successfulAmount);

  //Mapping Chart Data
  function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }
  const linedata = (canvas) => {
    const ctx = canvas.getContext("2d");
    var chartColor = "#FFFFFF";
    var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#80b6f4");
    gradientStroke.addColorStop(1, chartColor);
    var gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
    gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
    gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.14)");

    return {
      labels: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      datasets: [
        {
          label: "Units(kWh)",
          borderColor: chartColor,
          pointBorderColor: chartColor,
          pointBackgroundColor: "#2c2c2c",
          pointHoverBackgroundColor: "#2c2c2c",
          pointHoverBorderColor: chartColor,
          pointBorderWidth: 1,
          pointHoverRadius: 7,
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          fill: true,
          backgroundColor: gradientFill,
          borderWidth: 2,
          data: monthlyTransactionsUnits,
        },
      ],
    };
  };
  const lineoptions = {
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 0,
        bottom: 0,
      },
    },
    maintainAspectRatio: false,
    tooltips: {
      backgroundColor: "#fff",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    legend: {
      position: "bottom",
      fillStyle: "#FFF",
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "rgba(255,255,255,0.4)",
            fontStyle: "bold",
            beginAtZero: true,
            maxTicksLimit: 5,
            padding: 10,
          },
          gridLines: {
            drawTicks: true,
            drawBorder: false,
            display: true,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            color: "rgba(255,255,255,0.1)",
          },
          ticks: {
            padding: 10,
            fontColor: colors.userTitle,
            fontStyle: "bold",
          },
        },
      ],
    },
  };
  const data = (canvas) => {
    var ctx = canvas.getContext("2d");
    var gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
    gradientFill.addColorStop(0, "rgba(255,165,44, 0)");
    gradientFill.addColorStop(1, hexToRGB("#FFA500", 0.6));
    return {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          label: "Recharge(₦)",
          backgroundColor: gradientFill,
          borderColor: "#FFA500",
          pointBorderColor: "#FFF",
          pointBackgroundColor: "#FFA500",
          pointBorderWidth: 2,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 1,
          pointRadius: 4,
          fill: true,
          borderWidth: 1,
          data: monthlyTransactions,
        },
      ],
    };
  };
  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      bodySpacing: 4,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
      xPadding: 10,
      yPadding: 10,
      caretPadding: 10,
    },
    responsive: 1,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            padding: 10,
            maxTicksLimit: 7,
          },
          gridLines: {
            zeroLineColor: "transparent",
            drawBorder: false,
          },
        },
      ],
      xAxes: [
        {
          display: 0,
          ticks: {
            display: false,
          },
          gridLines: {
            zeroLineColor: "transparent",
            drawTicks: false,
            display: false,
            drawBorder: false,
          },
        },
      ],
    },
    layout: {
      padding: { left: 0, right: 0, top: 15, bottom: 15 },
    },
  };

  // Get notification posts

  useEffect(() => {
    try {
      NewsFeedService.getNewsFeed().then((data) => {
        setNotifications(data);
        setIsLoaded(true);
      });
    } catch (error) {
      setIsLoaded(true);
      setError(error);
    }
  }, [setError]);
  let myArray = notifications.slice(-1)[0];

  let Excerpt = { ...myArray }.excerpt;
  let Title = { ...myArray }.title;
  let BlogUrl = { ...myArray }.blog_url;

  //user greetings
  const currentHour = new Date().getHours();
  const greetingMessage = greetings(currentHour);

  //Mobile Menu
  useEffect(() => {
    document.body.classList.add("dashboard-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("dashboard-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  //meter details
  function meterAccount() {
    if (user.meter_no === null) {
      return "No meter info";
    } else {
      return user.meter_no;
    }
  }
  function dDisco() {
    if (user.disco === null) {
      return "No info!";
    } else {
      return user.disco;
    }
  }
  function outstandingMin() {
    if (meterOutstanding === "Not available") {
      return (
        <h5 style={{ color: colors.black, marginTop: "1px" }}>
          {meterOutstanding}
        </h5>
      );
    } else {
      return (
        <h5 style={{ color: colors.black, marginTop: "1px" }}>
          <span style={{ color: colors.green }}>₦</span>
          {meterOutstanding}
        </h5>
      );
    }
  }

  function lastUpDate() {
    return dateFormat(today, "h:MM TT");
  }
  //last recharge

  function unitIssued() {
    if ({ ...lastDashTransaction }.value) {
      return (
        <div>
          {Number(lastUnit)}
          <span style={{ color: colors.green }}></span>
        </div>
      );
    } else {
      return "No record";
    }
  }

  function amountRechargedD() {
    if (lastPayment) {
      return (
        <div>
          <span style={{ color: colors.green }}>₦</span>
          {lastPayment}
        </div>
      );
    } else {
      return "No record";
    }
  }

  function energyRateD() {
    if (lastRate) {
      return (
        <div>
          <span style={{ color: colors.green }}>₦</span>
          {lastRate}
        </div>
      );
    } else {
      return "No record ";
    }
  }

  // function lastRechargeFooter() {
  //   if (Math.floor(daysSinceLastPayment) > 365) {
  //     const years = Math.floor(daysSinceLastPayment / 365);
  //     const remainingDays = Math.round(daysSinceLastPayment % 365); // Round remaining days
  //     return `${years} year${years > 1 ? "s" : ""} ${remainingDays} day${
  //       remainingDays > 1 ? "s" : ""
  //     }`;
  //   } else if (daysSinceLastPayment > 0) {
  //     return `${daysSinceLastPayment}day(s)`;
  //   } else {
  //     return "0 day(s)";
  //   }
  // }

  function lastRechargeFooter() {
    const threshold = 0.01; // Define a small threshold for rounding to zero

    if (Math.floor(daysSinceLastPayment) > 365) {
      const years = Math.floor(daysSinceLastPayment / 365);
      const remainingDays = Math.round(daysSinceLastPayment % 365); // Round remaining days
      return `${years} year${years > 1 ? "s" : ""} ${remainingDays} day${
        remainingDays > 1 ? "s" : ""
      }`;
    } else if (daysSinceLastPayment >= threshold) {
      return `${daysSinceLastPayment.toFixed(2)} day(s)`; // Show with two decimal places
    } else {
      return "0 day(s)";
    }
  }

  //Payment Details
  function thisMonthTotalD() {
    if (thisMonthTotal) {
      return (
        <div>
          {" "}
          <span style={{ color: colors.green }}>₦</span>{" "}
          {/* {parseFloat(thisMonthTotal).toFixed(2)} */}
          {thisMonthTotal.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </div>
      );
    } else {
      return "No record";
    }
  }
  function thisYearTotalTransactionsD() {
    if (thisYearTotalTransactions) {
      return (
        <div>
          {" "}
          <span style={{ color: colors.green }}>₦</span>
          {/* {parseFloat(thisYearTotalTransactions).toFixed(2)} */}
          {thisYearTotalTransactions.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </div>
      );
    } else {
      return "No record";
    }
  }

  function highestPaymentD() {
    if (highestPayment) {
      return (
        <div>
          {" "}
          <span style={{ color: colors.green }}>₦</span>{" "}
          {/* {parseFloat(highestPayment).toFixed(2)} */}
          {highestPayment.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </div>
      );
    } else {
      return "No record";
    }
  }

  //Electricity Usage
  function thisMonthUnitTotalD() {
    if (thisMonthUnitTotal) {
      return (
        <div>
          {parseFloat(thisMonthUnitTotal).toFixed(2)}
          <span style={{ color: colors.green }}> kWh</span>
        </div>
      );
    } else {
      return "No record";
    }
  }

  function lastMonthUnitTotalD() {
    if (lastMonthUnitTotal) {
      return (
        <div>
          {parseFloat(lastMonthUnitTotal).toFixed(2)}
          <span style={{ color: colors.green }}> kWh</span>
        </div>
      );
    } else {
      return "No record";
    }
  }

  function thisYearTotalTransactionsUnitsD() {
    if (thisYearTotalTransactionsUnits) {
      return (
        <div>
          {parseFloat(thisYearTotalTransactionsUnits).toFixed(2)}
          <span style={{ color: colors.green }}> kWh</span>
        </div>
      );
    } else {
      return "No record";
    }
  }

  //const request_id = "202308092245cp390";
  //const serviceID = "abuja-electric";
  //const billersCode = "45076154769";
  // const variation_code = "prepaid";
  // const amount = "5000";
  //const phone = "09027663173";

  //console.log(thisYearTotalTransactionsBalances);

  return (
    <div>
      <AuthNavbar />
      <h2
        style={{
          color: colors.pageHeader,
          marginBottom: "0px",
          fontWeight: "600",
          marginTop: "62px",
          textAlign: "center",
          paddingTop: "20px",
        }}
      >
        Dashboard
      </h2>
      <Row
        style={{
          marginTop: "20px",
          marginLeft: "20px",
          marginRight: "20px",
          backgroundImage:
            "url(https://res.cloudinary.com/comparelight-com/image/upload/v1598357632/dashboard_background.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center right",
          backgroundPositionX: "500px",
          backgroundPositionY: "150px",
          backgroundSize: "contain",
          minHeight: "900px",
        }}
      >
        {updateMeterNumber()}
        <Col md="8">
          <Card body inverse color="warning">
            <CardTitle tag="h2" style={{ color: colors.secondary }}>
              {greetingMessage}
              <span style={{ color: colors.white, fontWeight: "600" }}>
                {user.firstname}
              </span>
            </CardTitle>
            <h6>
              Power is{" "}
              <span style={{ color: colors.white, fontWeight: "600" }}>
                ON!
              </span>{" "}
            </h6>

            <CardText style={{ color: colors.black }}>
              Manage your spending and control your appliances here.
            </CardText>
          </Card>
          <Row xs="4">
            <Col>
              <Card
                className="card-chart"
                body
                inverse
                style={{
                  backgroundColor: colors.dashboard,
                  minHeight: "500px",
                }}
              >
                <CardHeader>
                  <img
                    src="https://res.cloudinary.com/comparelight-com/image/upload/v1598361158/meter_icon.png"
                    alt="Prepaid meter"
                    height="50px"
                    width="50px"
                  />
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Meter Details
                  </h3>
                  <CardTitle
                    style={{ color: colors.userTitle, marginBottom: "2px" }}
                  >
                    Meter/Account Number
                  </CardTitle>
                  <h5 style={{ color: colors.black, marginTop: "1px" }}>
                    {meterAccount()}
                  </h5>
                  <CardTitle
                    style={{ color: colors.userTitle, marginBottom: "2px" }}
                  >
                    Disco
                  </CardTitle>
                  <h5 style={{ color: colors.black, marginTop: "1px" }}>
                    {dDisco()}
                  </h5>
                  <CardTitle
                    style={{ color: colors.userTitle, marginBottom: "2px" }}
                  >
                    Min.Amount
                  </CardTitle>
                  {outstandingMin()}
                </CardHeader>
                <CardBody>
                  <Recharge />
                </CardBody>
                <CardFooter>
                  <div className="stats" style={{ color: colors.icon }}>
                    <i className="now-ui-icons ui-2_time-alarm" /> Updated{" "}
                    {lastUpDate()}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col>
              <Card
                className="card-chart"
                body
                inverse
                style={{
                  backgroundColor: colors.dashboard,
                  minHeight: "500px",
                }}
              >
                <CardHeader>
                  <img
                    src="https://res.cloudinary.com/comparelight-com/image/upload/v1598381890/Plug_icon.png"
                    alt="Prepaid meter"
                    height="50px"
                    width="50px"
                  />
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Last Recharge
                  </h3>
                  <CardTitle
                    style={{ color: colors.userTitle, marginBottom: "2px" }}
                  >
                    Unit Issued(kWh)
                  </CardTitle>
                  <h5 style={{ color: colors.black, marginTop: "1px" }}>
                    {unitIssued()}
                  </h5>
                  <CardTitle
                    style={{ color: colors.userTitle, marginBottom: "2px" }}
                  >
                    Amount Recharged
                  </CardTitle>
                  <h5 style={{ color: colors.black, marginTop: "1px" }}>
                    {amountRechargedD()}
                  </h5>
                  <CardTitle
                    style={{ color: colors.userTitle, marginBottom: "2px" }}
                  >
                    Energy Rate (₦/kWh)
                  </CardTitle>
                  <h5 style={{ color: colors.black, marginTop: "1px" }}>
                    {energyRateD()}
                  </h5>
                </CardHeader>
                <CardBody>
                  <MeterStatus />
                </CardBody>
                <CardFooter>
                  <div className="stats" style={{ color: colors.icon }}>
                    <i className="now-ui-icons ui-1_calendar-60" />{" "}
                    {lastRechargeFooter()} ago
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col>
              <Card
                className="card-chart"
                body
                inverse
                style={{
                  backgroundColor: colors.dashboard,
                  minHeight: "500px",
                }}
              >
                <CardHeader>
                  <img
                    src="https://res.cloudinary.com/comparelight-com/image/upload/v1598434273/invoice_icon.png"
                    alt="invoice"
                    height="50px"
                    width="50px"
                  />
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Payment Details
                  </h3>
                  <CardTitle
                    style={{ color: colors.userTitle, marginBottom: "2px" }}
                  >
                    This Month Total
                  </CardTitle>
                  <h5 style={{ color: colors.black, marginTop: "1px" }}>
                    {thisMonthTotalD()}
                  </h5>
                  <CardTitle
                    style={{ color: colors.userTitle, marginBottom: "2px" }}
                  >
                    This Year Total
                  </CardTitle>
                  <h5 style={{ color: colors.black, marginTop: "1px" }}>
                    {thisYearTotalTransactionsD()}
                  </h5>
                  <CardTitle
                    style={{ color: colors.userTitle, marginBottom: "2px" }}
                  >
                    Highest Payment
                  </CardTitle>
                  <h5 style={{ color: colors.black, marginTop: "1px" }}>
                    {highestPaymentD()}
                  </h5>
                </CardHeader>
                <CardBody>
                  <Button
                    href="/transactions-page"
                    className="btn-round"
                    style={{
                      backgroundColor: colors.primary,
                      marginLeft: "-20px",
                      color: colors.secondary,
                    }}
                  >
                    Transactions
                  </Button>
                </CardBody>
                <CardFooter>
                  <div className="stats" style={{ color: colors.icon }}>
                    <i className="now-ui-icons business_money-coins" /> From
                    records
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col>
              <Card
                className="card-chart"
                body
                inverse
                style={{
                  backgroundColor: colors.dashboard,
                  minHeight: "500px",
                }}
              >
                <CardHeader>
                  <img
                    src="https://res.cloudinary.com/comparelight-com/image/upload/v1598453311/pluged_icon.png"
                    alt="Plug"
                    height="50px"
                    width="50px"
                  />
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Electricity Usage
                  </h3>
                  <CardTitle
                    style={{ color: colors.userTitle, marginBottom: "2px" }}
                  >
                    Purchased This Month
                  </CardTitle>
                  <h5 style={{ color: colors.black, marginTop: "1px" }}>
                    {thisMonthUnitTotalD()}
                  </h5>
                  <CardTitle
                    style={{ color: colors.userTitle, marginBottom: "2px" }}
                  >
                    Purchased Last Month
                  </CardTitle>
                  <h5 style={{ color: colors.black, marginTop: "1px" }}>
                    {lastMonthUnitTotalD()}
                  </h5>
                  <CardTitle
                    style={{ color: colors.userTitle, marginBottom: "2px" }}
                  >
                    Purchased This Year
                  </CardTitle>
                  <h5 style={{ color: colors.black, marginTop: "1px" }}>
                    {thisYearTotalTransactionsUnitsD()}
                  </h5>
                </CardHeader>
                <CardBody>
                  <Button
                    className="btn-round"
                    style={{
                      color: colors.secondary,
                      marginLeft: "-20px",
                      backgroundColor: colors.primary,
                    }}
                  >
                    Manage
                  </Button>
                </CardBody>
                <CardFooter>
                  <div className="stats" style={{ color: colors.icon }}>
                    <i className="now-ui-icons business_bulb-63" /> Current
                    year: {today.getFullYear()}
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="8">
              <Card
                body
                inverse
                style={{
                  backgroundColor: colors.dashboard,
                  minHeight: "500px",
                }}
              >
                <img
                  src="https://res.cloudinary.com/comparelight-com/image/upload/v1598454910/receipt_icon.png"
                  alt="Receipts"
                  height="50px"
                  width="50px"
                />
                <CardHeader>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Recent Transactions
                  </h3>
                </CardHeader>
                <CardBody>
                  {/* <Table responsive>
                    <thead className=" text-warning">
                      <tr>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Token</th>
                        <th>Receipt No</th>
                        <th className="text-right">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTransactions.map((transaction) => {
                        return (
                          <tr key={transaction.id}>
                            <TransactionItem
                              key={transaction.id}
                              transaction={transaction}
                              loading={isLoaded}
                            />
                          </tr>
                        );
                      })}
                      {currentTransactions.length === 0 && (
                        <span>{alert}</span>
                      )}
                    </tbody>
                  </Table> */}

                  <DataTableExtensions
                    {...tableData}
                    filter={true}
                    export={false}
                    print={false}
                    filterHidden={false}
                  >
                    <DataTable
                      title=""
                      responsive={true}
                      pagination={true}
                      paginationTotalRows={5}
                      paginationPerPage={5}
                      columns={columns}
                      //progressPending={ true}
                      data={transactions}
                      highlightOnHover
                      export={true}
                      progressComponent={alert}
                      print={true}
                      noDataComponent="No transaction record yet"
                      striped={false}
                      // defaultSortAsc={false}
                      theme="solarized"
                      customStyles={customStyles}
                    />
                  </DataTableExtensions>
                  <br></br>
                  <Button
                    color="warning"
                    href="/transactions-page"
                    className="btn-round"
                    style={{
                      backgroundColor: colors.primary,
                      marginLeft: "-20px",
                      color: colors.secondary,
                    }}
                  >
                    View More
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card
                body
                inverse
                style={{
                  backgroundColor: colors.dashboard,
                  minHeight: "400px",
                  display: "grid",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <img
                  src="https://res.cloudinary.com/comparelight-com/image/upload/v1598460470/shoppingcart_icon.png"
                  alt="Shopping cart"
                  height="200px"
                  width="200px"
                />
                <Button
                  className="btn-round"
                  style={{
                    color: colors.secondary,
                    backgroundColor: colors.primary,
                  }}
                >
                  Shop
                </Button>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md="4">
          <Card
            body
            inverse
            style={{
              backgroundColor: colors.dashboard,
              minHeight: "300px",
            }}
          >
            <CardHeader>
              <h3
                style={{
                  color: colors.primary,
                  marginBottom: "10px",
                  fontWeight: "600",
                  textAlign: "left",
                }}
              >
                Transaction Chart{" "}
              </h3>
              <hr
                className="warning"
                style={{
                  backgroundColor: colors.primary,
                  marginTop: "30px",
                }}
              />
            </CardHeader>
            <CardBody>
              <PanelHeader
                size="lg"
                content={<Line data={linedata} options={lineoptions} />}
              />
              <div className="chart-area">
                <Bar data={data} options={options} />
              </div>
            </CardBody>
            <CardFooter>
              <div className="stats"></div>
              <hr
                className="warning"
                style={{
                  backgroundColor: colors.primary,
                  marginTop: "30px",
                }}
              />
            </CardFooter>
          </Card>
          <Card
            body
            inverse
            style={{
              backgroundColor: colors.dashboard,
              minHeight: "300px",
            }}
          >
            <CardHeader>
              <h3
                style={{
                  color: colors.primary,
                  marginBottom: "10px",
                  fontWeight: "600",
                  textAlign: "left",
                }}
              >
                News & Updates{" "}
              </h3>
              <hr
                className="warning"
                style={{
                  backgroundColor: colors.primary,
                  marginTop: "30px",
                }}
              />
            </CardHeader>
            {isLoaded === true ? (
              <div>
                <CardBody>
                  <CardTitle style={{ color: "#696969", textAlign: "left" }}>
                    {Title}
                  </CardTitle>
                  <CardText style={{ color: "#808080", textAlign: "left" }}>
                    {Excerpt}
                  </CardText>
                  <CardLink
                    style={{ color: colors.primary, textAlign: "left" }}
                    href={BlogUrl}
                    target="_blank"
                  >
                    Read More
                  </CardLink>
                </CardBody>
              </div>
            ) : (
              <div
                style={{
                  color: colors.black,
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>Loading</p>
              </div>
            )}
            <CardFooter>
              <div className="stats"></div>
              <hr
                className="warning"
                style={{
                  backgroundColor: colors.primary,
                  marginTop: "30px",
                }}
              />
            </CardFooter>
          </Card>
          <Card
            body
            inverse
            style={{
              backgroundColor: colors.dashboard,
              minHeight: "500px",
            }}
          >
            <CardHeader>
              <h3
                style={{
                  color: colors.primary,
                  marginBottom: "10px",
                  fontWeight: "600",
                  textAlign: "left",
                }}
              >
                {" "}
                Running Applications{" "}
              </h3>
              <hr
                className="warning"
                style={{
                  backgroundColor: colors.primary,
                  marginTop: "30px",
                }}
              />
              <CardTitle
                style={{ color: colors.userTitle, marginBottom: "2px" }}
              >
                TV
              </CardTitle>
              <Switch offColor="" offText="OF" onColor="" onText="ON"></Switch>
            </CardHeader>

            <CardBody></CardBody>
          </Card>
        </Col>
      </Row>
      <TransparentFooter />
    </div>
  );
}

export default DashboardPage;
