import React, { useState, useEffect, useContext } from "react";
import { CartContext } from "context/cartContext";
import { useParams } from "react-router-dom";
// reactstrap components
import {
  Button,
  Row,
  Col,
  Card,
  CardTitle,
  Container,
  CardBody,
  CardHeader,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

import AuthNavbar from "components/Navbars/AuthNavbar";
import ShopService from "services/shopService";
import DarkFooter from "components/Footers/DarkFooter.js";
import GetInTouch from "./index-section/GetInTouch.js";
import Download from "./index-section/Download.js";
import Cart from "components/Modal/CartModal";
import colors from "config/colors.config";

function ProductPage() {
  const [setCart] = useContext(CartContext);
  let productName = new useParams({}).id;
  //const [iconTabs, setIconTabs] = React.useState("1");
  const [item, setItem] = useState({ status: "notAdded" });
  const [features, setFeatures] = useState([]);
  const [tags, setTags] = useState([]);
  const [product, setProduct] = useState({
    id: "",
    vendor: "",
    name: "",
    model: "",
    price: "",
    why: "",
    quantity: "",
    status: "",
    description: "",
    image_url: "",
    image1_url: "",
    image2_url: "",
    image3_url: "",
    image4_url: "",
    video_url: "",
    tutorial_url: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    ShopService.findProduct({ name: productName }).then((data) => {
      setProduct(data);
    });
  }, [productName]);

  useEffect(() => {
    async function getFeatures() {
      await ShopService.findAllProductFeatures({
        shopProductId: product.id,
      }).then((data) => {
        setFeatures(data);
      });
    }
    getFeatures();
  }, [product]);

  useEffect(() => {
    async function getProductTags() {
      await ShopService.findAllProductTags({ shopProductId: product.id }).then(
        (data) => {
          setTags(data);
        }
      );
    }
    getProductTags();
  }, [product.id]);

  useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const addToCart = () => {
    setCart((currentState) => [...currentState, product]);
    setItem({ status: "Added" });
  };

  const firstBenefit = tags[0];
  const secondBenefit = tags[tags.length - 1];

  const items = [
    {
      src: product.image2_url,
      altText: "Buy po",
      caption: "",
    },
    {
      src: product.image3_url,
      altText: "Buy powers",
      caption: "",
    },
    {
      src: product.image4_url,
      altText: "Buy pow",
      caption: "",
    },
  ];

  function CarouselSection() {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
      setAnimating(true);
    };
    const onExited = () => {
      setAnimating(false);
    };
    const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    };
    const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex) => {
      if (animating) return;
      setActiveIndex(newIndex);
    };
    return (
      <>
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {items.map((item) => {
            return (
              <CarouselItem
                onExiting={onExiting}
                onExited={onExited}
                key={item.src}
              >
                <img src={item.src} alt={item.altText} />
                <div className="carousel-caption d-none d-md-block">
                  <h5>{item.caption}</h5>
                </div>
              </CarouselItem>
            );
          })}
          <a
            className="carousel-control-prev"
            data-slide="prev"
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              previous();
            }}
            role="button"
          >
            <i className="now-ui-icons arrows-1_minimal-left"></i>
          </a>
          <a
            className="carousel-control-next"
            data-slide="next"
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              next();
            }}
            role="button"
          >
            <i className="now-ui-icons arrows-1_minimal-right"></i>
          </a>
        </Carousel>
      </>
    );
  }

  return (
    <>
      <AuthNavbar />
      <div className="wrapper">
        <div>
          <div
            style={{
              backgroundColor: colors.dashboard,
              paddingTop: "100px",
              paddingBottom: "20px",
              minHeight: "500px",
            }}
          >
            <Row className="justify-content-center">
              <Col className="col-md-3 ">
                <img alt="..." src={product.image_url}></img>
              </Col>
              <Col className="col-md-3 ">
                <div>
                  <h4> {product.name}</h4>
                  <p>Model: {product.model}</p>
                  <h5 style={{ color: colors.black }}>
                    Price: <span style={{ color: colors.green }}>₦</span>
                    {product.price}
                  </h5>

                  {item.status === "Added" ? (
                    <div>
                      <Cart />
                      <Button
                        href="/shop-page"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                      >
                        Shop more
                      </Button>
                    </div>
                  ) : (
                    <Button
                      onClick={addToCart}
                      className="btn-round"
                      style={{
                        color: colors.primary,
                      }}
                      outline
                    >
                      Add to cart
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <Container className="text-center">
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center" style={{ marginTop: "50px" }}>
                  Why you should have one
                </h2>
                <h5 className="description">{product.why}</h5>
                <br />
              </Col>
            </Row>
            <br />
            <br />
            <Row></Row>
          </Container>
        </div>
        <div className="section section-dark section-nucleo-icons">
          <Container>
            <Row>
              <Col md="4">
                <h2 className="text-left">{{ ...firstBenefit }.title}</h2>
                <br />
                <p style={{ textAlign: "left" }}>
                  {{ ...firstBenefit }.description}
                </p>
              </Col>
              <Col md="8">
                <div style={{ alignContent: "right" }}>
                  <img alt="..." src={product.image1_url}></img>
                </div>
              </Col>
            </Row>
          </Container>
        </div>{" "}
        <div className="section section-dark section-nucleo-icons">
          <Container>
            <Row>
              <Col md="6">{CarouselSection()}</Col>
              <Col md="6">
                <h2 className="text-left">{{ ...secondBenefit }.title}</h2>
                <br />
                <p style={{ textAlign: "left" }}>
                  {{ ...secondBenefit }.description}
                </p>
                <Button
                  className="btn-round ml-1"
                  color="warning"
                  style={{
                    color: "blue",
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "left",
                    width: "150px",
                  }}
                  href="tel:+2348174735017"
                >
                  Talk to us
                </Button>
              </Col>
            </Row>
          </Container>
        </div>{" "}
        <div className="section section-features">
          <h2 className="text-center">Features</h2>
          <Container>
            <Row>
              {features.map((feature) => {
                return (
                  <Col key={feature.id}>
                    <Card
                      body
                      outline
                      color="warning"
                      style={{
                        backgroundColor: "rgba(248, 244, 238, 80%)",
                        width: "18rem",
                      }}
                    >
                      <CardHeader
                        style={{
                          marginTop: "50px",
                          display: "grid",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="https://res.cloudinary.com/dfszquucy/image/upload/v1591630522/drop_pgxdvd.png"
                          alt="feature"
                          width="60px"
                        />
                      </CardHeader>
                      <CardTitle
                        className="info-title"
                        style={{
                          color: "black",
                          marginTop: "50px",
                          fontSize: "15px",
                          display: "grid",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {feature.title}
                      </CardTitle>
                      <CardBody>
                        <h5 style={{ color: "RGB(128, 128, 128)" }}>
                          {feature.description}
                        </h5>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
        <GetInTouch />
        <Download />
        <DarkFooter />
      </div>
    </>
  );
}

export default ProductPage;
