import React from "react";
import { useState, useEffect, useRef } from "react";
import useTypewriter from "react-typewriter-hook";
import "../components/Headers/indexheader.css";

const MagicOcean = [
  "Monitor remotely.",
  "Make electricity payments...",
  "Buy smart devices...",
  "Manage consumption...",
];
let index = 0;

function TypeWrite() {
  const [magicName, setMagicName] = useState("Take Control");
  const intervalRef = useRef({});
  const name = useTypewriter(magicName);
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      // index = index + 1 > 2 ? 0 : ++index + 1;
      index = index > 2 ? 0 : ++index;
      setMagicName(MagicOcean[index]);
    }, 5000);
    return function clear() {
      clearInterval(intervalRef.current);
    };
  }, [magicName]);

  return (
    <div className="boxes">
      <h2 className="  cursor">{name}</h2>
    </div>
  );
}

export default TypeWrite;
