import React from "react";
import ReactDOM from "react-dom";
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/nucleo-icons-page-styles.css";
import App from "./app";
import AuthProvider from "./context/authContext";
import CartProvider from "context/cartContext";

ReactDOM.render(
  <AuthProvider>
    <CartProvider>
      <App />
    </CartProvider>
  </AuthProvider>,
  document.getElementById("root")
);
