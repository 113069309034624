import React, { useState } from "react";

// reactstrap components
import {
  Button,
  FormGroup,
  Row,
  Form,
  Input,
  Col,
  Card,
  CardTitle,
  CardBody,
  CardHeader,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";

// core components

import colors from "config/colors.config";
import AdminService from "services/adminService";
import Message from "components/Message";

function InvoiceQueries(props) {
  const [iconTabs, setIconTabs] = React.useState("1");
  const [invoice, setInvoice] = useState({ invoice_no: "" });
  const [invoice2, setInvoice2] = useState({ invoice_no: "" });
  const [invoice3, setInvoice3] = useState({ invoice_no: "" });
  const [transaction, setTransaction] = useState({
    phone_no: "",
    meter_no: "",
    account_no: "",
    meter_address: "",
    invoice_no: "",
    amount: "",
    response: "",
    token: "",
    date: "",
    userId: "",
    discoId: "",
    meterTypeId: "",
  });
  const [transaction2, setTransaction2] = useState({
    phone_no: "",
    meter_no: "",
    account_no: "",
    meter_address: "",
    invoice_no: generateInvoice(),
    amount: "",
    response: "",
    token: "",
    date: "",
    userId: "",
    discoId: "",
    meterTypeId: "",
    status: "",
    account_name: "",
    charge: "",
    transaction_id: "",
    glade_ref: "",
    rate: "",
    value: "",
    balance: "",
    keyChangeToken1: "",
    keyChangeToken2: "",
    Name: "",
  });
  const [transaction3, setTransaction3] = useState({
    phone_no: "",
    meter_no: "",
    account_no: "",
    meter_address: "",
    invoice_no: "",
    amount: "",
    response: "",
    token: "",
    date: "",
    userId: "",
    discoId: "",
    meterTypeId: "",
    status: "",
    account_name: "",
    charge: "",
    transaction_id: "",
    glade_ref: "",
    rate: "",
    value: "",
    balance: "",
    keyChangeToken1: "",
    keyChangeToken2: "",
  });
  const [alert, setAlert] = useState("");
  const [alert2, setAlert2] = useState("");
  const [alert3, setAlert3] = useState("");
  const [alert4, setAlert4] = useState("");

  const [message, setMessage] = useState(null);
  const [message2, setMessage2] = useState(null);
  const [message3, setMessage3] = useState(null);
  const [message4, setMessage4] = useState(null);

  const [response, setResponse] = useState("false");
  const [response2, setResponse2] = useState("false");
  const [response3, setResponse3] = useState("false");

  //Search invoice
  const resetResponse = (e) => {
    setResponse("false");
    setMessage("");
  };
  const onChange = (e) => {
    setInvoice({
      ...invoice,
      [e.target.name]: e.target.value,
    });
  };
  const resetForm = () => {
    setInvoice({
      invoice_no: "",
    });
  };

  const getInvoice = (e) => {
    e.preventDefault();
    setAlert("Searching");
    try {
      AdminService.findInvoice(invoice).then((data) => {
        if (!data.message) {
          setAlert("");
          setResponse("true");
          setMessage("");
          resetForm();
          setTransaction(data);
        } else {
          const { message } = data;
          setAlert("");
          setResponse("false");
          setMessage(message);
        }
      });
    } catch (error) {}
  };
  function breakResponse(params) {
    if (params) {
      if (!params.includes('"code"')) {
        return params.replace("|TranId:", "  |TranId:");
      }
      let resp = JSON.parse(params);
      delete resp.content;
      delete resp.code;
      delete resp.amount;
      delete resp.CustomerName;
      delete resp.CustomerAddress;
      delete resp.Amount;
      let responseKeys = Object.keys(resp);
      let fmtResp = "";
      responseKeys.forEach((key) => {
        fmtResp += `${key}:${resp[key]}|`;
      });

      return fmtResp;
    } else {
      return "Failed";
    }
  }
  //Create invoice
  const onChangeTransaction2 = (e) => {
    setTransaction2({
      ...transaction2,
      [e.target.name]: e.target.value,
    });
  };

  const resetTransaction2 = () => {
    setTransaction2({
      phone_no: "",
      meter_no: "",
      account_no: "",
      meter_address: "",
      invoice_no: "",
      amount: "",
      response: "",
      token: "",
      date: "",
      userId: "",
      discoId: "",
      meterTypeId: "",
      status: "",
      Email: "",
      account_name: "",
      charge: "",
      transaction_id: "",
      glade_ref: "",
      rate: "",
      value: "",
      balance: "",
      keyChangeToken1: "",
      keyChangeToken2: "",
      Name: "",
    });
  };

  function generateInvoice(params) {
    return "CPA" + Math.floor(100000000 + Math.random() * 900000000);
  }

  function rechargeTotal() {
    const commission = 100;
    const charge = 1.5 / 100;

    if (transaction2.amount === "undefined") {
      return commission.toFixed(2);
    }
    if (transaction2.amount <= 5000) {
      // return roundToTwo(Number(rechargeAmount.amount) + commission);
      return commission.toFixed(2);
    }
    if (transaction2.amount > 5000) {
      // return roundToTwo(Number(rechargeAmount.amount) + commission);
      return (charge * transaction2.amount).toFixed(2);
    }
  }

  const createTransaction = (e) => {
    e.preventDefault();
    setAlert2("Creating Transaction");
    try {
      AdminService.createTransaction({
        phone_no: transaction2.phone_no,
        meter_no: transaction2.meter_no,
        account_no: transaction2.account_no,
        meter_address: transaction2.meter_address,
        invoice_no: transaction2.invoice_no,
        amount: transaction2.amount,
        Name: transaction2.Name,
        Email: transaction2.Email,
        response: transaction2.response,
        token: transaction2.token,
        date: transaction2.date,
        userId: transaction2.userId,
        discoId: transaction2.discoId,
        meterTypeId: transaction2.meterTypeId,
        status: transaction2.status,
        account_name: transaction2.account_name,
        charge: rechargeTotal(),
        transaction_id: transaction2.transaction_id,
        glade_ref: transaction2.glade_ref,
        rate: transaction2.rate,
        value: transaction2.value,
        balance: transaction2.balance,
        keyChangeToken1: transaction2.keyChangeToken2,
        keyChangeToken2: transaction2.keyChangeToken2,
      }).then((data) => {
        if (data.message.msgBody === "Transaction successfully created") {
          const { message } = data;
          setAlert2("");
          setMessage2(message);
          resetTransaction2();
        } else {
          const { message } = data;
          setAlert2("");
          setMessage2(message);
        }
      });
    } catch (error) {}
  };

  //Update invoice
  const resetResponse2 = (e) => {
    setResponse2("false");
    setMessage3("");
  };
  const onChange2 = (e) => {
    setInvoice2({
      ...invoice2,
      [e.target.name]: e.target.value,
    });
  };
  const resetForm2 = () => {
    setInvoice2({
      invoice_no: "",
    });
  };

  const getInvoice2 = (e) => {
    e.preventDefault();
    setAlert3("Searching");
    try {
      AdminService.findInvoice(invoice2).then((data) => {
        if (!data.message) {
          setAlert3("");
          setResponse2("true");
          setMessage3(null);
          resetForm2();
          setTransaction3(data);
        } else {
          const { message } = data;
          setAlert3("");
          setResponse2("false");
          setMessage3(message);
        }
      });
    } catch (error) {}
  };

  const onChangeTransaction3 = (e) => {
    setTransaction3({
      ...transaction3,
      [e.target.name]: e.target.value,
    });
  };

  const resetTransaction3 = () => {
    setTransaction3({
      phone_no: "",
      meter_no: "",
      account_no: "",
      meter_address: "",
      invoice_no: "",
      amount: "",
      response: "",
      token: "",
      date: "",
      userId: "",
      discoId: "",
      meterTypeId: "",
      status: "",
      account_name: "",
      charge: "",
      transaction_id: "",
      glade_ref: "",
      rate: "",
      value: "",
      balance: "",
      keyChangeToken1: "",
      keyChangeToken2: "",
    });
  };

  const updateTransaction = (e) => {
    e.preventDefault();
    setAlert3("Updating transaction");
    try {
      AdminService.updateTransaction(transaction3).then((data) => {
        if (data.message.msgBody === "Transaction was updated successfully.") {
          const { message } = data;
          setAlert3("");
          setMessage3(message);
          resetTransaction3();
        } else {
          const { message } = data;
          setAlert3("");
          setMessage3(message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Delete Invoice
  const resetResponse3 = (e) => {
    setResponse3("false");
    setMessage4("");
  };
  const onChange3 = (e) => {
    setInvoice3({
      ...invoice3,
      [e.target.name]: e.target.value,
    });
  };
  const resetForm3 = () => {
    setInvoice3({
      invoice_no: "",
    });
  };
  const deleteInvoice = (e) => {
    e.preventDefault();
    setAlert4("Deleting");
    try {
      AdminService.deleteTransaction(invoice3).then((data) => {
        const { message } = data;
        setAlert4("");
        setResponse3("true");
        setMessage4(message);
        resetForm3();
      });
    } catch (error) {}
  };

  return (
    <div>
      <Card
        className="card-chart"
        body
        inverse
        style={{
          backgroundColor: colors.dashboard,
          color: colors.black,
        }}
      >
        <CardHeader>
          <Nav className="justify-content-center" role="tablist" tabs>
            <NavItem>
              <NavLink
                className={iconTabs === "1" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("1");
                }}
              >
                <i className="now-ui-icons education_glasses"></i>
                Search
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs === "2" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("2");
                }}
              >
                <i className="now-ui-icons files_paper"></i>
                Create
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs === "3" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("3");
                }}
              >
                <i className="now-ui-icons text_caps-small"></i>
                Update
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconTabs === "4" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setIconTabs("4");
                }}
              >
                <i className="now-ui-icons ui-1_simple-delete"></i>
                Delete
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent className="text-center" activeTab={"iconTabs" + iconTabs}>
            <TabPane tabId="iconTabs1" style={{ color: colors.userTitle }}>
              {response === "false" ? (
                <div>
                  <div
                    className="col-md-6 offset-md-3"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                    }}
                  >
                    <Form onSubmit={getInvoice} className="form">
                      <Input
                        placeholder="Invoice number"
                        type="text"
                        name="invoice_no"
                        value={invoice.invoice_no}
                        required
                        onChange={onChange}
                        id="invoice_no"
                      ></Input>
                      <Button
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Find Invoice
                      </Button>
                    </Form>

                    <p>{alert}</p>
                    {message ? <Message message={message} /> : null}
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "50px",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "50px",
                    padding: "20px",
                    backgroundColor: colors.primary,
                  }}
                >
                  <div>
                    <Button
                      className="btn-round"
                      onClick={resetResponse}
                      style={{
                        color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      size="sm"
                    >
                      Reset
                    </Button>
                  </div>
                  <Row>
                    <Col className>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Id
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.id}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Amount
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.amount}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        MeterType
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.meterTypeId}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Meter No
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.meter_no}
                      </h5>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Phone No
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.phone_no}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Token No
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.token}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Date
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.date.slice(0, 10)}
                      </h5>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        User ID
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {transaction.userId}
                      </h5>
                    </Col>
                  </Row>

                  <br></br>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          color: colors.black,
                          marginBottom: "2px",
                        }}
                      >
                        Response
                      </CardTitle>
                      <h5
                        style={{
                          color: colors.white,
                          marginTop: "1px",
                        }}
                      >
                        {breakResponse(transaction.response)}
                      </h5>
                    </Col>
                  </Row>
                </div>
              )}
            </TabPane>
            <TabPane tabId="iconTabs2">
              <div style={{ color: colors.black }}>
                <Form onSubmit={createTransaction} className="form">
                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <label htmlFor="phoneNo">Phone No</label>
                      <Input
                        id="phone_no"
                        placeholder="Enter number"
                        type="text"
                        name="phone_no"
                        value={transaction2.phone_no}
                        required
                        onChange={onChangeTransaction2}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label htmlFor="meterNo">Meter No</label>
                      <Input
                        id="meter_no"
                        placeholder="Enter number"
                        type="text"
                        name="meter_no"
                        value={transaction2.meter_no}
                        required
                        onChange={onChangeTransaction2}
                      ></Input>
                    </FormGroup>
                  </div>
                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <label htmlFor="invoiceNo">Invoice No</label>
                      <Input
                        id="invoice_no"
                        readOnly
                        placeholder="Transaction number"
                        type="text"
                        name="invoice_no"
                        value={transaction2.invoice_no}
                        required
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label htmlFor="amount">Amount</label>
                      <Input
                        id="amount"
                        placeholder="Enter amount"
                        type="text"
                        name="amount"
                        value={transaction2.amount}
                        required
                        onChange={onChangeTransaction2}
                      ></Input>
                    </FormGroup>
                  </div>
                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <label htmlFor="token">Token</label>
                      <Input
                        id="token"
                        placeholder="Enter number"
                        type="text"
                        name="token"
                        value={transaction2.token}
                        required
                        onChange={onChangeTransaction2}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label htmlFor="date">Date</label>
                      <Input
                        id="date"
                        placeholder="Enter date"
                        type="date"
                        name="date"
                        value={transaction2.date}
                        required
                        onChange={onChangeTransaction2}
                      />
                    </FormGroup>
                  </div>
                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <label htmlFor="meterAddress">Customer Name</label>
                      <Input
                        id="Name"
                        placeholder="Enter name"
                        type="text"
                        name="Name"
                        value={transaction2.Name}
                        required
                        onChange={onChangeTransaction2}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label htmlFor="meterAddress">Meter Address</label>
                      <Input
                        id="meter_address"
                        placeholder="Enter Address"
                        type="text"
                        name="meter_address"
                        value={transaction2.meter_address}
                        required
                        onChange={onChangeTransaction2}
                      ></Input>
                    </FormGroup>
                  </div>

                  <FormGroup>
                    <label htmlFor="response">Response</label>
                    <Input
                      id="response"
                      placeholder="Enter response"
                      type="textarea"
                      name="response"
                      value={transaction2.response}
                      required
                      onChange={onChangeTransaction2}
                    ></Input>
                  </FormGroup>
                  <div className="form-row">
                    <FormGroup className="col-md-2">
                      <label htmlFor="userID">User ID</label>
                      <Input
                        id="userId"
                        placeholder="Enter userId"
                        type="text"
                        name="userId"
                        value={transaction2.userId}
                        required
                        onChange={onChangeTransaction2}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-4">
                      <label htmlFor="discoId">DiscoId</label>
                      <Input
                        id="discoId"
                        type="select"
                        name="discoId"
                        required
                        value={transaction2.discoId}
                        onChange={onChangeTransaction2}
                      >
                        <option value="">Choose...</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label htmlFor="meterTypeId">MeterTypeId</label>
                      <Input
                        id="meterTypeId"
                        type="select"
                        required
                        name="meterTypeId"
                        value={transaction2.meterTypeId}
                        onChange={onChangeTransaction2}
                      >
                        <option value="">Choose...</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                      </Input>
                    </FormGroup>
                  </div>

                  <div className="form-row">
                    <FormGroup className="col-md-4">
                      <label htmlFor="token">Email</label>
                      <Input
                        id="Email"
                        placeholder="Enter email address"
                        type="email"
                        name="Email"
                        value={transaction2.Email}
                        onChange={onChangeTransaction2}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-4">
                      <label htmlFor="token">keyChangeToken1</label>
                      <Input
                        id="keyChangeToken1"
                        placeholder="Enter number"
                        type="text"
                        name="keyChangeToken1"
                        value={transaction2.keyChangeToken1}
                        onChange={onChangeTransaction2}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-4">
                      <label htmlFor="date">keyChangeToken2</label>
                      <Input
                        id="keyChangeToken2"
                        placeholder="Enter number"
                        type="text"
                        name="keyChangeToken2"
                        value={transaction2.keyChangeToken2}
                        onChange={onChangeTransaction2}
                      />
                    </FormGroup>
                  </div>
                  <div className="form-row">
                    <FormGroup className="col-md-4">
                      <label htmlFor="date">Rate</label>
                      <Input
                        id="rate"
                        placeholder="Enter rate"
                        type="text"
                        name="rate"
                        value={transaction2.rate}
                        onChange={onChangeTransaction2}
                      />
                    </FormGroup>
                    <FormGroup className="col-md-4">
                      <label htmlFor="date">Cost of unit</label>
                      <Input
                        id="value"
                        placeholder="Enter value"
                        type="text"
                        name="value"
                        value={transaction2.value}
                        onChange={onChangeTransaction2}
                      />
                    </FormGroup>
                    <FormGroup className="col-md-4">
                      <label htmlFor="date">Value</label>
                      <Input
                        id="balance"
                        placeholder="Enter  balance"
                        type="text"
                        name="balance"
                        value={transaction2.balance}
                        onChange={onChangeTransaction2}
                      />
                    </FormGroup>
                  </div>
                  <div className="form-row">
                    <FormGroup className="col-md-4">
                      <label htmlFor="date">Account No</label>
                      <Input
                        id="account_no"
                        placeholder="Enter account number"
                        type="text"
                        name="account_no"
                        value={transaction2.account_no}
                        onChange={onChangeTransaction2}
                      />
                    </FormGroup>
                    <FormGroup className="col-md-4">
                      <label htmlFor="date">Monnify Ref</label>
                      <Input
                        id="glade_ref"
                        placeholder="Enter monnify ref"
                        type="text"
                        name="glade_ref"
                        value={transaction2.glade_ref}
                        onChange={onChangeTransaction2}
                      />
                    </FormGroup>
                    <FormGroup className="col-md-4">
                      <label htmlFor="date">Transaction ID</label>
                      <Input
                        id="transaction_id"
                        placeholder="Enter transaction ID"
                        type="text"
                        name="transaction_id"
                        value={transaction2.transaction_id}
                        onChange={onChangeTransaction2}
                      />
                    </FormGroup>
                  </div>
                  <div className="form-row">
                    <FormGroup className="col-md-3">
                      <label htmlFor="status">Status</label>
                      <Input
                        id="status"
                        type="select"
                        name="status"
                        value={transaction2.status}
                        onChange={onChangeTransaction2}
                      >
                        <option value="">Choose...</option>
                        <option value="200">200</option>
                      </Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label htmlFor="response">Account Name</label>
                      <Input
                        id="account_name"
                        placeholder="Enter account name"
                        type="textarea"
                        name="account_name"
                        value={transaction2.account_name}
                        onChange={onChangeTransaction2}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-md-3">
                      <label htmlFor="response">Charge</label>
                      <Input
                        id="charge"
                        //readOnly
                        placeholder="Enter charge"
                        type="text"
                        name="charge"
                        value={rechargeTotal()}
                      ></Input>
                    </FormGroup>
                  </div>

                  <Button
                    className="btn-round"
                    type="submit"
                    style={{
                      color: colors.secondary,
                      backgroundColor: colors.primary,
                    }}
                    size="lg"
                  >
                    Create
                  </Button>
                </Form>
                <hr
                  className="warning"
                  style={{
                    backgroundColor: colors.primary,
                    marginTop: "25px",
                  }}
                />
                <p>{alert2}</p>
                <div>{message2 ? <Message message={message2} /> : null}</div>
              </div>
            </TabPane>
            <TabPane tabId="iconTabs3">
              {response2 === "false" ? (
                <div>
                  <div
                    className="col-md-6 offset-md-3"
                    style={{
                      color: colors.black,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                    }}
                  >
                    <Form onSubmit={getInvoice2} className="form">
                      <Input
                        placeholder="Invoice number"
                        type="text"
                        name="invoice_no"
                        value={invoice2.invoice_no}
                        required
                        onChange={onChange2}
                        id="invoice_no"
                      ></Input>
                      <Button
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Find Invoice
                      </Button>
                    </Form>
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "50px",
                    }}
                  />
                  <p>{alert3}</p>
                  <div>{message3 ? <Message message={message3} /> : null}</div>
                </div>
              ) : (
                <div style={{ color: colors.black }}>
                  <Button
                    className="btn-round"
                    onClick={resetResponse2}
                    style={{
                      color: colors.secondary,
                      backgroundColor: colors.primary,
                    }}
                    size="sm"
                  >
                    Reset
                  </Button>

                  <Form onSubmit={updateTransaction} className="form">
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="phoneNo">Phone No</label>
                        <Input
                          id="phone_no"
                          placeholder="Enter number"
                          type="text"
                          name="phone_no"
                          value={transaction3.phone_no}
                          onChange={onChangeTransaction3}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="meterNo">Meter No</label>
                        <Input
                          id="meter_no"
                          placeholder="Enter number"
                          type="text"
                          name="meter_no"
                          value={transaction3.meter_no}
                          onChange={onChangeTransaction3}
                        ></Input>
                      </FormGroup>
                    </div>
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="invoiceNo">Invoice No</label>
                        <Input
                          id="invoice_no"
                          placeholder="Transaction number"
                          type="text"
                          name="invoice_no"
                          defaultValue={transaction3.invoice_no}
                          value={transaction3.invoice_no}
                          required
                          onChange={onChangeTransaction3}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="amount">Amount</label>
                        <Input
                          id="amount"
                          placeholder="Enter amount"
                          type="text"
                          name="amount"
                          defaultValue={transaction3.amount}
                          value={transaction3.amount}
                          onChange={onChangeTransaction3}
                        ></Input>
                      </FormGroup>
                    </div>
                    <div className="form-row">
                      <FormGroup className="col-md-4">
                        <label htmlFor="date">Rate</label>
                        <Input
                          id="rate"
                          placeholder="Enter rate"
                          type="text"
                          name="rate"
                          value={transaction3.rate}
                          onChange={onChangeTransaction3}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-4">
                        <label htmlFor="date">Cost of Units</label>
                        <Input
                          id="value"
                          placeholder="Enter value"
                          type="text"
                          name="value"
                          value={transaction3.value}
                          onChange={onChangeTransaction3}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-4">
                        <label htmlFor="date">Value</label>
                        <Input
                          id="balance"
                          placeholder="Enter  balance"
                          type="text"
                          name="balance"
                          value={transaction3.balance}
                          onChange={onChangeTransaction3}
                        />
                      </FormGroup>
                    </div>

                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="date">Monnify Ref</label>
                        <Input
                          id=" glade_ref"
                          placeholder="Enter Monnify ref"
                          type="text"
                          name="glade_ref"
                          value={transaction3.glade_ref}
                          onChange={onChangeTransaction3}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="date">Transaction ID</label>
                        <Input
                          id="transaction_id"
                          placeholder="Enter transaction ID"
                          type="text"
                          name="transaction_id"
                          value={transaction3.transaction_id}
                          onChange={onChangeTransaction3}
                        />
                      </FormGroup>
                    </div>
                    <div className="form-row">
                      <FormGroup className="col-md-3">
                        <label htmlFor="status">Status</label>
                        <Input
                          id="status"
                          type="select"
                          name="status"
                          value={transaction3.status}
                          onChange={onChangeTransaction3}
                        >
                          <option selected="" value="">
                            Choose...
                          </option>
                          <option value="200">Resolved</option>
                          <option value="100">Failed</option>
                          <option value="900">Waiting</option>
                        </Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="response">Account Name</label>
                        <Input
                          id=" account_name"
                          placeholder="Enter account name"
                          type="textarea"
                          name=" account_name"
                          value={transaction3.account_name}
                          onChange={onChangeTransaction3}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-3">
                        <label htmlFor="response">Charge</label>
                        <Input
                          id="charge"
                          placeholder="Enter charge"
                          type="text"
                          name="charge"
                          value={transaction3.charge}
                          onChange={onChangeTransaction3}
                        ></Input>
                      </FormGroup>
                    </div>

                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="token">keyChangeToken1</label>
                        <Input
                          id="keyChangeToken1"
                          placeholder="Enter number"
                          type="text"
                          name="keyChangeToken1"
                          value={transaction3.keyChangeToken1}
                          onChange={onChangeTransaction3}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="date">keyChangeToken2</label>
                        <Input
                          id="keyChangeToken2"
                          placeholder="Enter number"
                          type="text"
                          name="keyChangeToken1"
                          value={transaction3.keyChangeToken2}
                          onChange={onChangeTransaction3}
                        />
                      </FormGroup>
                    </div>

                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <label htmlFor="token">Token</label>
                        <Input
                          id="token"
                          placeholder="Enter number"
                          type="text"
                          name="token"
                          defaultValue={transaction3.token}
                          value={transaction3.token}
                          onChange={onChangeTransaction3}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="date">Date</label>
                        <Input
                          id="date"
                          placeholder="Enter date"
                          type="text"
                          name="date"
                          defaultValue={transaction3.date}
                          value={transaction3.date}
                          onChange={onChangeTransaction3}
                        />
                      </FormGroup>
                    </div>
                    <FormGroup>
                      <label htmlFor="meterAddress">Meter Address</label>
                      <Input
                        id="meter_address"
                        placeholder="Enter Address"
                        type="text"
                        name="meter_address"
                        defaultValue={transaction3.meter_address}
                        value={transaction3.meter_address}
                        onChange={onChangeTransaction3}
                      ></Input>
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="response">Response</label>
                      <Input
                        id="response"
                        placeholder="Enter response"
                        type="textarea"
                        name="response"
                        defaultValue={transaction3.response}
                        value={transaction3.response}
                        onChange={onChangeTransaction3}
                      ></Input>
                    </FormGroup>
                    <div className="form-row">
                      <FormGroup className="col-md-2">
                        <label htmlFor="userID">User ID</label>
                        <Input
                          id="userId"
                          placeholder="Enter userId"
                          type="text"
                          name="userId"
                          defaultValue={transaction3.userId}
                          value={transaction3.userId}
                          onChange={onChangeTransaction3}
                        ></Input>
                      </FormGroup>
                      <FormGroup className="col-md-4">
                        <label htmlFor="discoId">DiscoId</label>
                        <Input
                          id="discoId"
                          type="select"
                          name="discoId"
                          defaultValue={transaction3.discoId}
                          value={transaction3.discoId}
                          onChange={onChangeTransaction3}
                        >
                          <option selected="" value="">
                            Choose...
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </Input>
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <label htmlFor="meterTypeId">MeterTypeId</label>
                        <Input
                          id="meterTypeId"
                          type="select"
                          name="meterTypeId"
                          defaultValue={transaction3.meterTypeId}
                          value={transaction3.meterTypeId}
                          onChange={onChangeTransaction3}
                        >
                          <option selected="" value="">
                            Choose...
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                        </Input>
                      </FormGroup>
                    </div>
                    <Button
                      className="btn-round"
                      type="submit"
                      style={{
                        color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      size="lg"
                    >
                      Update
                    </Button>
                  </Form>
                  <p>{alert3}</p>
                  <div>{message3 ? <Message message={message3} /> : null}</div>
                </div>
              )}
            </TabPane>
            <TabPane tabId="iconTabs4">
              {response3 === "false" ? (
                <div>
                  <div
                    className="col-md-6 offset-md-3"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                    }}
                  >
                    <Form onSubmit={deleteInvoice} className="form">
                      <Input
                        placeholder="Invoice number"
                        type="text"
                        name="invoice_no"
                        value={invoice3.invoice_no}
                        required
                        onChange={onChange3}
                        id="invoice_no"
                      ></Input>
                      <Button
                        className="btn-round"
                        type="submit"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        size="lg"
                      >
                        Delete
                      </Button>
                    </Form>

                    <p>{alert4}</p>
                    {message4 ? <Message message={message4} /> : null}
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "50px",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "50px",
                  }}
                >
                  <div>
                    <Button
                      className="btn-round"
                      onClick={resetResponse3}
                      style={{
                        color: colors.secondary,
                        backgroundColor: colors.primary,
                      }}
                      size="sm"
                    >
                      Reset
                    </Button>
                  </div>
                  <p>{alert4}</p>
                  {message4 ? <Message message={message4} /> : null}
                </div>
              )}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
}

export default InvoiceQueries;
