import React, { useEffect, useState } from "react";
// eslint-disable-next-line
import { Line, Bar, Pie, Doughnut } from "react-chartjs-2";

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar";
import AdminService from "services/adminService";
import colors from "config/colors.config";
import TransparentFooter from "components/Footers/TransparentFooter";
import InvoiceQueries from "components/Queries.js/admin/invoice.queries";
import FailedTransactionQueries from "components/Queries.js/rep/failedTransactions.queries";
import UserQueries from "components/Queries.js/admin/user.queries";
import ProductQueries from "components/Queries.js/manager/product.queries";
import ProductTagQueries from "components/Queries.js/manager/productTag.queries";
import TransactionQueries from "components/Queries.js/rep/transactions.queries";
import ProductFeatureQueries from "components/Queries.js/manager/productFeatures.queries";
import NewsFeedQueries from "components/Queries.js/admin/newsFeed.queries";

function AdminPage() {
  const [monthlyTransactions, setMonthlyTransactions] = useState([]);
  const [shopMonthlyTransactions, setShopMonthlyTransactions] = useState([]);

  const [liveFailedTransaction, setLiveFailedTransaction] = useState();
  const [waitingFailedTransaction, setWaitingFailedTransaction] = useState();
  const [totalFailedTransactions, setTotalFailedTransactions] = useState();
  //const [failedIkeja, setFailedIkeja] = useState();
  //const [failedEko, setFailedEko] = useState();
  //const [failedIB, setFailedIB] = useState();
  const [failedDisco, setFailedDisco] = useState();

  //All live failed transactions
  useEffect(() => {
    try {
      AdminService.getAllLiveFailedTransaction().then((data) => {
        setLiveFailedTransaction(data.length);
      });
    } catch (error) {}
  }, []);
  // All waiting failed transactions
  useEffect(() => {
    try {
      AdminService.getWaitingTransactions().then((data) => {
        setWaitingFailedTransaction(data.length);
      });
    } catch (error) {}
  }, []);

  // All failed transactions
  useEffect(() => {
    try {
      AdminService.getFailedTransactions().then((data) => {
        setTotalFailedTransactions(data.length);
      });
    } catch (error) {}
  }, []);

  //Total failed Ikeja electric  transaction
  // useEffect(() => {
  //   try {
  //     AdminService.findAllDiscoFailedTransaction({ discoId: 2 }).then(
  //       (data) => {
  //         setFailedIkeja(data.length);
  //       }
  //     );
  //   } catch (error) {}
  // }, []);

  //Total failed Eko electric  transaction
  // useEffect(() => {
  //   try {
  //     AdminService.findAllDiscoFailedTransaction({ discoId: 1 }).then(
  //       (data) => {
  //         setFailedEko(data.length);
  //       }
  //     );
  //   } catch (error) {}
  // }, []);

  //Total failed Ibadan transaction
  // useEffect(() => {
  //   try {
  //     AdminService.findAllDiscoFailedTransaction({ discoId: 3 }).then(
  //       (data) => {
  //         setFailedIB(data.length);
  //       }
  //     );
  //   } catch (error) {}
  // }, []);

  //Total failed Ibadan transaction
  useEffect(() => {
    try {
      AdminService.findAllDiscoFailedTransaction().then((data) => {
        console.log(data);
        setFailedDisco(data);
      });
    } catch (error) {}
  }, []);

  //Total transactions monthly
  useEffect(() => {
    try {
      AdminService.getSuccessfulMonthlyTransactions().then((data) => {
        const { MonthlyArray } = data;
        setMonthlyTransactions(MonthlyArray);
      });
    } catch (error) {}
  }, []);

  useEffect(() => {
    try {
      AdminService.getMonthlyTransactions().then((data) => {
        console.log(data);
      });
    } catch (error) {}
  }, []);

  //Shop monthly transactions
  useEffect(() => {
    try {
      AdminService.getShopMonthlyTransactions().then((data) => {
        const { MonthlyArray } = data;
        setShopMonthlyTransactions(MonthlyArray);
      });
    } catch (error) {}
  }, []);

  // Color Setting
  function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }
  //Summation of Array
  const getArraySum = (a) => {
    try {
      var total = 0;
      for (var i in a) {
        total += a[i];
      }
      return total.toFixed(2);
    } catch (error) {
      console.log(error);
    }
  };

  // All shop Transaction chart
  const linedata = (canvas) => {
    const ctx = canvas.getContext("2d");
    var chartColor = "#FFFFFF";
    var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#80b6f4");
    gradientStroke.addColorStop(1, chartColor);
    var gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
    gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
    gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.14)");

    return {
      labels: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      datasets: [
        {
          label: "Data",
          borderColor: chartColor,
          pointBorderColor: chartColor,
          pointBackgroundColor: "#2c2c2c",
          pointHoverBackgroundColor: "#2c2c2c",
          pointHoverBorderColor: chartColor,
          pointBorderWidth: 1,
          pointHoverRadius: 7,
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          fill: true,
          backgroundColor: gradientFill,
          borderWidth: 2,
          data: shopMonthlyTransactions,
        },
      ],
    };
  };
  const lineoptions = {
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 0,
        bottom: 0,
      },
    },
    maintainAspectRatio: false,
    tooltips: {
      backgroundColor: "#fff",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    legend: {
      position: "bottom",
      fillStyle: "#FFF",
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#FFA500",
            fontStyle: "bold",
            beginAtZero: true,
            maxTicksLimit: 5,
            padding: 10,
          },
          gridLines: {
            drawTicks: true,
            drawBorder: false,
            display: true,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            color: "rgba(255,255,255,0.1)",
          },
          ticks: {
            padding: 10,
            fontColor: colors.userTitle,
            fontStyle: "bold",
          },
        },
      ],
    },
  };
  const thisYearShopTotalTransactions = getArraySum(shopMonthlyTransactions);

  //All transaction chart
  const data = (canvas) => {
    var ctx = canvas.getContext("2d");
    var gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
    gradientFill.addColorStop(0, "rgba(255,165,44, 0)");
    gradientFill.addColorStop(1, hexToRGB("#FFA500", 0.6));
    return {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          label: "Monthly Transactions",
          backgroundColor: gradientFill,
          borderColor: "#FFA500",
          pointBorderColor: "#FFF",
          pointBackgroundColor: "#FFA500",
          pointBorderWidth: 2,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 1,
          pointRadius: 4,
          fill: true,
          borderWidth: 1,
          data: monthlyTransactions,
        },
      ],
    };
  };
  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      bodySpacing: 4,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
      xPadding: 10,
      yPadding: 10,
      caretPadding: 10,
    },
    responsive: 1,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            padding: 10,
            maxTicksLimit: 7,
          },
          gridLines: {
            zeroLineColor: "transparent",
            drawBorder: false,
          },
        },
      ],
      xAxes: [
        {
          display: 0,
          ticks: {
            display: false,
          },
          gridLines: {
            zeroLineColor: "transparent",
            drawTicks: false,
            display: false,
            drawBorder: false,
          },
        },
      ],
    },
    layout: {
      padding: { left: 0, right: 0, top: 15, bottom: 15 },
    },
  };
  const thisYearTotalTransactions = getArraySum(monthlyTransactions);

  //Failed Transaction Chart
  const data2 = () => {
    return {
      labels: ["Live", "Waiting"],
      datasets: [
        {
          label: "Failed Transactions",
          backgroundColor: ["#FF8C00", "#DC143C"],
          hoverBackgroundColor: ["#FFA500", "#FF0000"],
          // borderColor: "#FFA500",
          // pointBorderColor: "#FFF",
          // pointBackgroundColor: "#FFA500",
          // pointBorderWidth: 2,
          // pointHoverRadius: 4,
          // pointHoverBorderWidth: 1,
          // pointRadius: 4,
          // fill: true,
          // borderWidth: 1,
          data: [liveFailedTransaction, waitingFailedTransaction],
        },
      ],
    };
  };
  //Failed Disco Transaction Chart
  const data3 = (canvas) => {
    return {
      labels: { ...failedDisco }.discos,
      //labels: ["IKeja(IE)", "EKO(EE)", "IBadan(IBEDC)"],
      datasets: [
        {
          label: "Disco Failure Rate",
          backgroundColor: [
            "#FF8C00",
            "#FF4500",
            "#FFA500",
            "#FFD700",
            "#DC143C",
            "#B22222",
            "#FF1493",
            "#FF6347",
            "#3CB371",
            "#2E8B57",
            "#20B2AA",
            "#66CDAA",
          ],
          hoverBackgroundColor: [
            "#FF9933",
            "#FF5733",
            "#FFB733",
            "#FFDD33",
            "#E3265A",
            "#BF4444",
            "#FF3BAA",
            "#FF7F6A",
            "#57C388",
            "#4C9E76",
            "#45C1BA",
            "#85D8BB",
          ],

          // borderColor: "#FFA500",
          // pointBorderColor: "#FFF",
          // pointBackgroundColor: "#FFA500",
          // pointBorderWidth: 400,
          //pointHoverRadius: 400,
          //pointHoverBorderWidth: 100,
          //pointRadius: 100,
          // fill: true,
          //borderWidth: 5,
          maintainAspectRatio: 100 / 410,
          // data: [failedIkeja, failedEko, failedIB],
          data: { ...failedDisco }.discoData,
        },
      ],
    };
  };

  //Mobile menu
  useEffect(() => {
    document.body.classList.add("admin-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("admin-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <AuthNavbar />
      <div className="wrapper">
        <div className="section">
          <h2
            style={{
              color: colors.pageHeader,
              marginBottom: "0px",
              fontWeight: "600",

              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            AdminBoard
          </h2>
          <Row
            style={{
              marginTop: "20px",
              marginLeft: "20px",
              marginRight: "20px",
              backgroundImage:
                "url(https://res.cloudinary.com/comparelight-com/image/upload/v1598357632/dashboard_background.png)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center right",
              backgroundPositionX: "500px",
              backgroundPositionY: "150px",
              backgroundSize: "contain",
              minHeight: "900px",
            }}
          >
            <Col md="8">
              <Card body inverse color="warning">
                <CardTitle tag="h2" style={{ color: colors.secondary }}>
                  Hello
                  <span style={{ color: colors.white, fontWeight: "600" }}>
                    {" "}
                    Admin
                  </span>
                </CardTitle>
                <CardText style={{ color: colors.black }}>
                  Manage and Control accounts here
                </CardText>
              </Card>
              <Row md="2">
                <Col>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    User Queries
                  </h3>
                  <UserQueries />
                </Col>

                <Col>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Invoice Queries
                  </h3>
                  <InvoiceQueries />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Failed Transaction Queries
                  </h3>
                  <FailedTransactionQueries />
                </Col>
              </Row>
              <Row md="2">
                <Col>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Product Queries
                  </h3>
                  <ProductQueries />
                </Col>

                <Col>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Tag Queries
                  </h3>
                  <ProductTagQueries />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Transaction Queries
                  </h3>
                  <TransactionQueries />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Feature Queries
                  </h3>
                  <ProductFeatureQueries />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    News Queries
                  </h3>
                  <NewsFeedQueries />
                </Col>
              </Row>
            </Col>
            <Col md="4">
              <Card
                body
                inverse
                style={{
                  backgroundColor: colors.dashboard,
                  minHeight: "300px",
                }}
              >
                <CardHeader>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                      textAlign: "left",
                    }}
                  >
                    Monthly Transactions{" "}
                  </h3>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "30px",
                    }}
                  />
                </CardHeader>

                <CardBody>
                  <div className="chart-area">
                    <Bar data={data} options={options} />
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="stats">
                    <h5 style={{ color: colors.black, marginTop: "10px" }}>
                      {thisYearTotalTransactions ? (
                        <div>
                          This Year Total:{" "}
                          <span style={{ color: colors.green }}>₦</span>
                          {thisYearTotalTransactions}
                        </div>
                      ) : (
                        "No records yet"
                      )}
                    </h5>
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "30px",
                    }}
                  />
                </CardFooter>
              </Card>
              <Card
                body
                inverse
                style={{
                  backgroundColor: colors.dashboard,
                  minHeight: "300px",
                }}
              >
                <CardHeader>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                      textAlign: "left",
                    }}
                  >
                    Shop Monthly Transactions{" "}
                  </h3>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "30px",
                    }}
                  />
                </CardHeader>

                <CardBody>
                  <div className="chart-area">
                    <Line data={linedata} options={lineoptions} />
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="stats">
                    <h5 style={{ color: colors.black, marginTop: "20px" }}>
                      {thisYearTotalTransactions ? (
                        <div>
                          Total Sales:{" "}
                          <span style={{ color: colors.green }}>₦</span>
                          {thisYearShopTotalTransactions}
                        </div>
                      ) : (
                        "No records yet"
                      )}
                    </h5>
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "30px",
                    }}
                  />
                </CardFooter>
              </Card>
              <Card
                body
                inverse
                style={{
                  backgroundColor: colors.dashboard,
                  minHeight: "200px",
                }}
              >
                <CardHeader>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                      textAlign: "left",
                    }}
                  >
                    Failed Transactions{" "}
                  </h3>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "30px",
                    }}
                  />
                </CardHeader>

                <CardBody>
                  <div className="chart-area">
                    <Doughnut data={data2} type="doughnut" />
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="stats">
                    <h5 style={{ color: colors.black, marginTop: "10px" }}>
                      Total Failed Transactions: {totalFailedTransactions}
                    </h5>
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "30px",
                    }}
                  />
                </CardFooter>
              </Card>
              <Card
                body
                inverse
                style={{
                  backgroundColor: colors.dashboard,
                  minHeight: "200px",
                }}
              >
                <CardHeader>
                  <h3
                    style={{
                      color: colors.primary,
                      marginBottom: "10px",
                      fontWeight: "600",
                      textAlign: "left",
                    }}
                  >
                    Disco Failures{" "}
                  </h3>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "30px",
                    }}
                  />
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Bar data={data3} options={options} />
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="stats">
                    <h5 style={{ color: colors.black, marginTop: "10px" }}>
                      Total Failed Transactions: {totalFailedTransactions}
                    </h5>
                  </div>
                  <hr
                    className="warning"
                    style={{
                      backgroundColor: colors.primary,
                      marginTop: "30px",
                    }}
                  />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default AdminPage;
