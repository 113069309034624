import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  Container,
  Row,
} from "reactstrap";

// core components
import colors from "config/colors.config";
import EmailService from "services/emailService";

function GetInTouch(props) {
  const [nameFocus, setNameFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [messageFocus, setMessageFocus] = useState(false);
  const [user, setUser] = useState({ name: "", email: "", message: "" });
  const [alert, setAlert] = useState("");

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setUser({ name: "", email: "", message: "" });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setAlert("Sending your message...");
    EmailService.sendMail(user)
      .then((data) => {
        if (data.message.msgBody === "Message sent") {
          setAlert("message is submitted");
          resetForm();
          setTimeout(() => {
            setAlert("");
          }, 3000);
        } else {
          setAlert("Something went wrong! try again!");
          setTimeout(() => {
            setAlert("");
          }, 3000);
        }
      })
      .catch((error) => {
        setAlert("Something went wrong this time!");
        setTimeout(() => {
          setAlert("");
        }, 3000);
      });
  };

  return (
    <>
      <div
        className="section section-signup"
        style={{
          backgroundImage:
            "url(https://res.cloudinary.com/comparelight-com/image/upload/v1598283244/index_bg1.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: "700px",
        }}
      >
        <div>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Get in touch?</h2>
                <Form className="form" onSubmit={onSubmit}>
                  <Row>
                    <Col md="6">
                      <label style={{ color: "white" }}>Name</label>
                      <InputGroup
                        className={
                          "no-border" + (nameFocus ? " input-group-focus" : "")
                        }
                      >
                        <Input
                          placeholder="Name..."
                          type="text"
                          name="name"
                          value={user.name}
                          onChange={onChange}
                          onFocus={() => setNameFocus(true)}
                          onBlur={() => setNameFocus(false)}
                          variant="outlined"
                        ></Input>
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label style={{ color: "white" }}>Email</label>
                      <InputGroup
                        className={
                          "no-border" + (emailFocus ? " input-group-focus" : "")
                        }
                      >
                        <Input
                          placeholder="Enter Your Email..."
                          name="email"
                          type="email"
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                          onChange={onChange}
                          value={user.email}
                          variant="outlined"
                        ></Input>
                      </InputGroup>
                    </Col>
                  </Row>
                  <br></br>
                  <label style={{ color: "white" }}>Your message</label>
                  <InputGroup
                    className={
                      "no-border input-lg" +
                      (messageFocus ? " input-group-focus" : "")
                    }
                  >
                    <Input
                      placeholder="Tell us how we can help..."
                      type="textarea"
                      name="message"
                      value={user.message}
                      onChange={onChange}
                      rows="8"
                      onFocus={() => setMessageFocus(true)}
                      onBlur={() => setMessageFocus(false)}
                      variant="outlined"
                    ></Input>
                  </InputGroup>

                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button
                        className="btn-round ml-1"
                        style={{
                          color: colors.secondary,
                          backgroundColor: colors.primary,
                        }}
                        type="submit"
                        size="lg"
                      >
                        Send Message
                      </Button>
                      <p
                        className="content-center text-center"
                        style={{ color: "white" }}
                      >
                        {alert}
                      </p>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default GetInTouch;
